<template>
    <SidebarFilter
        :title="title"
        :border="border"
        :alerts="alerts"
        @dismiss:alert="$emit('dismiss:alert', $event)"
    >
        <template #content>
            <span>
                <div
                    class="flex items-center bg-white max-w-full w-full rounded-md"
                >
                    <div
                        v-if="isBusy"
                        class="w-full p-2 text-center animate-pulse"
                    >
                        <LoadingDots class="animate-ping h-6 mx-auto" />
                        <span class="inline">
                            {{ `Loading ${title}` }}
                        </span>
                    </div>
                    <div
                        v-else-if="!isEmpty"
                        class="w-full p-2"
                    >
                        <!-- <div
                            v-if="debug"
                            class="whitespace-pre-line p-2 rounded-lg bg-gray-50"
                        >
                            {{
                                {
                                    selectedWeatherStations: Object.keys(
                                        tree.nodes
                                    )
                                        .filter((id) => id.startsWith('s'))
                                        .map((id) => tree.nodes[id])
                                        .filter(
                                            (node) =>
                                                node.state?.checked === true
                                        )
                                        .map((node) => node.id),
                                    buttons: buttons?.length,
                                    config: tree.config,
                                    nodes: Object.keys(tree.nodes).length,
                                    stations: stations.length,
                                }
                            }}
                        </div> -->
                        <Treeview
                            id="tree-stations"
                            class="w-full min-h-10 overflow-x-auto overflow-y-auto flex-shrink-0"
                            :config="tree.config"
                            :nodes="tree.nodes"
                            :class="menuHeight"
                            @nodeOpened="
                                (e) =>
                                    getTreeEventHandler(
                                        TREEVIEW.NodeOpenedEvent
                                    )(e)
                            "
                            @nodeClosed="
                                (e) =>
                                    getTreeEventHandler(
                                        TREEVIEW.NodeClosedEvent
                                    )(e)
                            "
                            @nodeChecked="
                                (e) =>
                                    getTreeEventHandler(
                                        TREEVIEW.NodeCheckedEvent
                                    )(e)
                            "
                            @nodeUnchecked="
                                (e) =>
                                    getTreeEventHandler(
                                        TREEVIEW.NodeUncheckedEvent
                                    )(e)
                            "
                        />
                    </div>
                    <div
                        v-else
                        class="w-full p-2 text-center"
                    >
                        {{ `No ${title} available.` }}
                    </div>
                </div>
            </span>
        </template>
    </SidebarFilter>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onMounted, toRef } from 'vue';

    // <!-- COMPONENTS -->
    import SidebarFilter from '@/components/sidebar/SidebarFilter.vue';
    import LoadingDots from '@/components/LoadingDots.vue';
    import Treeview from 'vue3-treeview';

    // <!-- COMPOSABLES -->
    import { useWeatherStationFilter } from '@/components/sidebar/hooks/useWeatherStationFilter';

    // <!-- TYPES -->
    /** @typedef {import('@/components/alerts/hooks/useAlerts').AlertDef} AlertDef */

    // <!-- EVENTS -->
    import { TREEVIEW } from '@/components/sidebar/events';
    import { DismissAlertEvent } from '@/components/alerts/events';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'WeatherStationFilter',
        components: {
            SidebarFilter,
            LoadingDots,
            Treeview,
        },
        props: {
            /** Filter section title. */
            title: {
                /** @type {V.PropType<String>} */
                type: String,
                required: true,
            },
            /** Treeview menu height. */
            menuHeight: {
                /** @type {V.PropType<String>} */
                type: String,
                default: '',
            },
            /** If true, show the top border. */
            border: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: true,
            },
            /** Alert props. */
            alerts: {
                /** @type {V.PropType<AlertDef[]>} */
                type: Array,
                default: () => [],
            },
            /** Checked limits. */
            checkedLimit: {
                /** @type {V.PropType<Number>} */
                type: Number,
                default: Infinity,
            },
            /** If true, show the buttons. */
            showButtons: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** If true, the items are currently loading. */
            isLoading: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            debug: {
                type: Boolean,
                default: false,
            },
        },
        emits: [
            TREEVIEW.ExpandEvent,
            TREEVIEW.CollapseEvent,
            TREEVIEW.CheckAllEvent,
            TREEVIEW.CheckNoneEvent,
            TREEVIEW.NodeOpenedEvent,
            TREEVIEW.NodeClosedEvent,
            TREEVIEW.NodeCheckedEvent,
            TREEVIEW.NodeUncheckedEvent,
            TREEVIEW.NodeToggleEvent,
            TREEVIEW.NodeFocusEvent,
            TREEVIEW.NodeBlurEvent,
            DismissAlertEvent,
        ],
        setup(props, context) {
            // DESTRUCTURE reactive props.

            /** Maximum allowed weather stations that can be checked at one time. */
            const checkedLimit = toRef(props, 'checkedLimit');

            // DEFINE composable filter behaviour.

            /** Create the weather station filter state and methods. */
            const filter = useWeatherStationFilter({ checkedLimit });
            const { service, constants, state, properties, methods } = filter;

            // DESTRUCTURE state.
            const { alerts, buttons, tree } = state;

            // DESTRUCTURE conditionals.
            const { isBusy, isEmpty } = properties;

            // DESTRUCTURE methods.
            const {
                getTreeEventHandler,
                initializeAlerts,
                initializeTree,
                onAlertDismissed,
                listen,
            } = methods;

            // Run once mounted.
            onMounted(async () => {
                // Trigger fetch requests (if not cached).
                await service.weatherStationIndex.refreshWeatherStationIndex(
                    true
                );
                // Reset alerts.
                initializeAlerts();
                // Initialize the tree.
                initializeTree();
                // Initialize watchers.
                listen();
            });

            // ==== EXPOSE ====
            return {
                // EVENTS
                TREEVIEW,
                // STATE,
                treeAlerts: alerts,
                // PROPERTIES,
                tree,
                // CONDITIONALS
                isBusy,
                isEmpty,
                // METHODS,
                getTreeEventHandler,
                onAlertDismissed,
                // DEBUG
                state,
                stations: service.weatherStationIndex.stations,
            };
        },
    });
</script>

<style lang="scss" scoped>
    #tree-stations::v-deep {
        .vue-treeselect {
            position: static;
        }

        .vue-treeselect__control {
            display: none;
        }

        .vue-treeselect__menu {
            margin-top: 0;
            border: none;
            box-shadow: none;
            max-height: calc(100vh - 4rem) !important;
            position: static;
        }

        .vue-treeselect__menu-container {
            position: static;
        }
    }

    .level-enter-active,
    .level-leave-active {
        transition: opacity 0.5s ease, transform 0.5s ease-out;
    }

    .level-leave-from,
    .level-enter-to {
        opacity: 1;
        transform: translateX(0px);
    }

    .level-enter-from,
    .level-leave-to {
        opacity: 0;
        transform: translateX(-1rem);
    }
</style>
