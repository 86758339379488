// <!-- TYPES -->
import { Model } from '@/models/Model';
import { DynamicEnumFactory } from '@/utils/DynamicEnum';
/** @typedef {ReturnType<LocationStats['toPayload']>} LocationStatsPayload */
/** @typedef {ReturnType<LocationStats['toResource']>} LocationStatsResource */

/** Model attribute names. */
const FIELDS = DynamicEnumFactory().fromKeys([
    's_start_date',
    's_end_date',
    's_temp_mean',
    's_temp_min',
    's_temp_max',
    's_rh_mean',
    's_rh_min',
    's_rh_max',
    's_dp_mean',
    's_dp_min',
    's_dp_max',
    's_temp_sd',
    's_rh_sd',
    's_dp_sd',
    's_t_below',
    's_t_in',
    's_t_above',
    's_rh_below',
    's_rh_in',
    's_rh_above',
    's_dp_below',
    's_dp_in',
    's_dp_above',
    's_t_median',
    's_rh_median',
    's_dp_median',
]);

/**
 * @class
 */
export class LocationStats extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    /**
     * @param {LocationStatsPayload} payload
     * @returns {this}
     */
    parsePayload(payload) {
        /** @type {readonly (readonly [string, any])[]} */
        const entries = [
            [FIELDS.s_start_date, payload[0]],
            [FIELDS.s_end_date, payload[1]],
            [FIELDS.s_temp_mean, payload[2]],
            [FIELDS.s_temp_min, payload[3]],
            [FIELDS.s_temp_max, payload[4]],
            [FIELDS.s_rh_mean, payload[5]],
            [FIELDS.s_rh_min, payload[6]],
            [FIELDS.s_rh_max, payload[7]],
            [FIELDS.s_dp_mean, payload[8]],
            [FIELDS.s_dp_min, payload[9]],
            [FIELDS.s_dp_max, payload[10]],
            [FIELDS.s_temp_sd, payload[11]],
            [FIELDS.s_rh_sd, payload[12]],
            [FIELDS.s_dp_sd, payload[13]],
            [FIELDS.s_t_below, payload[14]],
            [FIELDS.s_t_in, payload[15]],
            [FIELDS.s_t_above, payload[16]],
            [FIELDS.s_rh_below, payload[17]],
            [FIELDS.s_rh_in, payload[18]],
            [FIELDS.s_rh_above, payload[19]],
            [FIELDS.s_dp_below, payload[20]],
            [FIELDS.s_dp_in, payload[21]],
            [FIELDS.s_dp_above, payload[22]],
            [FIELDS.s_t_median, payload[23]],
            [FIELDS.s_rh_median, payload[24]],
            [FIELDS.s_dp_median, payload[25]],
        ];
        return this.parseArray(entries);
    }

    toPayload() {
        return [
            /** @type {string} */
            this.get(FIELDS.s_start_date),
            /** @type {string} */
            this.get(FIELDS.s_end_date),
            /** @type {number} */
            this.get(FIELDS.s_temp_mean),
            /** @type {number} */
            this.get(FIELDS.s_temp_min),
            /** @type {number} */
            this.get(FIELDS.s_temp_max),
            /** @type {number} */
            this.get(FIELDS.s_rh_mean),
            /** @type {number} */
            this.get(FIELDS.s_rh_min),
            /** @type {number} */
            this.get(FIELDS.s_rh_max),
            /** @type {number} */
            this.get(FIELDS.s_dp_mean),
            /** @type {number} */
            this.get(FIELDS.s_dp_min),
            /** @type {number} */
            this.get(FIELDS.s_dp_max),
            /** @type {number} */
            this.get(FIELDS.s_temp_sd),
            /** @type {number} */
            this.get(FIELDS.s_rh_sd),
            /** @type {number} */
            this.get(FIELDS.s_dp_sd),
            /** @type {number} */
            this.get(FIELDS.s_t_below),
            /** @type {number} */
            this.get(FIELDS.s_t_in),
            /** @type {number} */
            this.get(FIELDS.s_t_above),
            /** @type {number} */
            this.get(FIELDS.s_rh_below),
            /** @type {number} */
            this.get(FIELDS.s_rh_in),
            /** @type {number} */
            this.get(FIELDS.s_rh_above),
            /** @type {number} */
            this.get(FIELDS.s_dp_below),
            /** @type {number} */
            this.get(FIELDS.s_dp_in),
            /** @type {number} */
            this.get(FIELDS.s_dp_above),
            /** @type {number} */
            this.get(FIELDS.s_t_median),
            /** @type {number} */
            this.get(FIELDS.s_rh_median),
            /** @type {number} */
            this.get(FIELDS.s_dp_median),
        ];
    }

    toResource() {
        return {
            /** @type {string} */
            [FIELDS.s_start_date]: this.get(FIELDS.s_start_date),
            /** @type {string} */
            [FIELDS.s_end_date]: this.get(FIELDS.s_end_date),
            /** @type {number} */
            [FIELDS.s_temp_mean]: this.get(FIELDS.s_temp_mean),
            /** @type {number} */
            [FIELDS.s_temp_min]: this.get(FIELDS.s_temp_min),
            /** @type {number} */
            [FIELDS.s_temp_max]: this.get(FIELDS.s_temp_max),
            /** @type {number} */
            [FIELDS.s_rh_mean]: this.get(FIELDS.s_rh_mean),
            /** @type {number} */
            [FIELDS.s_rh_min]: this.get(FIELDS.s_rh_min),
            /** @type {number} */
            [FIELDS.s_rh_max]: this.get(FIELDS.s_rh_max),
            /** @type {number} */
            [FIELDS.s_dp_mean]: this.get(FIELDS.s_dp_mean),
            /** @type {number} */
            [FIELDS.s_dp_min]: this.get(FIELDS.s_dp_min),
            /** @type {number} */
            [FIELDS.s_dp_max]: this.get(FIELDS.s_dp_max),
            /** @type {number} */
            [FIELDS.s_temp_sd]: this.get(FIELDS.s_temp_sd),
            /** @type {number} */
            [FIELDS.s_rh_sd]: this.get(FIELDS.s_rh_sd),
            /** @type {number} */
            [FIELDS.s_dp_sd]: this.get(FIELDS.s_dp_sd),
            /** @type {number} */
            [FIELDS.s_t_below]: this.get(FIELDS.s_t_below),
            /** @type {number} */
            [FIELDS.s_t_in]: this.get(FIELDS.s_t_in),
            /** @type {number} */
            [FIELDS.s_t_above]: this.get(FIELDS.s_t_above),
            /** @type {number} */
            [FIELDS.s_rh_below]: this.get(FIELDS.s_rh_below),
            /** @type {number} */
            [FIELDS.s_rh_in]: this.get(FIELDS.s_rh_in),
            /** @type {number} */
            [FIELDS.s_rh_above]: this.get(FIELDS.s_rh_above),
            /** @type {number} */
            [FIELDS.s_dp_below]: this.get(FIELDS.s_dp_below),
            /** @type {number} */
            [FIELDS.s_dp_in]: this.get(FIELDS.s_dp_in),
            /** @type {number} */
            [FIELDS.s_dp_above]: this.get(FIELDS.s_dp_above),
            /** @type {number} */
            [FIELDS.s_t_median]: this.get(FIELDS.s_t_median),
            /** @type {number} */
            [FIELDS.s_rh_median]: this.get(FIELDS.s_rh_median),
            /** @type {number} */
            [FIELDS.s_dp_median]: this.get(FIELDS.s_dp_median),
        };
    }
}
