// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';

// <!-- UTILITIES -->
import { isWithinInterval, fromUnixTime } from 'date-fns';

// <!-- TYPES -->
/** @typedef {import('@/models/locations/Location').LocationResource} LocationResource */
import { LocationRisks } from '@/models/locations/LocationRisks';
import { LocationStats } from '@/models/locations/LocationStats';

// <!-- BASE URL -->
const LEGACY_BASE_URL = Object.freeze(
    /** @type {const} */ ({
        V1: `${process.env.VUE_APP_API_PREFIX}/actions/v1`,
        V2: `${process.env.VUE_APP_API_PREFIX}/actions/v2`,
    })
);

// <!-- ROUTES -->
const ROUTES = {
    GET_DATA: () => `${process.env.VUE_APP_API_PREFIX}/api/v1/nara/data`,
    GET_RISKS: () => `${process.env.VUE_APP_API_PREFIX}/api/v1/nara/risks`,
    GET_STATS: () => `${process.env.VUE_APP_API_PREFIX}/api/v1/nara/statistics`,
    GET_WEATHER_DATA: () => 'getWeatherData.php',
    GET_WEATHER_RISKS: () => 'getWeatherRisks.php',
    GET_WEATHER_STATS: () => 'getWeatherStats.php',
};

// <!-- REQUESTS -->

/** @typedef {{ location: number, metric: 'T' | 'RH' | 'TRH' | 'DP' | 'MOLD' | 'PI' | 'TWPI' | 'DC' | 'EMC', start_time: number, end_time: number }} NARALocationDataRequest */
/** @typedef {{ loc: number, metric: 'T' | 'RH' | 'TRH' | 'DP' | 'MOLD' | 'PI' | 'TWPI' | 'DC' | 'EMC', startd: number, endd: number, startz?: number, endz?: number }} NARAWeatherStationDataRequest */

/** @typedef {{ location: number, start_time: number, end_time: number, tll?: number, tul?: number, rhll?: number, rhul?: number, dpll?: number, dpul?: number }} NARALocationStatisticsRequest */
/** @typedef {{ loc: number, startd: number, endd: number, tll?: number, tul?: number, rhll?: number, rhul?: number, dpll?: number, dpul?: number }} NARAWeatherStationStatisticsRequest */

/** @typedef {{ location: number, start_time: number, end_time: number }} NARALocationRisksRequest */
/** @typedef {{ loc: number, startd: number, endd: number }} NARAWeatherStationRisksRequest */

// <!-- RESPONSES -->

/** @typedef {{ metric: 'T' | 'RH' | 'TRH' | 'DP' | 'MOLD' | 'PI' | 'TWPI' | 'DC' | 'EMC', temperature_scale?: 'F' | 'C', timestamp_precision: 'seconds' | 'milliseconds', location_id: number, location_timezone: string, server_timezone: string, number_of_records?: number, filter_start: string, filter_end: string, data_start?: string, data_end?: string, status: number, version: 'v2' }} MetadataResponse */
/** @typedef {Combine<MetadataResponse & (TDataResponse | RHDataResponse | TRHDataResponse | DPDataResponse | MOLDDataResponse | PIDataResponse | TWPIDataResponse | DCDataResponse | EMCDataResponse)>} NARALocationDataResponse */
/** @typedef {TDataResponse | RHDataResponse | TRHDataResponse | DPDataResponse | MOLDDataResponse | PIDataResponse | TWPIDataResponse | DCDataResponse | EMCDataResponse} NARAWeatherStationDataResponse */
/** @typedef {{ data: Array<[ timestamp: number, T: number ]>, errors?: Record<string, string[]>, status?: number, version?: string }} TDataResponse */
/** @typedef {{ data: Array<[ timestamp: number, RH: number ]>, errors?: Record<string, string[]>, status?: number, version?: string }} RHDataResponse */
/** @typedef {{ data: Array<[ timestamp: number, T: number, RH: number ]>, errors?: Record<string, string[]>, status?: number, version?: string }} TRHDataResponse */
/** @typedef {{ data: Array<[ timestamp: number, DP: number ]>, errors?: Record<string, string[]>, status?: number, version?: string }} DPDataResponse */
/** @typedef {{ data: Array<[ timestamp: number, MOLD: number ]>, errors?: Record<string, string[]>, status?: number, version?: string }} MOLDDataResponse */
/** @typedef {{ data: Array<[ timestamp: number, PI: number ]>, errors?: Record<string, string[]>, status?: number, version?: string }} PIDataResponse */
/** @typedef {{ data: Array<[ timestamp: number, TWPI: number, PI: number ]>, errors?: Record<string, string[]>, status?: number, version?: string }} TWPIDataResponse */
/** @typedef {{ data: Array<[ timestamp: number, DC: number ]>, errors?: Record<string, string[]>, status?: number, version?: string }} DCDataResponse */
/** @typedef {{ data: Array<[ timestamp: number, EMC: number ]>, errors?: Record<string, string[]>, status?: number, version?: string }} EMCDataResponse */

/** @typedef {Combine<MetadataResponse & { data: { start_date: string, end_date: string, temp_min: number, temp_max: number, temp_mean: number, temp_median: number, temp_sd: number, temp_below: number, temp_in: number, temp_above: number, rh_min: number, rh_max: number, rh_mean: number, rh_median: number, rh_sd: number, rh_below: number, rh_in: number, rh_above: number, dp_min: number, dp_max: number, dp_mean: number, dp_median: number, dp_sd: number, dp_below: number, dp_in: number, dp_above: number }, errors?: Record<string, string[]> }>} NARALocationStatisticsResponse */
/** @typedef {{ data: { start_date: string, end_date: string, temp_min: number, temp_max: number, temp_mean: number, temp_median: number, temp_sd: number, temp_below: number, temp_in: number, temp_above: number, rh_min: number, rh_max: number, rh_mean: number, rh_median: number, rh_sd: number, rh_below: number, rh_in: number, rh_above: number, dp_min: number, dp_max: number, dp_mean: number, dp_median: number, dp_sd: number, dp_below: number, dp_in: number, dp_above: number }, scale: 'F' | 'C', errors?: Record<string, string[]>, status?: number, version?: string }} NARAWeatherStationStatisticsResponse */

/** @typedef {Combine<MetadataResponse & { data: { start_date: string, end_date: string, temp_mean: number, rh_mean: number, dp_mean: number, mrf: number, last_twpi: number, dc_min: number, dc_max: number, dc_mean: number, dc_span: number, emc_min: number, emc_max: number, emc_mean: number }, errors?: Record<string, string[]> }>} NARALocationRisksResponse */
/** @typedef {{ data: { start_date: string, end_date: string, temp_mean: number, rh_mean: number, dp_mean: number, mrf: number, last_twpi: number, dc_min: number, dc_max: number, dc_span: number, emc_min: number, emc_max: number, emc_mean: number }, scale: 'F' | 'C', errors?: Record<string, string[]>, status?: number, version?: string }} NARAWeatherStationRisksResponse */

/**
 * Fetch risks for the specified location.
 * @param {Pick<LocationResource, 'id' | 'name'> & { minDate: number, maxDate: number }} location
 * @param {import('@/types').AxiosRequestConfig} [config]
 * @returns {Promise<import('@/models/locations/LocationRisks').LocationRisksResource>}
 */
export const fetchLocationRisks = async (
    location = {
        id: 62313,
        name: 'Mock Location',
        minDate: 1000000,
        maxDate: 1893456000,
    },
    config
) => {
    /** @type {NARALocationRisksRequest} */
    const request = {
        location: location?.id,
        start_time: location?.minDate ?? 1000000,
        end_time: location?.maxDate ?? 1893456000,
    };

    /** @type {import('axios').AxiosResponse<NARALocationRisksResponse, NARALocationRisksRequest>} */
    const response = await axios().get(ROUTES.GET_RISKS(), {
        ...(config ?? {}),
        baseURL: LEGACY_BASE_URL.V2,
        params: request,
    });

    const payload = response.data;
    const risks = new LocationRisks({}).toResource();
    risks.r_start_date = payload.data.start_date;
    risks.r_end_date = payload.data.end_date;
    risks.r_temp_mean = payload.data.temp_mean;
    risks.r_rh_mean = payload.data.rh_mean;
    risks.r_dp_mean = payload.data.dp_mean;
    risks.r_mrf = payload.data.mrf;
    risks.r_last_twpi = payload.data.last_twpi;
    risks.r_dc_min = payload.data.dc_min;
    risks.r_dc_max = payload.data.dc_max;
    // risks.r_dc_mean = payload.data.dc_mean;
    risks.r_dc_span = payload.data.dc_span;
    risks.r_emc_min = payload.data.emc_min;
    risks.r_emc_max = payload.data.emc_max;
    risks.r_emc_mean = payload.data.emc_mean;

    // TEMPORARY HOTFIX: Convert start date from GMT timezone to user's local timezone.
    // 1. PARSE the date from its ISO-8601 string.
    // 2. GET the short date (without time component).
    if (!!risks.r_start_date) {
        const local_start = new Date(risks.r_start_date);
        const formatted_start = local_start.toLocaleDateString('en-CA'); // `${local_start.getFullYear()}-${local_start.getMonth()}-${local_start.getDate()}`;
        risks.r_start_date = formatted_start;
    }

    // TEMPORARY HOTFIX: Convert end date from GMT timezone to user's local timezone.
    // 1. PARSE the date from its ISO-8601 string.
    // 2. GET the short date (without time component).
    if (!!risks.r_end_date) {
        const local_end = new Date(risks.r_end_date);
        const formatted_end = local_end.toLocaleDateString('en-CA'); // `${local_end.getFullYear()}-${local_end.getMonth()}-${local_end.getDate()}`;
        risks.r_end_date = formatted_end;
    }

    return risks;
};

/**
 * Fetch stats for the specified location.
 * @param {Pick<LocationResource, 'id' | 'name'> & { minDate: number, maxDate: number, tll?: number, tul?: number, rhll?: number, rhul?: number, dpll?: number, dpul?: number }} location
 * @param {import('@/types').AxiosRequestConfig} [config]
 * @returns {Promise<import('@/models/locations/LocationStats').LocationStatsResource>}
 */
export const fetchLocationStats = async (
    location = {
        id: 62313,
        name: 'Mock Location',
        minDate: 1000000,
        maxDate: 1893456000,
    },
    config
) => {
    /** @type {NARALocationStatisticsRequest} */
    const request = {
        location: location?.id,
        start_time: location?.minDate ?? 1000000,
        end_time: location?.maxDate ?? 1893456000,
        tll: location?.tll ?? null,
        tul: location?.tul ?? null,
        rhll: location?.rhll ?? null,
        rhul: location?.rhul ?? null,
        dpll: location?.dpll ?? null,
        dpul: location?.dpul ?? null,
    };

    /** @type {import('axios').AxiosResponse<NARALocationStatisticsResponse>} */
    const response = await axios().get(ROUTES.GET_STATS(), {
        ...(config ?? {}),
        baseURL: LEGACY_BASE_URL.V2,
        params: request,
    });

    const payload = response.data;
    const stats = new LocationStats({}).toResource();
    stats.s_start_date = payload.data.start_date;
    stats.s_end_date = payload.data.end_date;
    stats.s_temp_min = payload.data.temp_min;
    stats.s_temp_max = payload.data.temp_max;
    stats.s_temp_mean = payload.data.temp_mean;
    stats.s_t_median = payload.data.temp_median;
    stats.s_temp_sd = payload.data.temp_sd;
    stats.s_t_below = payload.data.temp_below;
    stats.s_t_in = payload.data.temp_in;
    stats.s_t_above = payload.data.temp_above;
    stats.s_rh_min = payload.data.rh_min;
    stats.s_rh_max = payload.data.rh_max;
    stats.s_rh_mean = payload.data.rh_mean;
    stats.s_rh_median = payload.data.rh_median;
    stats.s_rh_sd = payload.data.rh_sd;
    stats.s_rh_below = payload.data.rh_below;
    stats.s_rh_in = payload.data.rh_in;
    stats.s_rh_above = payload.data.rh_above;
    stats.s_dp_min = payload.data.dp_min;
    stats.s_dp_max = payload.data.dp_max;
    stats.s_dp_mean = payload.data.dp_mean;
    stats.s_dp_median = payload.data.dp_median;
    stats.s_dp_sd = payload.data.dp_sd;
    stats.s_dp_below = payload.data.dp_below;
    stats.s_dp_in = payload.data.dp_in;
    stats.s_dp_above = payload.data.dp_above;

    // TEMPORARY HOTFIX: Convert start date from GMT timezone to user's local timezone.
    // 1. PARSE the date from its ISO-8601 string.
    // 2. GET the short date (without time component).
    if (!!stats.s_start_date) {
        const local_start = new Date(stats.s_start_date);
        const formatted_start = local_start.toLocaleDateString('en-CA'); // `${local_start.getFullYear()}-${local_start.getMonth()}-${local_start.getDate()}`;
        stats.s_start_date = formatted_start;
    }

    // TEMPORARY HOTFIX: Convert end date from GMT timezone to user's local timezone.
    // 1. PARSE the date from its ISO-8601 string.
    // 2. GET the short date (without time component).
    if (!!stats.s_end_date) {
        const local_end = new Date(stats.s_end_date);
        const formatted_end = local_end.toLocaleDateString('en-CA'); // `${local_end.getFullYear()}-${local_end.getMonth()}-${local_end.getDate()}`;
        stats.s_end_date = formatted_end;
    }

    return stats;
};

/**
 * Fetch data for the specified location.
 * @template {'T' | 'RH' | 'TRH' | 'DP' | 'MOLD' | 'PI' | 'TWPI' | 'DC' | 'EMC'} [Metric='T' | 'RH' | 'TRH' | 'DP' | 'MOLD' | 'PI' | 'TWPI' | 'DC' | 'EMC']
 * @param {{ id?: Number, name?: String, metric?: Metric, dateStart?: String, dateEnd?: String }} location
 * @param {import('@/types').AxiosRequestConfig} [config]
 * @returns {Promise<{ id: Number, metric: Metric, name: String, data: Array<[ x: Number, y: Number ]> }>}
 */
export const fetchLocationData = async (
    location = {
        id: 62313,
        name: 'Mock Location',
        dateStart: '1000000',
        dateEnd: '1893456000',
    },
    config
) => {
    /** @type {NARALocationDataRequest} */
    const request = {
        location: location?.id,
        metric: location.metric ?? 'T',
        start_time: Number(location?.dateStart ?? 1000000),
        end_time: Number(location?.dateEnd ?? 1893456000),
    };

    /** @type {import('axios').AxiosResponse<NARALocationDataResponse>} */
    const response = await axios().get(ROUTES.GET_DATA(), {
        ...(config ?? {}),
        baseURL: LEGACY_BASE_URL.V2,
        params: request,
    });

    const start = new Date(response?.data?.filter_start).getTime();
    const end = new Date(response?.data?.filter_end).getTime();
    const range = { start, end };

    /**
     * Parse data strings into numbers.
     * @param {Object} response
     * @param {NARALocationDataResponse['data']} response.data
     * @returns {Array<[ x: Number, y: Number ]>}
     */
    const parseData = ({ data }) => {
        if (!data) {
            // NO dataset.
            return [];
        } else if ('length' in data && data.length === 0) {
            // EMPTY dataset.
            return [];
        }
        return data
            .map((entry) => entry.map((i) => Number(i)))
            .filter(([x, y]) => {
                const currentDate = new Date(x.valueOf());
                const isValidDate = isWithinInterval(currentDate, range);
                return isValidDate === true;
            });
    };

    // Get the series data object.
    return {
        id: location?.id,
        metric: location?.metric,
        name: location?.name,
        data: parseData(response.data),
    };
};

/**
 * Fetch risks for the specified weather station.
 * @param {Pick<LocationResource, 'id' | 'name'> & { minDate: number, maxDate: number }} station
 * @param {import('@/types').AxiosRequestConfig} [config]
 * @returns {Promise<import('@/models/locations/LocationRisks').LocationRisksResource>}
 */
export const fetchWeatherStationRisks = async (
    station = {
        id: 4215,
        name: 'Troy, AL',
        minDate: 1000000,
        maxDate: 1893456000,
    },
    config
) => {
    /** @type {NARAWeatherStationRisksRequest} */
    const request = {
        loc: station?.id,
        startd: station?.minDate ?? 1000000,
        endd: station?.maxDate ?? 1893456000,
    };

    /** @type {import('axios').AxiosResponse<NARAWeatherStationRisksResponse, NARAWeatherStationRisksRequest>} */
    const response = await axios().get(ROUTES.GET_WEATHER_RISKS(), {
        ...(config ?? {}),
        baseURL: LEGACY_BASE_URL.V2,
        params: request,
    });

    const payload = response.data;
    const risks = new LocationRisks({}).toResource();
    risks.r_start_date = payload.data.start_date;
    risks.r_end_date = payload.data.end_date;
    risks.r_temp_mean = payload.data.temp_mean;
    risks.r_rh_mean = payload.data.rh_mean;
    risks.r_dp_mean = payload.data.dp_mean;
    risks.r_mrf = payload.data.mrf;
    risks.r_last_twpi = payload.data.last_twpi;
    risks.r_dc_min = payload.data.dc_min;
    risks.r_dc_max = payload.data.dc_max;
    risks.r_dc_span = payload.data.dc_span;
    risks.r_emc_min = payload.data.emc_min;
    risks.r_emc_max = payload.data.emc_max;
    risks.r_emc_mean = payload.data.emc_mean;
    return risks;
};

/**
 * Fetch stats for the specified weather station.
 * @param {Pick<LocationResource, 'id' | 'name'> & { minDate: number, maxDate: number, tll?: number, tul?: number, rhll?: number, rhul?: number, dpll?: number, dpul?: number }} station
 * @param {import('@/types').AxiosRequestConfig} [config]
 * @returns {Promise<import('@/models/locations/LocationStats').LocationStatsResource>}
 */
export const fetchWeatherStationStats = async (
    station = {
        id: 4215,
        name: 'Troy, AL',
        minDate: 1000000,
        maxDate: 1893456000,
    },
    config
) => {
    /** @type {NARALocationStatisticsRequest} */
    const request = {
        /** @ts-ignore */
        loc: station?.id,
        startd: station?.minDate ?? 1000000,
        endd: station?.maxDate ?? 1893456000,
        tll: station?.tll ?? 9999.99,
        tul: station?.tul ?? -9999.99,
        rhll: station?.rhll ?? 9999.99,
        rhul: station?.rhul ?? -9999.99,
        dpll: station?.dpll ?? 9999.99,
        dpul: station?.dpul ?? -9999.99,
    };

    /** @type {import('axios').AxiosResponse<NARAWeatherStationStatisticsResponse>} */
    const response = await axios().get(ROUTES.GET_WEATHER_STATS(), {
        ...(config ?? {}),
        baseURL: LEGACY_BASE_URL.V2,
        params: request,
    });

    const payload = response.data;
    const stats = new LocationStats({}).toResource();
    stats.s_start_date = payload.data.start_date;
    stats.s_end_date = payload.data.end_date;
    stats.s_temp_min = payload.data.temp_min;
    stats.s_temp_max = payload.data.temp_max;
    stats.s_temp_mean = payload.data.temp_mean;
    stats.s_t_median = payload.data.temp_median;
    stats.s_temp_sd = payload.data.temp_sd;
    stats.s_t_below = payload.data.temp_below;
    stats.s_t_in = payload.data.temp_in;
    stats.s_t_above = payload.data.temp_above;
    stats.s_rh_min = payload.data.rh_min;
    stats.s_rh_max = payload.data.rh_max;
    stats.s_rh_mean = payload.data.rh_mean;
    stats.s_rh_median = payload.data.rh_median;
    stats.s_rh_sd = payload.data.rh_sd;
    stats.s_rh_below = payload.data.rh_below;
    stats.s_rh_in = payload.data.rh_in;
    stats.s_rh_above = payload.data.rh_above;
    stats.s_dp_min = payload.data.dp_min;
    stats.s_dp_max = payload.data.dp_max;
    stats.s_dp_mean = payload.data.dp_mean;
    stats.s_dp_median = payload.data.dp_median;
    stats.s_dp_sd = payload.data.dp_sd;
    stats.s_dp_below = payload.data.dp_below;
    stats.s_dp_in = payload.data.dp_in;
    stats.s_dp_above = payload.data.dp_above;
    return stats;
};

/**
 * Fetch data for the specified weather station.
 * @template {'T' | 'RH' | 'TRH' | 'DP' | 'MOLD' | 'PI' | 'TWPI' | 'DC' | 'EMC'} [Metric='T' | 'RH' | 'TRH' | 'DP' | 'MOLD' | 'PI' | 'TWPI' | 'DC' | 'EMC']
 * @param {{ id?: number, name?: String, metric?: Metric, dateStart?: String, dateEnd?: String }} station
 * @param {import('@/types').AxiosRequestConfig} [config]
 * @returns {Promise<{ id: number, metric: Metric, name: String, data: Array<[ x: Number, y: Number ]> }>}
 */
export const fetchWeatherStationData = async (
    station = {
        id: 4215,
        name: 'Troy, AL',
        dateStart: '1000000',
        dateEnd: '1893456000',
    },
    config
) => {
    /** @type {NARAWeatherStationDataRequest} */
    const request = {
        loc: station?.id,
        metric: station.metric ?? 'T',
        startd: Number(station?.dateStart ?? 1000000),
        endd: Number(station?.dateEnd ?? 1893456000),
        startz: Number(station?.dateStart ?? 1000000),
        endz: Number(station?.dateEnd ?? 1893456000),
    };

    /** @type {import('axios').AxiosResponse<NARAWeatherStationDataResponse>} */
    const response = await axios().get(ROUTES.GET_WEATHER_DATA(), {
        ...(config ?? {}),
        baseURL: LEGACY_BASE_URL.V2,
        params: request,
    });

    const minDate = parseInt(station?.dateStart, 10);
    const maxDate = parseInt(station?.dateEnd, 10);
    const range = {
        start: fromUnixTime(minDate.valueOf()),
        end: fromUnixTime(maxDate.valueOf()),
    };

    /**
     * Parse data strings into numbers.
     * @param {Object} response
     * @param {NARAWeatherStationDataResponse['data']} response.data
     * @returns {Array<[ x: Number, y: Number ]>}
     */
    const parseData = ({ data }) => {
        if (!data) {
            // NO dataset.
            return [];
        } else if ('length' in data && data.length === 0) {
            // EMPTY dataset.
            return [];
        }
        return data
            .map((entry) => entry.map((i) => Number(i)))
            .filter(([x, y]) => {
                const currentDate = new Date(x.valueOf());
                const isValidDate = isWithinInterval(currentDate, range);
                return isValidDate === true;
            });
    };

    // Get the series data object.
    return {
        id: station?.id,
        metric: station?.metric,
        name: station?.name,
        data: parseData(response.data),
    };
};

// <!-- EXPORTS -->
export default {
    LocationRisks,
    LocationStats,
    fetchLocationRisks,
    fetchLocationStats,
    fetchLocationData,
};
