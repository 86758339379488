// <!-- API -->
import Highcharts from 'highcharts';
import { watch, reactive, ref, computed } from 'vue';
import { fetchLocationData, fetchWeatherStationData } from '@/api/legacy';

// <!-- MODULES -->
import initializeStockModule from 'highcharts/modules/stock';
import initializeExportData from 'highcharts/modules/export-data';
import initializeExportingModule from 'highcharts/modules/exporting';
import initializeOfflineExportingModule from 'highcharts/modules/offline-exporting';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useECNBCache } from '@/hooks/store/useECNBCache';
import { useLocationIndex } from '@/hooks/cache/useLocationIndex';
import { useWeatherStationIndex } from '@/hooks/cache/useWeatherStationIndex';

// <!-- UTILITIES -->
import clone from 'just-clone';
import compare from 'just-compare';
import omit from 'just-omit';
import isNil from 'lodash-es/isNil';
import {
    getUnixTime,
    isWithinInterval,
    startOfDay,
    endOfDay,
    sub,
} from 'date-fns';
import {
    formatGraphTitle,
    formatGraphSubtitle,
    formatGraphFilename,
} from '@/features/analysis/utils/formatters';
import { POJO } from '@/utils/POJO';
import { Emoji } from '@/utils/emoji';
import { Constants } from '@/utils/constants';
import { DateRange } from '@/utils/filters';
import {
    DateRangeFilter,
    LimitFilter,
    LimitFilterRecord,
    ScaleFilter,
    ScaleFilterRecord,
    LocationFilter,
    WeatherStationFilter,
} from '@/utils/filters';
import { Tree, Node, NodeRecord, NodeState, NodeSelector } from '@/utils/tree';
import { diff } from 'just-diff';
import is from '@sindresorhus/is';

//=== VUE ====//
/**  */ /** Component} Component */

/** @template {V.EmitsOptions} [E=any]  */ /** SetupContext<E>} SetupContext<E> */
/** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */
/** @typedef {Router.Router} Router */
/** @template [T=unknown]  */ /** WatchSource<T>} WatchSource */
/**  */ /** WatchStopHandle} WatchStopHandle */
/**  */ /** WatchCallback} WatchCallback */
/**  */ /** WatchOptions} WatchOptions */
/**  */ /** WatchEffect} WatchEffect */

//=== STORE ====//
/** @typedef {import('@/store/types/ECNBStore').ECNBState} ECNBState */

//=== TYPES ====//
/** @typedef {import('@/utils/filters').IDate} IDate */
/** @typedef {import('@/utils/filters').IInterval} IInterval */
/** @typedef {import('@/utils/filters').ITimestamp} ITimestamp */

//=== FILTERS ====//
/** @typedef {import('@/utils/filters').IAxisRangeFilter} IAxisRangeFilter */
/** @typedef {import('@/utils/filters').IDateRangeFilter} IDateRangeFilter */
/** @typedef {import('@/utils/filters').ILimitFilter} ILimitFilter */
/** @typedef {import('@/utils/filters').IScaleFilter} IScaleFilter */
/** @typedef {import('@/utils/filters').ILocationFilter} ILocationFilter */
/** @typedef {import('@/utils/filters').IWeatherStationFilter} IWeatherStationFilter */

//=== RECORDS ====//
/** @typedef {import('@/utils/filters').ILimitFilterRecord} ILimitFilterRecord */
/** @typedef {import('@/utils/filters').IScaleFilterRecord} IScaleFilterRecord */

//=== MODELS ====//
import { LocationResource } from '@/models/locations/Location';
import { useDebounceFn } from '@vueuse/core';
/** @typedef {import('@/models/weather/WeatherStation').WeatherStationResource} WeatherStationResource */

//=== UTILITIES ====//

/**
 * @class
 * Class used to manipulate {@link Higcharts.Options}.
 */
export class ChartOptions {
    // CONSTANTS used while defining the default options.
    static XAXIS_MIN_RANGE = Math.round(3600 * 1000 * 0.5); // 30 minute segments.
    static XAXIS_LABEL_FORMAT = `{value:%Y-%m-%d}`;
    static EXPORT_DEFAULT_TYPE = `image/png`;

    /**
     * Instance of chart options with default values.
     * @type {Readonly<Highcharts.Options>}
     */
    static DefaultOptions = Object.freeze(
        /**
         * @type {Highcharts.Options} Global Highcharts options.
         * Use this area to define defaults and global options
         * for all Highcharts charts on a given page.
         *
         * If your option is not static (eg. dynamic, reactive)
         * you should use the {@link AnalysisChartState.chartOptions} field.
         */ ({
            chart: {
                zoomType: 'x',
                animation: true,
                events: {
                    load: undefined, // NOTE: Bind using `onAfterChartLoaded.value`.
                },
            },
            plotOptions: {
                series: {
                    enableMouseTracking: true, // NOTE: Bind using `enableMouseTracking.value`.
                    showInLegend: true,
                    showInNavigator: true,
                },
                column: {
                    opacity: 0.7,
                },
            },
            scrollbar: {
                enabled: true,
                showFull: true,
                height: 12,
                liveRedraw: true,
            },
            series: [
                // NOTE: Hydrate using `hydrateSeries(SeriesLineOptions[])`.
            ],
            xAxis: {
                id: 'Time_axis',
                type: 'datetime',
                minRange: ChartOptions.XAXIS_MIN_RANGE, // 30 minutes.
                labels: {
                    format: ChartOptions.XAXIS_LABEL_FORMAT,
                },
                events: {
                    afterSetExtremes: undefined, // NOTE: Bind using `onAfterSetXAxisExtremes.value`.
                },
                ordinal: false,
                startOnTick: false,
                endOnTick: false,
                showEmpty: true,
            },
            yAxis: [
                // NOTE: Hydrate using `hydrateYAxisChartOptions(Highcharts.YAxisOptions[])`.
            ],
            legend: {
                enabled: true,
                verticalAlign: /** @type {"top"} */ ('top'),
                symbolHeight: 10,
                symbolWidth: 10,
            },
            title: {
                text: 'Graph', // NOTE: Bind using `title.value`.
            },
            subtitle: {
                text: '01/01/2022 to 12/31/2022', // NOTE: Bind using `subtitle.value`.
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: true,
                allowHTML: true,
                // NOTE: Exporting is handled by a handler function,
                // with a custom UI button. We are hiding their native button,
                // but we can still assign global properties here.
                buttons: {
                    contextButton: {
                        enabled: false,
                    },
                },
                error: undefined, // NOTE: Bind using `onAfterExportError.value`.
                chartOptions: {
                    scrollbar: {
                        enabled: false,
                    },
                    navigator: {
                        enabled: false,
                    },
                },
                fallbackToExportServer: false,
                sourceHeight: 600,
                sourceWidth: 1000,
                height: 600,
                width: 1000,
                scale: 4,
                showTable: false,
                tableCaption: false,
                type: ChartOptions.EXPORT_DEFAULT_TYPE, // NOTE: Bind using `exportOptions.value`
            },
            tooltip: {
                enabled: true,
                shared: true,
                padding: 5,
            },
            navigator: {
                enabled: true,
                adaptToUpdatedData: true,
                series: {
                    // NOTE: Hydrated when `hydrateSeries(SeriesLineOptions[])` is called.
                    data: [],
                    turboThreshold: 99999,
                },
                xAxis: {
                    ordinal: false,
                },
            },
            rangeSelector: {
                // See: https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/stock/demo/lazy-loading/
                enabled: false, // NOTE: Currently disabled. Could potentially be toggled in the future for commercial.
                buttons: [
                    {
                        type: 'hour',
                        count: 1,
                        text: '1h',
                    },
                    {
                        type: 'day',
                        count: 1,
                        text: '1d',
                    },
                    {
                        type: 'month',
                        count: 1,
                        text: '1m',
                    },
                    {
                        type: 'year',
                        count: 1,
                        text: '1y',
                    },
                    {
                        type: 'all',
                        text: 'All',
                    },
                ],
                inputEnabled: false, // it supports only days
                selected: 2, // month
            },
        })
    );

    /**
     * Instantiate POJO containing highchart options.
     * @param {Readonly<Partial<Highcharts.Options>>} [props]
     * @returns {Highcharts.Options}
     */
    static create = (props = {}) => {
        // CREATE plain JavaScript object from the passed instance.
        const instance = POJO.create(props, ChartOptions.DefaultOptions);
        return instance;
    };

    /**
     * Create an overridden instance from a patch applied to a source {@link Highcharts.Options}.
     * @param {Readonly<Highcharts.Options>} source Source object.
     * @param {Readonly<Partial<Highcharts.Options>>} [patch] Changes to apply to the source options object.
     * @returns {Highcharts.Options}
     */
    static override = (source, patch = undefined) => {
        // CHECK if source is provided.
        if (isNil(source)) {
            // If no source object is given,
            // use the 'DefaultOptions' as the
            // initial source instead.
            return ChartOptions.create(patch);
        }

        // RETURN overridden object.
        const target = POJO.override(source, patch, true);
        return target;
    };
}

/**
 * @class
 * Class used to manipulate vertical axes.
 */
export class YAxis {
    // CONSTANTS.
    static PRIMARY_AXIS = /** @type {const} */ (0);
    static SECONDARY_AXIS = /** @type {const} */ (1);

    /**
     * Get the formatted id.
     * @param {string} key
     */
    static formatID = (key) => `${key}_<axis>`;

    /**
     * Default vertical axis settings.
     * @type {Readonly<Highcharts.YAxisOptions>}
     */
    static DefaultAxisOptions = Object.freeze({
        id: YAxis.formatID(`$default`),
        alignTicks: false,
        allowDecimals: true,
        type: 'linear',
        offset: 30,
        margin: 2,
        // Each tick unit corresponds to a fixed pixel height.
        staticScale: 10,
        // By default, axis is visible.
        visible: true,
        // Show axis when there is no data inside it.
        showEmpty: true,
        // Contains: YAxisPlotLinesOptions
        plotLines: [],
        // Enable zooming on axes.
        zoomEnabled: true,
    });

    /**
     * Instantiate POJO containing highchart options.
     * @template {unknown} [K=unknown]
     * @param {K extends string ? K : never} [id]
     * @param {Readonly<Partial<Highcharts.YAxisOptions>>} [props]
     * @returns {Highcharts.YAxisOptions}
     */
    static create = (id, props = {}) => {
        // CREATE plain JavaScript object from the passed instance.
        const instance = POJO.create(
            { ...props, id },
            YAxis.DefaultAxisOptions
        );
        return instance;
    };

    /**
     * Create an overridden instance from a patch applied to a source {@link Highcharts.YAxisOptions}.
     * @param {Readonly<Highcharts.YAxisOptions>} source Source object.
     * @param {Readonly<Partial<Highcharts.YAxisOptions>>} [patch] Changes to apply to the source options object.
     */
    static override = (source, patch = undefined) => {
        // CHECK if source is provided.
        if (isNil(source)) {
            // If no source object is given,
            // use the 'DefaultOptions' as the
            // initial source instead.
            return YAxis.create(YAxis.DefaultAxisOptions.id, patch);
        }

        const target = POJO.override(source, patch);
        return target;
    };
}

/**
 * @class
 * Class used to manipulate {@link Highcharts.YAxisPlotLinesOptions} data.
 */
export class YAxisPlotLine {
    /**
     * Get the formatted id.
     * @param {keyof AnalysisChartConstants['AxisType']} axis
     * @param {string} key
     */
    static formatID = (axis, key) => `${key}_${axis}_<plotline>`;

    /**
     * Default vertical axis settings.
     * @type {Readonly<Highcharts.YAxisPlotLinesOptions>}
     */
    static DefaultYAxisPlotLineOptions = Object.freeze({
        id: YAxisPlotLine.formatID('T', '$default'),
        color: 'red',
        dashStyle: 'Dash',
        value: NaN,
        width: 3,
        zIndex: 5,
        label: {
            text: 'Reference',
        },
    });

    /**
     * Instantiate POJO containing highchart options.
     * @template {unknown} [K=unknown]
     * @param {K extends string ? K : never} id
     * @param {Readonly<Partial<Highcharts.YAxisPlotLinesOptions>>} [props]
     * @returns {Highcharts.YAxisPlotLinesOptions}
     */
    static create = (id, props = {}) => {
        // CREATE plain JavaScript object from the passed instance.
        const instance = POJO.create(
            { ...props, id },
            YAxisPlotLine.DefaultYAxisPlotLineOptions
        );
        return instance;
    };

    /**
     * Create an overridden instance from a patch applied to a source {@link Highcharts.YAxisPlotLinesOptions}.
     * @param {Readonly<Highcharts.YAxisPlotLinesOptions>} source Source object.
     * @param {Readonly<Partial<Highcharts.YAxisPlotLinesOptions>>} [patch] Changes to apply to the source options object.
     */
    static override = (source, patch = undefined) => {
        // CHECK if source is provided.
        if (isNil(source)) {
            // If no source object is given,
            // use the 'DefaultOptions' as the
            // initial source instead.
            return YAxisPlotLine.create(
                YAxisPlotLine.DefaultYAxisPlotLineOptions.id,
                patch
            );
        }

        // RETURN overridden object.
        const target = POJO.override(source, patch);
        return target;
    };
}

/**
 * @class
 * Class used to manipulate {@link Highcharts.Series} data.
 */
export class Series {
    /**
     * Get the formatted id.
     * @param {keyof AnalysisChartConstants['AxisType']} axis
     * @param {string} key
     */
    static formatID = (axis, key) => `${key}_${axis}_<series>`;

    /**
     * Default vertical axis settings.
     * @type {Readonly<Highcharts.SeriesLineOptions>}
     */
    static DefaultSeriesLineOptions = Object.freeze({
        id: Series.formatID('T', '$default'),
        type: 'line',
    });

    /**
     * Default vertical axis settings.
     * @type {Readonly<Highcharts.SeriesColumnOptions>}
     */
    static DefaultSeriesColumnOptions = Object.freeze({
        id: Series.formatID('PI', '$default'),
        type: 'column',
    });

    /**
     * Instantiate POJO containing highchart options.
     * @template {unknown} [K=unknown]
     * @param {K extends string ? K : never} id
     * @param {Readonly<Partial<Highcharts.SeriesLineOptions>>} [props]
     * @returns {Highcharts.SeriesLineOptions}
     */
    static createLine = (id, props = {}) => {
        // CREATE plain JavaScript object from the passed instance.
        const instance = POJO.create(
            { ...props, id },
            Series.DefaultSeriesLineOptions
        );
        return instance;
    };

    /**
     * Instantiate POJO containing highchart options.
     * @template {unknown} [K=unknown]
     * @param {K extends string ? K : never} id
     * @param {Readonly<Partial<Highcharts.SeriesColumnOptions>>} [props]
     * @returns {Highcharts.SeriesColumnOptions}
     */
    static createColumn = (id, props = {}) => {
        // CREATE plain JavaScript object from the passed instance.
        const instance = POJO.create(
            { ...props, id },
            Series.DefaultSeriesColumnOptions
        );
        return instance;
    };

    /**
     * Create an overridden instance from a patch applied to a source {@link Highcharts.SeriesLineOptions}.
     * @param {Readonly<Highcharts.SeriesLineOptions>} source Source object.
     * @param {Readonly<Partial<Highcharts.SeriesLineOptions>>} [patch] Changes to apply to the source options object.
     */
    static overrideLine = (source, patch = undefined) => {
        // CHECK if source is provided.
        if (isNil(source)) {
            // If no source object is given,
            // use the 'DefaultOptions' as the
            // initial source instead.
            return Series.createLine(Series.DefaultSeriesLineOptions.id, patch);
        }

        // RETURN overridden object.
        const target = POJO.override(source, patch);
        return target;
    };
    /**
     * Create an overridden instance from a patch applied to a source {@link Highcharts.SeriesColumnOptions}.
     * @param {Readonly<Highcharts.SeriesColumnOptions>} source Source object.
     * @param {Readonly<Partial<Highcharts.SeriesColumnOptions>>} [patch] Changes to apply to the source options object.
     */
    static overrideColumn = (source, patch = undefined) => {
        // CHECK if source is provided.
        if (isNil(source)) {
            // If no source object is given,
            // use the 'DefaultOptions' as the
            // initial source instead.
            return Series.createColumn(
                Series.DefaultSeriesColumnOptions.id,
                patch
            );
        }

        // RETURN overridden object.
        const target = POJO.override(source, patch);
        return target;
    };
}

/**
 * @class
 * Support class used to provide Analysis Chart context.
 */
class AnalysisChart {
    //=== CONSTANTS ===//

    /** Define constants to be used by the {@link AnalysisChartState}. */
    static defineConstants() {
        /** Readonly record containing status ids. */
        const Status = Constants.Record.fromRecord(
            /** @type {const} */ ({
                PRINTING: 'PRINTING',
                LOADING: 'LOADING',
                INITIALIZED: 'INITIALIZED',
                REFRESHING: 'REFRESHING',
            })
        );

        /** Readonly map containing default query parameters. */
        const DefaultQueryParams = Constants.Map.fromRecord(
            /** @type {const} */ ({
                metric: 'T',
                dateStart: '1000000',
                dateEnd: '1893456000',
            })
        );

        /** Map containing the supported graph export formats. */
        const ExportFormat = Constants.Map.fromEntries(
            /** @type {const} */ ([
                ['png', 'image/png'],
                ['jpg', 'image/jpeg'],
                ['svg', 'image/svg+xml'],
                ['pdf', 'application/pdf'],
            ])
        );

        /** Enabled graph export formats. */
        const ExportFormatAllowList = Object.freeze(
            /** @type {const} */ (['png', 'jpg', 'svg'])
        );

        /** Enum containing graph types. */
        const AxisType = Constants.Record.fromRecord(
            /** @type {const} */ ({
                T: 'T',
                RH: 'RH',
                DP: 'DP',
                MOLD: 'MOLD',
                PI: 'PI',
                TWPI: 'TWPI',
                DC: 'DC',
                EMC: 'EMC',
            })
        );

        /** Readonly map containing information about each {@link AxisType}. */
        const AxisLabel = Constants.Record.fromRecord(
            /** @type {const} */ ({
                T: 'Temperature',
                RH: 'Relative Humidity',
                DP: 'Dew Point',
                MOLD: 'Mold',
                PI: 'Preservation Index',
                TWPI: 'Time Weighted Preservation Index',
                DC: 'Dimensional Change',
                EMC: 'Equilibrium Moisture Content',
            })
        );

        /** Readonly map of axis alignment by the plot line graph and axis. */
        const AxisAlignByPlotLineGraph = Constants.Record.fromRecord(
            /** @type {const} */ ({
                /** @type {(axis?: 'T' | 'RH' | 'DP') => 'left' | 'right'} */
                T: (axis) => 'left',
                /** @type {(axis?: 'T' | 'RH' | 'DP') => 'left' | 'right'} */
                RH: (axis) => 'left',
                /** @type {(axis?: 'T' | 'RH' | 'DP') => 'left' | 'right'} */
                TRH: (axis) => (axis === 'RH' ? 'left' : 'right'),
                /** @type {(axis?: 'T' | 'RH' | 'DP') => 'left' | 'right'} */
                DP: (axis) => 'left',
            })
        );

        /**
         * Get the axis by the limit key.
         */
        const AxisByLimitKey = Constants.Map.fromEntries(
            /** @type {const} */ ([
                ['temp', 'T'],
                ['rh', 'RH'],
                ['dp', 'DP'],
            ])
        );

        /**
         * Get the color by the limit key.
         */
        const ColorByLimitKey = Constants.Map.fromEntries(
            /** @type {const} */ ([
                ['temp', 'red'],
                ['rh', '#103355'],
                ['dp', '#009999'],
            ])
        );

        /**
         * Get the labels by the limit key.
         */
        const LabelsByLimitKey = Constants.Map.fromEntries(
            /** @type {const} */ ([
                [
                    'temp',
                    {
                        lower: `${AxisLabel.T} (Min)`,
                        upper: `${AxisLabel.T} (Max)`,
                    },
                ],
                [
                    'rh',
                    {
                        lower: `${AxisLabel.RH} (Min)`,
                        upper: `${AxisLabel.RH} (Max)`,
                    },
                ],
                [
                    'dp',
                    {
                        lower: `${AxisLabel.DP} (Min)`,
                        upper: `${AxisLabel.DP} (Max)`,
                    },
                ],
            ])
        );

        /**
         * Get the unit getter.
         * @type {Map<keyof AxisType, (temperatureScale?: 'F' | 'C') => string>}
         */
        const UnitByAxis = new Map(
            /** @type {[keyof AxisType, (temperatureScale?: 'F' | 'C') => string][]} */ ([
                ['T', (ts) => Units.get(ts)],
                ['RH', () => Units.get('%')],
                ['DP', (ts) => Units.get(ts)],
                ['MOLD', () => Units.get('#')],
                ['PI', () => Units.get('#')],
                ['TWPI', () => Units.get('#')],
                ['DC', () => Units.get('%')],
                ['EMC', () => Units.get('%')],
            ])
        );

        /**
         * Get the axis format getter.
         * @type {Map<keyof AxisType, (unit?: string) => string>}
         */
        const AxisFormatByAxis = new Map(
            /** @type {[keyof AxisType, (unit?: string) => string][]} */ ([
                ['T', (unit) => `{text} ${unit ?? ''}`.trim()],
                ['RH', (unit) => `{text} ${unit ?? ''}`.trim()],
                ['DP', (unit) => `{text} ${unit ?? ''}`.trim()],
                ['MOLD', (unit) => `{text} ${unit ?? ''}`.trim()],
                ['PI', (unit) => `{text} ${unit ?? ''}`.trim()],
                ['TWPI', (unit) => `{text} ${unit ?? ''}`.trim()],
                ['DC', (unit) => `{text} ${unit ?? ''}`.trim()],
                ['EMC', (unit) => `{text} ${unit ?? ''}`.trim()],
            ])
        );

        /**
         * Get the axis title getter.
         * @type {Map<keyof AxisType, (unit?: string) => string>}
         */
        const AxisTitleByAxis = new Map(
            /** @type {[keyof AxisType, (unit?: string) => string][]} */ ([
                ['T', (unit) => `${AxisLabel['T']} ${unit ?? ''}`.trim()],
                ['RH', (unit) => `${AxisLabel['RH']}  ${unit ?? ''}`.trim()],
                ['DP', (unit) => `${AxisLabel['DP']}  ${unit ?? ''}`.trim()],
                [
                    'MOLD',
                    (unit) => `${AxisLabel['MOLD']}  ${unit ?? ''}`.trim(),
                ],
                ['PI', (unit) => `${AxisLabel['PI']}  ${unit ?? ''}`.trim()],
                [
                    'TWPI',
                    (unit) => `${AxisLabel['TWPI']}  ${unit ?? ''}`.trim(),
                ],
                ['DC', (unit) => `${AxisLabel['DC']}  ${unit ?? ''}`.trim()],
                ['EMC', (unit) => `${AxisLabel['EMC']}  ${unit ?? ''}`.trim()],
            ])
        );

        /** Readonly map containing partial vertical axis definitions. */
        const PartialYAxisOptions = Constants.Map.fromEntries(
            Object.values(AxisType).map((type) => [type, YAxis.create(type)])
        );

        /** Tuple containing graph types. Useful for 'includes' check. */
        const GraphTypes = Object.freeze(
            /** @type {const} */ (['TRH', ...Object.values(AxisType)])
        );

        /** Enum containing graphe events. */
        const GraphEvents = Constants.Record.fromRecord({
            print: Object.freeze({
                start: 'export::start',
                success: 'export::success',
                failure: 'export::failure',
            }),
        });

        /** @type {8} */
        const MaxLocationCapacity = 8;

        /** @type {Infinity} */
        const MaxWeatherStationCapacity = Infinity;

        /** Tuple containing temperature scale values. */
        const TemperatureScale = Constants.Tuple.fromKeys({
            F: 'Fahrenheit',
            C: 'Celsius',
        });

        /** Readonly map containing numeric value units. */
        const Units = Constants.Map.fromEntries(
            /** @type {const} */ ([
                ['F', `${Emoji.degree}F`],
                ['C', `${Emoji.degree}C`],
                ['%', `%`],
                ['#', ``],
            ])
        );

        // RETURN the constants.
        return Object.freeze({
            Status,
            DefaultQueryParams,
            ExportFormat,
            ExportFormatAllowList,
            AxisType,
            AxisLabel,
            AxisAlignByPlotLineGraph,
            AxisByLimitKey,
            ColorByLimitKey,
            LabelsByLimitKey,
            UnitByAxis,
            AxisFormatByAxis,
            AxisTitleByAxis,
            PartialYAxisOptions,
            GraphTypes,
            GraphEvents,
            MaxLocationCapacity,
            MaxWeatherStationCapacity,
            TemperatureScale,
            Units,
        });
    }

    //=== STATE ===//

    /** Define reactive reference for the chart instance. */
    static defineHighchartsRefs() {
        /** @type {V.Ref<typeof Highcharts>} Highcharts API. */
        const HighchartsModule = ref(null);
        /** @type {V.Ref<Highcharts.StockChart>} Chart instance. */
        const chart = ref(null);
        /** @type {V.Ref<string>} Chart key. */
        const key = ref('empty');
        // RETURN references.
        return {
            HighchartsModule,
            chart,
            key,
        };
    }

    /** Define reactive references for the status. */
    static defineStatusRefs() {
        return {
            /** @type {V.Ref<AnalysisChartConstants['GraphTypes'][number]>} */
            graph: ref('T'),
            /** @type {V.Ref<Set<(keyof AnalysisChartConstants['Status'])>>} */
            status: ref(new Set()),
            /** @type {V.Ref<boolean>} */
            zoomed: ref(false),
        };
    }

    /** Define reactive references for the filters. */
    static defineFilterRefs() {
        // INIT the date range filter reference.
        const end = endOfDay(Date.now());
        const start = startOfDay(sub(end, { years: 1 }));
        const dates = ref(DateRangeFilter.create({ start, end }));
        // INIT the location filter.
        const locations = ref(LocationFilter.create());
        // INIT the weather station filter.
        const weatherStations = ref(WeatherStationFilter.create());
        // RETURN the initialized filter references.
        return {
            dates,
            locations,
            weatherStations,
        };
    }

    /** Define reactive references for the filter records. */
    static defineFilterRecordRefs() {
        // INIT the limits filter record.
        const limits = ref(LimitFilterRecord.create());
        // INIT the limits filter record.
        const scales = ref(ScaleFilterRecord.create());
        // RETURN the initialized filter record references.
        return {
            limits,
            scales,
        };
    }

    /** Define reactive reference for the chart options. */
    static defineChartOptionsRefs() {
        /** @type {V.Ref<Highcharts.Options>} Used to contain all resolved property values. */
        const resolvedOptions = ref(
            /** @type {object} */ (ChartOptions.create())
        );
        /** @type {V.Ref<Highcharts.Options>} */
        const chartOptions = ref(/** @type {object} */ (ChartOptions.create()));
        /** @type {V.Ref<Highcharts.PlotOptions['series']['enableMouseTracking']>} */
        const enableMouseTracking = ref(false); // chartOptions.plotOptions.series.enableMouseTracking.
        /** @type {V.Ref<AnalysisChartConstants['ExportFormatAllowList'][number]>} */
        const exportFormat = ref('png'); // key used to get the chartOptions.exporting.type
        /** @type {V.Ref<Partial<Map<AnalysisChartConstants['GraphTypes'][number],(Highcharts.YAxisOptions)[]>>>} Chart yAxis options. */
        const yAxis = ref(new Map()); // chartOptions.yAxis[number]
        /** @type {V.Ref<Partial<Map<keyof AnalysisChartConstants['AxisType'],(Highcharts.YAxisPlotLinesOptions)[]>>>} Chart plotlines. */
        const plotLines = ref(new Map()); // chartOptions.yAxis[number].plotLines
        /** @type {V.Ref<Partial<Map<keyof AnalysisChartConstants['AxisType'],(Highcharts.SeriesLineOptions | Highcharts.SeriesColumnOptions)[]>>>} Chart series. */
        const series = ref(new Map()); // chartOptions.series[number]
        /** @type {V.Ref<Highcharts.ChartOptions['events']['load']>} */
        const onAfterChartLoaded = ref(() => void 0); // chartOptions.chart.events.load
        /** @type {V.Ref<Highcharts.XAxisOptions['events']['afterSetExtremes']>} */
        const onAfterSetXAxisExtremes = ref((e) => void 0); // chartOptions.xAxis.events.afterSetExtremes
        /** @type {V.Ref<Highcharts.ExportingErrorCallbackFunction>} */
        const onExportError = ref(
            /** @type {Highcharts.ExportingErrorCallbackFunction} */
            (options, err) => void 0
        ); // chartOptions.exporting.error
        // RETURN references.
        return {
            resolvedOptions,
            chartOptions,
            enableMouseTracking,
            exportFormat,
            yAxis,
            plotLines,
            series,
            onAfterChartLoaded,
            onAfterSetXAxisExtremes,
            onExportError,
        };
    }

    /** Define reactive references for the chart series data. */
    static defineDataRefs() {
        /** @type {V.Ref<Map<keyof AnalysisChartConstants['AxisType'], Record<string, ([ x: number, y: number ][])>>>} Simple object containing fetched data payloads. */
        const locationsData = ref(new Map());
        /** @type {V.Ref<Map<keyof AnalysisChartConstants['AxisType'], Record<string, ([ x: number, y: number ][])>>>} Simple object containing fetched data payloads. */
        const weatherStationsData = ref(new Map());
        // RETURN data references.
        return {
            locationsData,
            weatherStationsData,
        };
    }

    //=== COMPUTED PROPERTIES ===//

    /**
     * Define computed properties for the specified area.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state'>} context
     */
    static defineRouterProperties(context) {
        // DESTRUCTURE service and router.
        const { service } = context;
        const { router } = service;

        // DESTRUCTURE current route property.
        const { currentRoute } = router;

        // DEFINE route locations.
        const routeQueryLocations = computed(() => {
            const route = currentRoute.value;
            const query = route.query;
            // RETURN string array with ids, if present.
            if ('location' in query) {
                if (Array.isArray(query['location'])) {
                    return query['location'];
                } else {
                    return [query['location']];
                }
            }
            // RETURN empty array, if no route query resources.
            return [];
        });

        // DEFINE route weather stations.
        const routeQueryWeatherStations = computed(() => {
            const route = currentRoute.value;
            const query = route.query;
            // RETURN string array with ids, if present.
            if ('station' in query) {
                if (Array.isArray(query['station'])) {
                    return query['station'];
                } else {
                    return [query['station']];
                }
            }
            // RETURN empty array, if no route query resources.
            return [];
        });

        // RETURN sealed properties.
        return Object.seal({
            currentRoute,
            routeQueryLocations,
            routeQueryWeatherStations,
        });
    }

    /**
     * Define computed properties for the specified area.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state'>} context
     */
    static defineStoreProperties(context) {
        // DESTRUCTURE context
        const { service } = context;
        const { store } = service;

        // DEFINE computed properties.
        const currentTemperatureScale = computed(() => {
            const account = store.state.accounts.account;
            return isNil(account) ? 'F' : account.tempScale;
        });

        // RETURN sealed properties.
        return Object.seal({
            currentTemperatureScale,
        });
    }

    /**
     * Define computed properties for the specified area.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state'>} context
     */
    static defineResourceProperties(context) {
        // DESTRUCTURE context
        const { constants, service, state } = context;
        const { MaxLocationCapacity, MaxWeatherStationCapacity } = constants;
        const { locationIndex, weatherStationIndex } = service;
        const {
            locations: locationsFilter,
            weatherStations: weatherStationsFilter,
        } = state;

        // DESTRUCTURE resource computed properties.
        const { locations } = locationIndex;
        const { stations: weatherStations } = weatherStationIndex;

        // DEFINE fetching resource properties.
        const isFetchingLocations = computed(
            () => locationIndex.isFetching.value === true
        );
        const isFetchingWeatherStations = computed(
            () => weatherStationIndex.isFetching.value === true
        );
        const isFetchingResource = computed(
            () => isFetchingLocations.value || isFetchingWeatherStations.value
        );

        // DEFINE selected resource properties.
        const selectedLocations = computed(() => {
            const index = locations.value;
            const filter = locationsFilter.value;

            const checkedLocationIDs = NodeRecord.where(
                filter?.tree?.nodes,
                (n) => Node.isLocationNode(n) && NodeState.isChecked(n.state)
            )
                .map((n) => n.id)
                .map(NodeSelector.readResourceID)
                .map(Number)
                .filter((id) => !Number.isNaN(id));

            const checkedLocations = checkedLocationIDs.map((id) =>
                index.find((l) => l.id === id)
            );

            const filteredLocations = checkedLocations.slice(
                0,
                MaxLocationCapacity + 1
            );
            const sortedLocations = filteredLocations.sort((a, b) =>
                a.name.localeCompare(b.name)
            );

            return sortedLocations;
        });

        const selectedWeatherStations = computed(() => {
            const index = weatherStations.value;
            const filter = weatherStationsFilter.value;

            const checkedWeatherStationIDs = NodeRecord.where(
                filter?.tree?.nodes,
                (n) =>
                    Node.isWeatherStationNode(n) && NodeState.isChecked(n.state)
            )
                .map((n) => n.id)
                .map(NodeSelector.readResourceID)
                .map(Number)
                .filter((id) => !Number.isNaN(id));

            const checkedWeatherStations = checkedWeatherStationIDs.map((id) =>
                index.find((l) => l.id === String(id))
            );

            const filteredWeatherStations = checkedWeatherStations.slice(
                0,
                MaxWeatherStationCapacity + 1
            );

            const sortedWeatherStations = filteredWeatherStations.sort((a, b) =>
                a.name.localeCompare(b.name)
            );

            return sortedWeatherStations;
        });

        // DEFINE resource condition properties.

        const isEmptyLocationSelection = computed(() => {
            return (
                isNil(selectedLocations.value) ||
                selectedLocations.value.length === 0
            );
        });

        const isEmptyWeatherStationSelection = computed(() => {
            return (
                isNil(selectedWeatherStations.value) ||
                selectedWeatherStations.value.length === 0
            );
        });

        const isEmptyResourceSelection = computed(() => {
            return (
                isEmptyLocationSelection.value &&
                isEmptyWeatherStationSelection.value
            );
        });

        // RETURN sealed properties.
        return Object.seal({
            locations,
            weatherStations,
            isFetchingLocations,
            isFetchingWeatherStations,
            isFetchingResource,
            selectedLocations,
            selectedWeatherStations,
            isEmptyLocationSelection,
            isEmptyWeatherStationSelection,
            isEmptyResourceSelection,
        });
    }

    /**
     * Define computed properties for the specified area.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state'>} context
     */
    static defineStatusProperties(context) {
        // DESTRUCTURE context
        const { constants, state } = context;
        const { Status } = constants;
        const { HighchartsModule, status } = state;

        // DEFINE status properties.
        const isInitialized = computed(
            () =>
                status.value.has(Status.INITIALIZED) &&
                !isNil(HighchartsModule.value)
        );
        const isPrinting = computed(() => status.value.has(Status.PRINTING));
        const isLoading = computed(() => status.value.has(Status.LOADING));
        const isRefreshing = computed(() =>
            status.value.has(Status.REFRESHING)
        );

        const isPrintingDisabled = computed(() => {
            return (
                !isInitialized.value ||
                isPrinting.value ||
                isLoading.value ||
                isNil(state.chart.value)
            );
        });

        // RETURN sealed properties.
        return Object.seal({
            isInitialized,
            isPrinting,
            isPrintingDisabled,
            isLoading,
            isRefreshing,
        });
    }

    /**
     * Define computed properties for the specified area.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state'>} context
     */
    static defineConditionProperties(context) {
        // DESTRUCTURE context
        const { constants, service, state } = context;
        const { Status } = constants;
        const { locationIndex, weatherStationIndex } = service;
        const { status, chart, graph, series, resolvedOptions } = state;

        // DEFINE condition properties.

        /** Is the chart ready to accept data? */
        const isChartReady = computed(
            () => status.value.has(Status.INITIALIZED) && !isNil(chart.value)
        );

        /** Is chart loading data or printing? */
        const isChartBusy = computed(() => {
            const isFetchingResource =
                locationIndex.isFetching.value ||
                weatherStationIndex.isFetching.value;
            const isLoadingData = status.value.has(Status.LOADING);
            const isPrinting = status.value.has(Status.PRINTING);
            return isFetchingResource || isLoadingData || isPrinting;
        });

        /** Is chart empty? */
        const isChartEmpty = computed(() => {
            if (resolvedOptions.value.series.length > 0) {
                const _series = /** @type {Highcharts.SeriesLineOptions[]} */ (
                    resolvedOptions.value.series ?? []
                );
                const _data = _series.flatMap((s) => s?.data ?? []);
                return _data.length === 0;
            }
            return true;
        });

        // RETURN sealed properties.
        return Object.seal({
            isChartReady,
            isChartBusy,
            isChartEmpty,
        });
    }

    /**
     * Define computed properties for the specified area.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state'>} context
     */
    static defineGraphProperties(context) {
        // DESTRUCTURE context
        const { state } = context;
        const { dates, zoomed } = state;

        // DEFINE isZoomed property for the reset zoom button visibility.
        const isResetZoomButtonVisible = computed(() => {
            return zoomed.value === true;
        });

        // RETURN sealed properties.
        return Object.seal({
            isResetZoomButtonVisible,
        });
    }

    /**
     * Define computed properties for the specified area.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state'>} context
     */
    static defineFilterProperties(context) {
        // DESTRUCTURE context
        const { state } = context;
        const { dates } = state;

        // DEFINE date range filter properties.

        const currentDateRange = computed(() => {
            const filter = dates.value;
            return DateRange.create(filter);
        });

        const isAllDatesModifierChecked = computed(() => {
            const filter = dates.value;
            return filter.checked.includes('overlap');
        });

        const isOverlapDatesModifierChecked = computed(() => {
            const filter = dates.value;
            return filter.checked.includes('overlap');
        });

        // RETURN sealed properties.
        return Object.seal({
            currentDateRange,
            isAllDatesModifierChecked,
            isOverlapDatesModifierChecked,
        });
    }

    //=== METHODS ===//

    /**
     * Define interface methods for the specified area.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state' | 'properties'>} context
     */
    static defineHighchartsAPI(context) {
        // DESTRUCTURE context
        const { state } = context;

        /**
         * Extends the Highcharts namespace by initializing the imported modules.
         * - {@link https://github.com/highcharts/highcharts-vue#importing-highcharts-modules Docs: Importing Highcharts Modules}
         * - {@link https://www.highcharts.com/docs/export-module/export-module-overview Docs: Exporting Module}
         * - {@link https://www.highcharts.com/docs/export-module/client-side-export Docs: Client-side Exports}
         */
        const initializeModules = () => {
            // Extend Highcharts namespace with HighStock.
            // See: https://github.com/superman66/vue-highcharts/issues/43
            initializeStockModule(Highcharts);
            initializeExportData(Highcharts);
            initializeExportingModule(Highcharts);
            initializeOfflineExportingModule(Highcharts);

            // NOTE: Unsure if needed, but ensures we have a reference.
            state.HighchartsModule.value = Highcharts;
        };

        // RETURN defined interface.
        return Object.seal({
            initializeModules,
        });
    }

    /**
     * Define interface methods for the specified area.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state' | 'properties'>} context
     */
    static defineChartOptionsAPI(context) {
        // DESTRUCTURE context
        const { constants, state } = context;
        const { ExportFormat } = constants;
        const {
            chartOptions,
            enableMouseTracking,
            exportFormat,
            onAfterChartLoaded,
            onAfterSetXAxisExtremes,
            onExportError,
        } = state;

        /**
         * Mutate the store chart options.
         * @param {Readonly<Highcharts.Options>} options
         */
        const setChartOptions = (options) => {
            chartOptions.value = options; // Respond to changes with a watchEffect?
        };

        /**
         * Explicitly set all chart options (with undefined properties using the defaults).
         * @param {Readonly<Partial<Highcharts.Options>>} [props]
         */
        const resetChartOptions = (props = {}) => {
            // Resets the chart options back to the default, with overriden props.
            const chartOptions = ChartOptions.create(props);
            // TODO: Special logic before resetting the chart options.
            // Set the chart options.
            setChartOptions(chartOptions);
            // TODO: Special logic after resetting the chart options.
        };

        /**
         * Override the existing chart options and store the data.
         * @param {Readonly<Partial<Highcharts.Options>>} [props]
         */
        const updateChartOptions = (props = {}) => {
            // Override the chart options and update them.
            const options = Object.assign({}, chartOptions.value, props);
            // const options = ChartOptions.override(chartOptions.value, props);
            // Set the chart options.
            setChartOptions(options);
            // TODO: Special logic when updating the chart options.
        };

        // RETURN defined interface.
        return Object.seal({
            setChartOptions,
            resetChartOptions,
            updateChartOptions,
        });
    }

    /**
     * Define interface methods for the specified area.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state' | 'properties'>} context
     */
    static defineGraphAPI(context) {
        // DESTRUCTURE context
        const { constants, state } = context;
        const { Units, AxisType } = constants;
        const { graph } = state;

        /**
         * Get the graph units.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {string} [temperatureScale]
         */
        const getGraphUnit = (type, temperatureScale = 'F') => {
            const formatScaleUnit = () => {
                const isValidScale =
                    temperatureScale === 'F' || temperatureScale === 'C';
                return isValidScale ? Units.get(temperatureScale) : '';
            };

            // GET the scale unit.
            const scaleUnit = formatScaleUnit();
            const percentUnit = Units.get('%');
            const numericUnit = Units.get('#');

            // FORMAT the graph unit.
            switch (type) {
                case 'MOLD':
                case 'PI':
                case 'TWPI':
                    return `${numericUnit}`;
                case 'RH':
                case 'DC':
                case 'EMC':
                    return `${percentUnit}`;
                case 'T':
                case 'DP':
                    return `${scaleUnit}`;
                case 'TRH':
                    return `${scaleUnit}/${percentUnit}`;
                default:
                    return '';
            }
        };

        /**
         * Get the graph axis keys.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @returns { [ primaryAxis: keyof AxisType ] | [ primaryAxis: keyof AxisType, secondaryAxis: keyof AxisType ]}
         */
        const getGraphYAxisTypes = (type) => {
            if (type === 'TRH') {
                return ['T', 'RH'];
            }
            if (type === 'TWPI') {
                return ['PI', 'TWPI'];
            } else {
                return [AxisType[type]];
            }
        };

        /**
         * Mutate the graph type.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         */
        const setGraphType = (type = 'T') => {
            graph.value = type; // Respond to changes with a watchEffect?
        };

        /**
         * Initializes the graph type and updates the title appropriately.
         * @param {AnalysisChartConstants['GraphTypes'][number]} [type] Initialize the graph type.
         */
        const resetGraphType = (type = 'T') => {
            // TODO: Special logic before resetting the graph type.
            // Resets the graph type to the specified type.
            setGraphType(type);
            // TODO: Special logic after resetting the graph type.
        };

        // RETURN defined interface.
        return Object.seal({
            getGraphYAxisTypes,
            getGraphUnit,
            setGraphType,
            resetGraphType,
        });
    }

    /**
     * Define interface methods for the specified area.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state' | 'properties'>} context
     */
    static defineStatusAPI(context) {
        // DESTRUCTURE context
        const { state } = context;
        const { status } = state;

        /**
         * Add status.
         * @param {keyof AnalysisChartConstants['Status']} flag
         */
        const enable = (flag) => {
            const update = status.value.add(flag);
            status.value = update;
        };

        /**
         * Disable status.
         * @param {keyof AnalysisChartConstants['Status']} flag
         */
        const disable = (flag) => {
            const update = status.value;
            update.delete(flag);
            status.value = update;
        };

        /**
         * Reset status.
         */
        const resetStatus = () => {
            status.value = new Set();
        };

        // RETURN defined interface.
        return Object.seal({
            enable,
            disable,
            resetStatus,
        });
    }

    /**
     * Define interface methods for the specified area.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state' | 'properties'>} context
     * @param {ReturnType<AnalysisChart.defineStatusAPI>} methods
     * @param {V.SetupContext<AnalysisChartConstants['GraphEvents']['print'][keyof AnalysisChartConstants['GraphEvents']['print']][]>['emit']} emit
     */
    static defineExportAPI(context, methods, emit) {
        // DESTRUCTURE context
        const { constants, state } = context;
        const { Status, GraphEvents, ExportFormat } = constants;
        const { chart, series, exportFormat, resolvedOptions } = state;
        const { enable, disable } = methods;

        /**
         * Mutate the graph type.
         * @param {AnalysisChartConstants['ExportFormatAllowList'][number]} type
         */
        const setExportType = (type = 'png') => {
            exportFormat.value = type;
        };

        /**
         * Asynchronous callback for handling printing of the graph.
         * @param {AnalysisChartConstants['ExportFormatAllowList'][number]} format
         */
        const onExport = async (format = 'png') => {
            const { chart } = state;
            enable(Status.PRINTING);

            // See: https://www.highcharts.com/docs/export-module/export-module-overview
            // See: https://www.highcharts.com/docs/export-module/client-side-export

            // Settings used to interpret the below.
            const tags = []; // additional filename tags.

            // ts-ignore
            // chart.value.series = clone(resolvedOptions.value.series);

            // Get the current chart settings and apply any changes.
            const exporting = clone(resolvedOptions.value.exporting);
            exporting.filename = formatGraphFilename(tags);
            exporting.type = ExportFormat.get(format);
            exporting.buttons = { contextButton: { enabled: false } };
            exporting.sourceHeight = 600;
            exporting.sourceWidth = 1000;
            exporting.fallbackToExportServer = false;
            exporting.scale = 2;
            exporting.showTable = false;
            exporting.tableCaption = false;
            // height: 600,
            // width: 1000,

            // Prepare the event.
            const event = {
                id: GraphEvents.print.start,
                name: `[print::graph] @ ${new Date().toLocaleTimeString()}`,
                status: 'pending',
                options: exporting,
            };

            // Attempt printing.
            console.groupCollapsed(event.name);
            try {
                if (isNil(chart.value)) {
                    throw new Error('No chart is ready to export.');
                }
                if (
                    isNil(chart.value.series) ||
                    chart.value.series?.length === 0
                ) {
                    throw new Error('No chart data to export.');
                }

                console.log('Starting export...');
                emit(event.id, event);

                // SHOW LOADING OVERLAY
                chart.value.showLoading('Exporting data...');

                // NOTE: Highcharts will CRASH if you export without disabling mouse tracking,
                // if the user happens to touch the graph, because the exporting context
                // takes over the chart instance.

                // DISABLE POINTER EVENTS.
                chart.value.options.plotOptions.series.enableMouseTracking = false;
                chart.value?.options?.series?.forEach(
                    (s) => (s.enableMouseTracking = false)
                );

                // We disable pointer events and wait a few seconds before beginning the export.
                const INTERACTION_DELAY = 500; // in milliseconds.
                await new Promise((resolve) =>
                    setTimeout(resolve, INTERACTION_DELAY)
                );

                // BEGIN EXPORT?
                chart.value.exportChartLocal(event.options);

                // HIDE OVERLAY
                chart.value.hideLoading();
                chart.value.options.plotOptions.series.enableMouseTracking = true;
                chart.value?.options?.series?.forEach(
                    (s) => (s.enableMouseTracking = true)
                );

                onSuccess(event.options);
            } catch (err) {
                onFailure(event.options, err);
            } finally {
                // Close the group.
                console.groupEnd();
            }
        };

        /**
         * Export success callback.
         * @param {Highcharts.ExportingOptions} options
         */
        const onSuccess = (options) => {
            console.log('Finished export', { event: { options } });
            emit(GraphEvents.print.success, options);
            disable(Status.PRINTING);
        };

        /**
         * Export failure callback.
         * @param {Highcharts.ExportingOptions} options
         * @param {Error | string} err
         */
        const onFailure = (options, err) => {
            console.log('Failed export', { event: { options, err } });
            emit(GraphEvents.print.failure, {
                options,
                error: err,
            });
            disable(Status.PRINTING);
        };

        /**
         * Export failure callback.
         * @type {Highcharts.ExportingErrorCallbackFunction}
         */
        const onError = (options, err) => {
            const event = {
                id: GraphEvents.print.failure,
                error: err,
                options,
            };
            console.error(err);
            emit(event.id, event);
            disable(Status.PRINTING);
        };

        // RETURN defined interface.
        return Object.seal({
            print: {
                onExport,
                onError,
            },
        });
    }

    /**
     * Define interface methods for the specified area.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state' | 'properties'>} context
     * @param {ReturnType<AnalysisChart.defineChartOptionsAPI>} methods
     */
    static defineYAxisAPI(context, methods) {
        const { state } = context;
        const { chartOptions, yAxis } = state;

        //=== TYPE GUARDS ===//

        /**
         * Check if there are exactly one option.
         * @param {Highcharts.YAxisOptions | Highcharts.YAxisOptions[]} axes
         * @returns {axes is [ primaryAxis: Highcharts.YAxisOptions ]}
         */
        const isSingleAxisTuple = (axes) => {
            return !isNil(axes) && 'length' in axes && axes?.length === 1;
        };

        /**
         * Check if there are exactly two options.
         * @param {Highcharts.YAxisOptions | Highcharts.YAxisOptions[]} axes
         * @returns {axes is [ primaryAxis: Highcharts.YAxisOptions, secondaryAxis: Highcharts.YAxisOptions ]}
         */
        const isDoubleAxisTuple = (axes) => {
            return !isNil(axes) && 'length' in axes && axes.length === 2;
        };

        //=== CONDITIONS ===//

        /**
         * Check if axes exist for the specified type.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         */
        const hasYAxes = (type) => {
            const axes = getYAxes(type);
            const definedAxes = axes.filter((ax) => !isNil(ax?.id));
            return definedAxes.length > 0;
        };

        /**
         * Check if axis exists at the specified index.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {number} index
         */
        const hasYAxisAt = (type, index) => {
            if (!hasYAxes(type) || index === -1) {
                return false;
            }
            const axes = getYAxes(type);
            const axis = axes[index];
            return !isNil(axis);
        };

        /**
         * Check if axis exists at the primary/secondary rank.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {YAxis.PRIMARY_AXIS | YAxis.SECONDARY_AXIS} rank
         */
        const hasYAxisOfRank = (type, rank) => hasYAxisAt(type, rank);

        /**
         * Check if axis exists at the primary rank.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         */
        const hasPrimaryYAxis = (type) =>
            hasYAxisOfRank(type, YAxis.PRIMARY_AXIS);

        /**
         * Check if axis exists at the secondary rank.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         */
        const hasSecondaryYAxis = (type) =>
            hasYAxisOfRank(type, YAxis.SECONDARY_AXIS);

        /**
         * Check if axis exists with the specified key (converted to id).
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {keyof AnalysisChartConstants['AxisType']} key
         */
        const hasYAxis = (type, key) => {
            const id = YAxis.formatID(key);
            const axes = getYAxes(type);
            const index = axes.findIndex((ax) => ax?.id === id);
            return hasYAxisAt(type, index);
        };

        //=== GETTERS ===//

        /**
         * Get tuple containing axes for the specified graph type.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         */
        const getYAxes = (type) => {
            const axes = yAxis.value.get(type);
            return axes ?? [];
        };

        //=== FINDERS ===//

        /**
         * Find axis by graph type and index.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {number} index
         */
        const findYAxisByIndex = (type, index) => {
            // SELECT
            const axes = getYAxes(type);
            const axis = axes[index];
            return axis;
        };

        /**
         * Find axis by graph type and primary/secondary rank.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {YAxis.PRIMARY_AXIS | YAxis.SECONDARY_AXIS} rank
         */
        const findYAxisByRank = (type, rank) => findYAxisByIndex(type, rank);

        /**
         * Find primary axis for the specified graph type.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         */
        const findPrimaryYAxis = (type) =>
            findYAxisByRank(type, YAxis.PRIMARY_AXIS);

        /**
         * Find secondary axis for the specified graph type.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         */
        const findSecondaryYAxis = (type) =>
            findYAxisByRank(type, YAxis.SECONDARY_AXIS);

        /**
         * Find axis by graph type and key.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {keyof AnalysisChartConstants['AxisType']} key
         */
        const findYAxisByKey = (type, key) => {
            // QUERY
            const id = YAxis.formatID(key);
            const axes = getYAxes(type);
            const axis = axes.find((ax) => ax?.id === id);
            return axis;
        };

        //=== DELETERS ===//

        /**
         * Clear all axes from a graph type.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         */
        const clearYAxes = (type) => {
            setYAxes(type, []);
        };

        /**
         * Drop axis by graph type and index.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {number} index
         */
        const dropYAxisByIndex = (type, index) => {
            if (!hasYAxes(type) || index === -1) {
                return;
            }
            // UPDATE
            const axes = getYAxes(type);
            axes[index] = undefined;
            setYAxes(type, axes);
        };

        /**
         * Drop axis by graph type and primary/secondary rank.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {YAxis.PRIMARY_AXIS | YAxis.SECONDARY_AXIS} rank
         */
        const dropYAxisByRank = (type, rank) => dropYAxisByIndex(type, rank);

        /**
         * Drop primary axis for the specified graph type.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         */
        const dropPrimaryYAxis = (type) =>
            dropYAxisByRank(type, YAxis.PRIMARY_AXIS);

        /**
         * Drop primary axis for the specified graph type.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         */
        const dropSecondaryYAxis = (type) =>
            dropYAxisByRank(type, YAxis.SECONDARY_AXIS);

        /**
         * Drop axis by graph type and key.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {keyof AnalysisChartConstants['AxisType']} key
         */
        const dropYAxis = (type, key) => {
            // QUERY
            const id = YAxis.formatID(key);
            const axes = getYAxes(type);
            const index = axes.findIndex((ax) => ax?.id === id);
            // DROP by index.
            dropYAxisByIndex(type, index);
        };

        //=== SETTERS ===//

        /**
         * Set axes for the specified graph type.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {Highcharts.YAxisOptions[]} axes
         */
        const setYAxes = (type, axes) => {
            const update = yAxis.value.set(type, axes);
            yAxis.value = update;
        };

        /**
         * Set axis at the specified index.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {number} index
         * @param {Highcharts.YAxisOptions} axis
         */
        const setYAxisByIndex = (type, index, axis) => {
            const axes = getYAxes(type);
            if (index === -1) {
                axes.push(axis);
            } else {
                axes[index] = axis;
            }
            setYAxes(type, axes);
        };

        /**
         * Set axis at the specified rank.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {YAxis.PRIMARY_AXIS | YAxis.SECONDARY_AXIS} rank
         * @param {Highcharts.YAxisOptions} axis
         */
        const setYAxisByRank = (type, rank, axis) =>
            setYAxisByIndex(type, rank, axis);

        /**
         * Set axis at the specified rank.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {Highcharts.YAxisOptions} axis
         */
        const setPrimaryYAxis = (type, axis) =>
            setYAxisByRank(type, YAxis.PRIMARY_AXIS, axis);

        /**
         * Set axis at the specified rank.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {Highcharts.YAxisOptions} axis
         */
        const setSecondaryYAxis = (type, axis) =>
            setYAxisByRank(type, YAxis.SECONDARY_AXIS, axis);

        /**
         * Set the axis by key.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {keyof AnalysisChartConstants['AxisType']} key
         * @param {Highcharts.YAxisOptions} axis
         */
        const setYAxis = (type, key, axis) => {
            const id = YAxis.formatID(key);
            const axes = getYAxes(type);
            const index = axes.findIndex((ax) => ax?.id === id);
            axis.id = id;
            setYAxisByIndex(type, index, axis);
        };

        //=== PATCHERS ===//

        /**
         * Update the specific axes individually for the specified graph type.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {Pick<Highcharts.YAxisOptions, 'id'> & Partial<Highcharts.YAxisOptions>[]} axes
         */
        const updateYAxes = (type, axes) => {
            axes.forEach((ax) => updateYAxis(type, ax));
        };

        /**
         * Update existing axis using provided props.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {number} index
         * @param {Partial<Highcharts.YAxisOptions>} props
         */
        const updateYAxisByIndex = (type, index, props) => {
            if (index !== -1) {
                const id = props?.id ?? YAxis.DefaultAxisOptions.id;
                const axes = getYAxes(type);
                const current = findYAxisByIndex(type, index);
                const next = isNil(current)
                    ? YAxis.create(id, props)
                    : YAxis.override(current, props);
                axes[index] = next;
                setYAxes(type, axes);
            }
        };

        /**
         * Update existing axis using provided props.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {YAxis.PRIMARY_AXIS | YAxis.SECONDARY_AXIS} rank
         * @param {Partial<Highcharts.YAxisOptions>} props
         */
        const updateYAxisByRank = (type, rank, props) =>
            updateYAxisByIndex(type, rank, props);

        /**
         * Update existing axis using provided props.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {Partial<Highcharts.YAxisOptions>} props
         */
        const updatePrimaryYAxis = (type, props) =>
            updateYAxisByRank(type, YAxis.PRIMARY_AXIS, props);

        /**
         * Update existing axis using provided props.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {Partial<Highcharts.YAxisOptions>} props
         */
        const updateSecondaryYAxis = (type, props) =>
            updateYAxisByRank(type, YAxis.SECONDARY_AXIS, props);

        /**
         * Update existing axis using provided props.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         * @param {Pick<Highcharts.YAxisOptions, 'id'> & Partial<Highcharts.YAxisOptions>} props
         * @param {keyof AnalysisChartConstants['AxisType']} [key]
         */
        const updateYAxis = (type, props, key = undefined) => {
            const id = isNil(key) ? props.id : YAxis.formatID(key);
            const axes = getYAxes(type);
            const index = axes.findIndex((ax) => ax?.id === id);
            updateYAxisByIndex(type, index, props);
        };

        //=== HYDRATE ===//

        /**
         * Hydrate the chart options object with the provided data.
         * @param {AnalysisChartConstants['GraphTypes'][number]} type
         */
        const hydrateYAxes = (type) => {
            const { setChartOptions } = methods;
            // Get the axes for the specified type.
            const axes = getYAxes(type);
            // Hydrate the axes for the chartOptions.
            const update = ChartOptions.override(chartOptions.value, {
                yAxis: axes,
            });
            // Set the chart options with the updated options.
            setChartOptions(update);
        };

        // RETURN interface.
        return Object.seal({
            // TYPE GUARDS
            isSingleAxisTuple,
            isDoubleAxisTuple,
            // CONDITIONS
            hasYAxes,
            hasYAxis,
            hasYAxisAt,
            hasPrimaryYAxis,
            hasSecondaryYAxis,
            // GETTERS
            getYAxes,
            // FINDERS
            findYAxisByKey,
            findYAxisByIndex,
            findPrimaryYAxis,
            findSecondaryYAxis,
            // DELETERS
            clearYAxes,
            dropYAxis,
            dropYAxisByIndex,
            dropPrimaryYAxis,
            dropSecondaryYAxis,
            // SETTERS
            setYAxes,
            setYAxis,
            setYAxisByIndex,
            setPrimaryYAxis,
            setSecondaryYAxis,
            // PATCHERS
            updateYAxes,
            updateYAxis,
            updatePrimaryYAxis,
            updateSecondaryYAxis,
            // HYDRATE
            hydrateYAxes,
        });
    }

    /**
     * Define interface methods for the specified area.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state' | 'properties'>} context
     * @param {ReturnType<AnalysisChart.defineChartOptionsAPI>} methods
     */
    static defineYAxisPlotLinesAPI(context, methods) {
        const { state } = context;
        const { chartOptions, plotLines } = state;

        //== CONDITIONS ==//

        /**
         * Check if any plot lines configurations exist for this axis.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         */
        const hasAxisPlotLines = (axis) => {
            const axisLines = plotLines.value?.get(axis) ?? [];
            return axisLines.length > 0;
        };

        /**
         * Check if plot lines with the specified axis and id exist.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         * @param {string} key
         */
        const hasPlotLine = (axis, key) => {
            if (!hasAxisPlotLines(axis) || isNil(key)) {
                return;
            }
            const id = YAxisPlotLine.formatID(axis, key);
            const axisLines = getAxisPlotLines(axis);
            const line = axisLines.find((l) => l.id === id);
            return !isNil(line);
        };

        //=== GETTERS ===//

        /**
         * Find axis plot lines.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         */
        const getAxisPlotLines = (axis) => {
            const axisLines = plotLines.value.get(axis);
            return axisLines ?? [];
        };

        //=== FINDERS ===//

        /**
         * Find plot line by its axis and key.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         * @param {string} key
         */
        const findPlotLine = (axis, key) => {
            const id = YAxisPlotLine.formatID(axis, key);
            const axisLines = getAxisPlotLines(axis);
            const line = axisLines.find((l) => l.id === id);
            return line;
        };

        //=== DELETERS ===//

        /**
         * Clear all plot lines from an axis.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         */
        const clearAxisPlotLines = (axis) => {
            setAxisPlotLines(axis, []);
        };

        /**
         * Drop plot line by its axis and key.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         * @param {string} key
         */
        const dropPlotLine = (axis, key) => {
            const id = YAxisPlotLine.formatID(axis, key);
            const axisLines = getAxisPlotLines(axis);
            const filtered = axisLines.filter((l) => l.id !== id);
            setAxisPlotLines(axis, filtered);
        };

        //=== SETTERS ===//

        /**
         * Set plot lines for the specified axis.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         * @param {Highcharts.YAxisPlotLinesOptions[]} lines
         */
        const setAxisPlotLines = (axis, lines) => {
            const update = plotLines.value.set(axis, lines);
            plotLines.value = update;
        };

        /**
         * Add plot line to the plot lines map.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         * @param {string} key
         * @param {Highcharts.YAxisPlotLinesOptions} line
         */
        const setPlotLine = (axis, key, line) => {
            const id = YAxisPlotLine.formatID(axis, key);
            const axisLines = getAxisPlotLines(axis);
            const index = axisLines.findIndex((ax) => ax?.id === id);
            // REPLACE or INSERT
            if (index !== -1) {
                line.id = id;
                axisLines[index] = line;
            } else {
                line.id = id;
                axisLines.push(line);
            }
            // COMMIT
            setAxisPlotLines(axis, axisLines);
        };

        //=== PATCHERS ===//

        /**
         * Update the specific plot lines individually for the specified axis.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         * @param {Pick<Highcharts.YAxisPlotLinesOptions, 'id'> & Partial<Highcharts.YAxisPlotLinesOptions>[]} lines
         */
        const updateAxisPlotLines = (axis, lines) => {
            lines.forEach((line) => updatePlotLine(axis, line));
        };

        /**
         * Update specific axis at the specified index.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         * @param {Pick<Highcharts.YAxisPlotLinesOptions, 'id'> & Partial<Highcharts.YAxisPlotLinesOptions>} props
         * @param {string} [key]
         */
        const updatePlotLine = (axis, props, key = undefined) => {
            const id =
                (isNil(key) ? props.id : YAxisPlotLine.formatID(axis, key)) ??
                YAxisPlotLine.DefaultYAxisPlotLineOptions.id;
            const axisLines = getAxisPlotLines(axis);
            const index = axisLines.findIndex((ax) => ax?.id === id);
            if (index !== -1) {
                const current = axisLines[index];
                const next = isNil(current)
                    ? YAxisPlotLine.create(id, props)
                    : YAxisPlotLine.override(current, props);
                axisLines[index] = next;
                setAxisPlotLines(axis, axisLines);
            }
        };

        //=== HYDRATE ===//

        /**
         * Hydrate the axes settings with the provided data.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         */
        const hydratePlotLines = (axis) => {
            const { updateChartOptions } = methods;

            // GET the plot lines for the specified axis.
            const lines = getAxisPlotLines(axis);

            // GET the current chart options axes.
            const yAxes = chartOptions.value.yAxis;
            const axes = isNil(yAxes) || Array.isArray(yAxes) ? yAxes : [yAxes];
            if (!isNil(axes) && Array.isArray(axes)) {
                // FIND the target axis.
                const index = axes.findIndex(
                    (ax) => ax?.id === YAxis.formatID(axis)
                );
                // UPDATE the target axis.
                const current = index === -1 ? undefined : axes[index];
                const next = isNil(current)
                    ? undefined
                    : YAxis.override(current, { plotLines: lines });
                // COMMIT if updated axis is not undefined.
                if (!isNil(next)) {
                    axes[index] = next;
                    updateChartOptions({ yAxis: axes });
                    return;
                }
            }
        };

        // RETURN interface.
        return Object.seal({
            // CONDITIONS
            hasAxisPlotLines,
            hasPlotLine,
            // GETTERS
            getAxisPlotLines,
            // FINDERS
            findPlotLine,
            // DELETERS
            clearAxisPlotLines,
            dropPlotLine,
            // SETTERS
            setAxisPlotLines,
            setPlotLine,
            // PATCHERS
            updateAxisPlotLines,
            updatePlotLine,
            // HYDRATE
            hydratePlotLines,
        });
    }

    /**
     * Define interface methods for the specified area.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state' | 'properties'>} context
     */
    static defineDataAPI(context) {
        const { state } = context;
        const { locationsData, weatherStationsData } = state;

        //== CONDITIONS ==//

        /**
         * Check if axis has data.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         * @param {typeof locationsData | typeof weatherStationsData} cache
         */
        const hasAxisCache = (axis, cache) => {
            const axisCache = cache.value.get(axis) ?? {};
            return Object.keys(axisCache).length > 0;
        };

        /**
         * Check if data exists for the specified resource.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         * @param {typeof locationsData | typeof weatherStationsData} cache
         * @param {string} key
         */
        const hasResourceData = (axis, cache, key) => {
            if (!hasAxisCache(axis, cache) || isNil(key)) {
                return;
            }
            const axisCache = getAxisCache(axis, cache);
            const data = axisCache[key] ?? [];
            return !isNil(data) && data.length > 0;
        };

        //=== GETTERS ===//

        /**
         * Get axis datasets.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         * @param {typeof locationsData | typeof weatherStationsData} cache
         */
        const getAxisCache = (axis, cache) => {
            const axisCache = cache.value.get(axis);
            return axisCache ?? {};
        };

        //=== FINDERS ===//

        /**
         * Find resource data by its axis and key.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         * @param {typeof locationsData | typeof weatherStationsData} cache
         * @param {string} key
         */
        const findResourceData = (axis, cache, key) => {
            const axisCache = getAxisCache(axis, cache);
            const data = axisCache[key] ?? [];
            return data;
        };

        //=== DELETERS ===//

        /**
         * Clear all data from all caches.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         */
        const clearAxisCache = (axis) => {
            locationsData.value = new Map();
            weatherStationsData.value = new Map();
        };

        /**
         * Clear all data from an axis.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         * @param {typeof locationsData | typeof weatherStationsData} cache
         */
        const dropAxisCache = (axis, cache) => {
            setAxisCache(axis, cache, {});
        };

        //=== SETTERS ===//

        /**
         * Set plot lines for the specified axis.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         * @param {typeof locationsData | typeof weatherStationsData} cache
         * @param {Record<string, [ x: number, y: number ][]>} [record]
         */
        const setAxisCache = (axis, cache, record = {}) => {
            const update = cache.value.set(axis, record ?? {});
            cache.value = update;
        };

        /**
         * Add plot line to the plot lines map.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         * @param {typeof locationsData | typeof weatherStationsData} cache
         * @param {string} key
         * @param {[ x: number, y: number ][]} data
         */
        const setResourceData = (axis, cache, key, data) => {
            const axisCache = getAxisCache(axis, cache);
            axisCache[key] = data ?? [];
            setAxisCache(axis, cache, axisCache);
        };

        return Object.seal({
            // CONDITIONS
            hasAxisCache,
            hasResourceData,
            // GETTERS
            getAxisCache,
            // FINDERS
            findResourceData,
            // DELETERS
            clearAxisCache,
            dropAxisCache,
            // SETTERS
            setAxisCache,
            setResourceData,
        });
    }

    /**
     * Define interface methods for the specified area.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state' | 'properties'>} context
     * @param {ReturnType<AnalysisChart.defineChartOptionsAPI>} methods
     */
    static defineSeriesAPI(context, methods) {
        const { state } = context;
        const { chartOptions, series } = state;

        //== CONDITIONS ==//

        /**
         * Check if any series configurations exist for this axis.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         */
        const hasAxisSeries = (axis) => {
            const axisSeries = series.value?.get(axis) ?? [];
            return axisSeries.length > 0;
        };

        /**
         * Check if series with the specified axis and id exist.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         * @param {string} key
         */
        const hasSeries = (axis, key) => {
            if (!hasAxisSeries(axis) || isNil(key)) {
                return;
            }
            const id = Series.formatID(axis, key);
            const axisSeries = getAxisSeries(axis);
            const series = axisSeries.find((s) => s.id === id);
            return !isNil(series);
        };

        //=== GETTERS ===//

        /**
         * Find axis series.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         */
        const getAxisSeries = (axis) => {
            const axisSeries = series.value.get(axis);
            return axisSeries ?? [];
        };

        //=== FINDERS ===//

        /**
         * Find serie by its axis and key.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         * @param {string} key
         */
        const findSeries = (axis, key) => {
            const id = Series.formatID(axis, key);
            const axisSeries = getAxisSeries(axis);
            const series = axisSeries.find((s) => s.id === id);
            return series;
        };

        //=== DELETERS ===//

        /**
         * Clear all series from an axis.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         */
        const clearAxisSeries = (axis) => {
            setAxisSeries(axis, []);
        };

        /**
         * Drop series by its axis and key.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         * @param {string} key
         */
        const dropSeries = (axis, key) => {
            const id = Series.formatID(axis, key);
            const axisSeries = getAxisSeries(axis);
            const filtered = axisSeries.filter((s) => s.id !== id);
            setAxisSeries(axis, filtered);
        };

        //=== SETTERS ===//

        /**
         * Set series for the specified axis.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         * @param {(Highcharts.SeriesLineOptions | Highcharts.SeriesColumnOptions)[]} seriesList
         */
        const setAxisSeries = (axis, seriesList) => {
            const update = series.value.set(axis, seriesList);
            series.value = update;
        };

        /**
         * Add serie to the series map.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         * @param {string} key
         * @param {Highcharts.SeriesLineOptions | Highcharts.SeriesColumnOptions} series
         */
        const setSeries = (axis, key, series) => {
            const id = Series.formatID(axis, key);
            const axisSeries = getAxisSeries(axis);
            const index = axisSeries.findIndex((ax) => ax?.id === series.id);
            series.id = id;
            // REPLACE or INSERT
            if (index !== -1) {
                axisSeries[index] = series;
            } else {
                axisSeries.push(series);
            }
            // COMMIT
            setAxisSeries(axis, axisSeries);
        };

        //=== PATCHERS ===//

        /**
         * Update the specific series individually for the specified axis.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         * @param {Pick<Highcharts.SeriesLineOptions, 'id'> & Partial<Highcharts.SeriesLineOptions | Highcharts.SeriesColumnOptions>[]} seriesList
         */
        const updateAxisSeries = (axis, seriesList) => {
            seriesList.forEach((series) => updateSeries(axis, series));
        };

        /**
         * Update specific axis at the specified index.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         * @param {Pick<Highcharts.SeriesLineOptions, 'id'> & Partial<Highcharts.SeriesLineOptions | Highcharts.SeriesColumnOptions>} props
         * @param {string} [key]
         */
        const updateSeries = (axis, props, key = undefined) => {
            const id =
                (isNil(key) ? props.id : Series.formatID(axis, key)) ??
                Series.DefaultSeriesLineOptions.id;
            const axisSeries = getAxisSeries(axis);
            const index = axisSeries.findIndex((ax) => ax?.id === id);
            if (index !== -1) {
                const current = axisSeries[index];
                let next = null;
                if (isNil(current)) {
                    if (props.type === 'column') {
                        next = Series.createColumn(id, props);
                    } else {
                        next = Series.createLine(id, props);
                    }
                } else {
                    if (current.type === 'column' && props.type === 'column') {
                        next = Series.overrideColumn(current, props);
                    } else if (
                        current.type === 'line' &&
                        props.type === 'line'
                    ) {
                        next = Series.overrideLine(current, props);
                    }
                }

                axisSeries[index] = next ?? current;
                setAxisSeries(axis, axisSeries);
            }
        };

        //=== HYDRATE ===//

        /**
         * Hydrate the axes settings with the provided data.
         * @param {keyof AnalysisChartConstants['AxisType']} axis
         */
        const hydrateSeries = (axis) => {
            const { updateChartOptions } = methods;

            // GET the series for the specified axis.
            const seriesList = getAxisSeries(axis);
            const series = clone(seriesList);

            // Add the appropriate axis label.
            series.forEach((s) => (s.yAxis = YAxis.formatID(axis)));

            // GET the current chart options pieces to be updated.
            const navigator = clone(chartOptions.value.navigator);

            // REPLACE the series data in the navigator.
            const props = { series, navigator };
            props.navigator.series = series;

            // COMMIT the updates to the series and navigator.
            updateChartOptions(props);
        };

        // RETURN interface.
        return Object.seal({
            // CONDITIONS
            hasAxisSeries,
            hasSeries,
            // GETTERS
            getAxisSeries,
            // FINDERS
            findSeries,
            // DELETERS
            clearAxisSeries,
            dropSeries,
            // SETTERS
            setAxisSeries,
            setSeries,
            // PATCHERS
            updateAxisSeries,
            updateSeries,
            // HYDRATE
            hydrateSeries,
        });
    }

    /**
     * Define interface methods for the specified area.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state' | 'properties'>} context
     */
    static defineDateRangeFilterAPI(context) {
        const { state } = context;
        const { dates } = state;

        /**
         * Check if a date is within the provided interval.
         * @returns {IInterval}
         */
        const getDateRange = () => {
            const interval = DateRange.clone(dates.value);
            const now = Date.now();
            const start = Number.isFinite(interval.start.valueOf())
                ? interval.start.valueOf()
                : startOfDay(sub(now, { years: 1 }));
            const end = Number.isFinite(interval.end.valueOf())
                ? interval.end.valueOf()
                : endOfDay(now);
            return { start, end };
        };

        /**
         * Is the specified date within the date range?
         * @param {IDate} date
         * @param {IInterval} [interval] // Defaults to current date range if not supplied.
         */
        const isDateWithinFilterRange = (date, interval = getDateRange()) => {
            return isWithinInterval(date, interval);
        };

        /**
         * Get date from point.
         * @param {[ x: string | number, y: number ]} point
         * @returns {IDate}
         */
        const getDateFromPoint = (point) => {
            if (typeof point[0] === 'string') {
                const date = parseInt(point[0]);
                return date;
            } else {
                point[0];
            }
        };

        // RETURN interface.
        return Object.seal({
            getDateRange,
            getDateFromPoint,
            isDateWithinFilterRange,
        });
    }

    /**
     * Define interface methods for the specified area.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state' | 'properties'>} context
     */
    static defineLocationFilterAPI(context) {
        const { state, constants } = context;
        const { locations } = state;
        const { MaxLocationCapacity } = constants;

        const getCheckedLocationIDs = () => {
            const tree = locations.value?.tree;
            const checkedLocations = NodeRecord.where(
                tree.nodes,
                (node) =>
                    Node.isLocationNode(node) && NodeState.isChecked(node.state)
            );
            return checkedLocations
                .map((n) => n.id)
                .map(NodeSelector.readResourceID)
                .map(Number)
                .slice(0, MaxLocationCapacity);
        };

        /**
         * Is the location selected?
         * @param {Pick<LocationResource, 'id'>} resource
         */
        const isLocationSelected = (resource) => {
            if (!isNil(resource?.id)) {
                const checked = getCheckedLocationIDs();
                const isChecked = checked.includes(resource.id);
                return isChecked;
            }
            return false;
        };

        // RETURN interface.
        return Object.seal({
            // CONDITIONS
            isLocationSelected,
            // GETTERS
            getCheckedLocationIDs,
        });
    }

    /**
     * Define interface methods for the specified area.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state' | 'properties'>} context
     */
    static defineWeatherStationFilterAPI(context) {
        const { state } = context;
        const { weatherStations } = state;

        const getCheckedWeatherStationIDs = () => {
            const tree = weatherStations.value?.tree;
            const checkedWeatherStations = NodeRecord.where(
                tree.nodes,
                (node) =>
                    Node.isWeatherStationNode(node) &&
                    NodeState.isChecked(node.state)
            );
            return checkedWeatherStations
                .map((n) => n.id)
                .map(NodeSelector.readResourceID);
        };

        /**
         * Is the weather station selected?
         * @param {Pick<WeatherStationResource, 'id'>} resource
         */
        const isWeatherStationSelected = (resource) => {
            if (!isNil(resource?.id)) {
                const checked = getCheckedWeatherStationIDs();
                const isChecked = checked.includes(resource.id);
                return isChecked;
            }
            return false;
        };

        // RETURN interface.
        return Object.seal({
            // CONDITIONS
            isWeatherStationSelected,
            // GETTERS
            getCheckedWeatherStationIDs,
        });
    }

    /**
     * Define interface methods for the specified area.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state' | 'properties'>} context
     */
    static defineFilterAPI(context) {
        // DEFINE sub-apis.
        const _dates = AnalysisChart.defineDateRangeFilterAPI(context);
        const _locations = AnalysisChart.defineLocationFilterAPI(context);
        const _stations = AnalysisChart.defineWeatherStationFilterAPI(context);

        // RETURN interface.
        return Object.seal({
            ..._dates,
            ..._locations,
            ..._stations,
        });
    }

    /**
     * Define interface methods for the specified area.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state' | 'properties'>} context
     */
    static defineFilterRecordAPI(context, methods) {
        // DESTRUCTURE context
        const { service, state, properties } = context;
        const { store } = service;
        const { isChartReady } = properties;
        const { chart, limits, scales } = state;

        // DEFINE limits filter record methods.

        /**
         * Is the specified limit currently visible?
         * @template {keyof LimitFilterRecord} [Key=keyof LimitFilterRecord]
         * @param {Key | 'MOLD'} key
         * @returns {LimitFilterRecord[Key]['checked']}
         */
        const isLimitVisible = (key) => {
            if (key === 'MOLD') {
                return true;
            }
            const filter = limits.value[key];
            const isShowChecked = filter.checked === true;
            const isLowerPresent =
                !isNil(filter.lower) &&
                !isNaN(filter.lower) &&
                isFinite(filter.lower);
            const isUpperPresent =
                !isNil(filter.upper) &&
                !isNaN(filter.upper) &&
                isFinite(filter.upper);
            const isScalePresent = isLowerPresent || isUpperPresent;
            return isShowChecked && isScalePresent;
        };

        /**
         * Check if this is a graph that has a visible plotline.
         * @type {(value: unknown) => value is ('T' | 'RH' | 'TRH' | 'DP' | 'MOLD')}
         */
        const isPlotLineVisible = (value) =>
            typeof value === 'string' &&
            ['T', 'RH', 'TRH', 'DP', 'MOLD'].includes(value);

        // DEFINE scale filter record methods.

        /**
         * Is the specified scale currently visible?
         * @template {keyof ScaleFilterRecord} [Key=keyof ScaleFilterRecord]
         * @param {Key} key
         * @returns {ScaleFilterRecord[Key]['checked']}
         */
        const isUsingCustomAxisScale = (key) => {
            const filter = scales.value[key];
            const isAutoUnchecked = filter.checked !== true;
            const isLowerPresent =
                !isNil(filter.lower) &&
                !isNaN(filter.lower) &&
                isFinite(filter.lower);
            const isUpperPresent =
                !isNil(filter.upper) &&
                !isNaN(filter.upper) &&
                isFinite(filter.upper);
            const isScalePresent = isLowerPresent || isUpperPresent;
            return isAutoUnchecked && isScalePresent; // AUTO is not checked and value is given.
        };

        /**
         * Get specified filter.
         * @template {keyof LimitFilterRecord} [Key=keyof LimitFilterRecord]
         * @param {Key} key
         * @returns {LimitFilterRecord[Key]}
         */
        const getLimitsFilter = (key) => {
            const filter = LimitFilter.clone(limits.value[key]);
            return filter;
        };

        /**
         * Get specified filter.
         * @template {keyof ScaleFilterRecord} [Key=keyof ScaleFilterRecord]
         * @param {Key} key
         * @returns {ScaleFilterRecord[Key]}
         */
        const getScaleFilter = (key) => {
            const filter = ScaleFilter.clone(scales.value[key]);
            return filter;
        };

        /**
         * Invoke a reset of the limits filter records.
         */
        const resetLimitsFilterRecord = () => {
            // CREATE new filter record.
            const limits = LimitFilterRecord.create();
            // COMMIT update to the store.
            store.commit('analysis/patchLimits', limits);
        };

        /**
         * Invoke a reset of the scale filter records.
         */
        const resetScaleFilterRecord = () => {
            // CREATE new filter record.
            const scales = ScaleFilterRecord.create();
            // COMMIT update to the store.
            store.commit('analysis/patchScales', scales);
        };

        /**
         * Invoke a debounced redraw function.
         */
        const triggerRedraw = useDebounceFn(() => {
            if (chart.value && isChartReady.value) {
                chart.value?.redraw();
            }
        }, 50);

        /**
         * Reset the zoom on the Y axis.
         */
        const resetYZoom = () => {
            const { zoomed } = state;
            if (chart.value && isChartReady.value) {
                zoomed.value = false;
                chart.value?.yAxis?.forEach((axis) =>
                    axis?.setExtremes(null, null, false)
                );
                chart.value?.redraw();
            }
        };
        /**
         * Reset the zoom on the X axis.
         */
        const resetXZoom = () => {
            const { dates, zoomed } = state;
            if (chart.value && isChartReady.value) {
                zoomed.value = false;
                const min = dates?.value?.start.valueOf() ?? null;
                const max = dates?.value?.end.valueOf() ?? null;
                chart.value?.xAxis?.forEach((axis) =>
                    axis?.setExtremes(min, max, false)
                );
                chart.value?.redraw();
            }
        };
        /**
         * Reset the zoom on both axes.
         */
        const resetZoom = () => {
            resetXZoom();
            resetYZoom();
        };

        // RETURN sealed properties.
        return Object.seal({
            // CONDITIONS
            isLimitVisible,
            isPlotLineVisible,
            isUsingCustomAxisScale,
            // GETTERS
            getLimitsFilter,
            getScaleFilter,
            // ACTIONS
            resetLimitsFilterRecord, // When changing the graph metric.
            resetScaleFilterRecord, // When changing the graph metric.
            resetZoom, // Reset the zoom scale.
            resetXZoom, // Reset the x-axis zoom scale.
            resetYZoom, // Reset the y-axis zoom scale.
            triggerRedraw,
        });
    }

    //=== SUBSCRIBERS || WATCHERS ===//

    /**
     * Define watcher for the chartOptions.
     * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state' | 'properties' | 'methods'>} context
     * @returns {V.WatchStopHandle[]}
     */
    static defineChartOptionsWatchers(context) {
        // DESTRUCTURE top-level context.
        const { constants, service, state, properties, methods } = context;
        const { getCheckedLocationIDs, getCheckedWeatherStationIDs } = methods;
        const { store } = service;

        // TODO: Move helpers into prior methods namespace.

        /**
         * Are updates allowed on the specified route?
         * @param {Router.RouteLocationNormalizedLoaded} route
         */
        const isRouteAllowed = (route) => {
            return (
                isNil(route) ||
                ['Home', 'Analysis'].includes(String(route.name))
            );
        };

        /**
         * Check if the node id is of a particular type (based on its id).
         * @param {string} id
         * @param {'h' | 'l' | 's'} specifier
         */
        const isNodeType = (id, specifier) => id.startsWith(specifier);

        // /**
        //  * Select the checked location ids.
        //  * @returns {number[]}
        //  */
        // const getCheckedLocationIDs = () => {
        //     const { locations } = state;
        //     const nodes = locations.value.checked ?? [];
        //     return nodes
        //         .filter((id) => isNodeType(id, 'l'))
        //         .map((id) => id.substring(1))
        //         .map((id) => Number(id))
        //         .sort((a, b) => a - b);
        // };

        // /**
        //  * Select the checked weather station ids.
        //  * @returns {string[]}
        //  */
        // const getCheckedWeatherStationIDs = () => {
        //     const { weatherStations } = state;
        //     const nodes = weatherStations.value.checked ?? [];
        //     return nodes
        //         .filter((id) => id.startsWith('s'))
        //         .map((id) => id.substring(1))
        //         .sort((a, b) => a.localeCompare(b));
        // };

        /**
         * Get the series id by axis and resource id.
         * @type {(axis: keyof AnalysisChartConstants['AxisType'], key: string) => string}
         */
        const getSeriesID = (axis, key) => {
            const id = Series.formatID(axis, key);
            return id;
        };

        // Store queries.
        const queryDateRangeFilter = () => store.state.analysis.filters.dates;
        const queryLimitFilterRecord = () =>
            store.state.analysis.filters.limits;
        const queryScaleFilterRecord = () =>
            store.state.analysis.filters.scales;
        const queryLocationsFilter = () =>
            store.state.analysis.filters.locations;
        const queryWeatherStationsFilter = () =>
            store.state.analysis.filters.stations;

        /** Define watcher to trigger under the specified conditions. */
        const onUpdateChartOverlay = () => {
            const { isLoading, isPrinting, isInitialized } = properties;
            const { chart } = state;

            // RETURN change the date range filter.
            return watch(
                [isLoading, isPrinting, isInitialized],
                (current, previous) => {
                    try {
                        const [_isLoading, _isPrinting, _isInitialized] =
                            current;
                        if (!isNil(chart.value)) {
                            try {
                                const showLoadingOverlay =
                                    _isLoading ||
                                    _isPrinting ||
                                    !_isInitialized;
                                if (showLoadingOverlay) {
                                    const message = _isInitialized
                                        ? _isPrinting
                                            ? 'Exporting chart data...'
                                            : 'Loading chart data...'
                                        : 'Initializing...';
                                    chart.value?.showLoading(message);
                                } else {
                                    chart.value?.hideLoading();
                                    chart.value?.redraw(true);
                                }
                            } catch (e) {
                                console.warn(e.message);
                            }
                        }
                    } catch (e) {
                        console.log(e);
                    }
                },
                {
                    deep: true,
                    immediate: true,
                    flush: 'pre',
                    // onTrigger: (e) => {
                    //     console.log(`[analysisChart] update::overlay`, e);
                    // },
                }
            );
        };

        // /** Define watcher to trigger under the specified conditions. */
        // const onQueryStore = () => {
        //     const { store } = service;
        //     const { currentRoute } = properties;
        //     const { dates, limits, scales, locations, weatherStations } = state;

        //     // Get the date range filter.
        //     const getDateRangeFilter = () => store.state.analysis.filters.dates;
        //     const getLimitsFilterRecord = () =>
        //         store.state.analysis.filters.limits;
        //     const getScalesFilterRecord = () =>
        //         store.state.analysis.filters.scales;
        //     const getLocationsFilter = () =>
        //         store.state.analysis.filters.locations;
        //     const getWeatherStationsFilter = () =>
        //         store.state.analysis.filters.stations;

        //     // DEFINE dependencies.
        //     const _deps = /** @type {const} */ ([
        //         currentRoute,
        //         getDateRangeFilter,
        //         getLimitsFilterRecord,
        //         getScalesFilterRecord,
        //         getLocationsFilter,
        //         getWeatherStationsFilter,
        //     ]);

        //     // RETURN change the date range filter.
        //     return watch(
        //         _deps,
        //         (current, previous) => {
        //             const [
        //                 _route,
        //                 _dates,
        //                 _limits,
        //                 _scales,
        //                 _locations,
        //                 _stations,
        //             ] = current;

        //             // ASSERT current route is the graph page.
        //             if (isRouteAllowed(_route)) {
        //                 // IF current route is allowed,
        //                 // UPDATE all store variables on change detection.
        //                 dates.value = DateRangeFilter.clone(_dates);
        //                 limits.value = LimitFilterRecord.clone(_limits);
        //                 scales.value = ScaleFilterRecord.clone(_scales);
        //                 locations.value = LocationFilter.clone(_locations);
        //                 weatherStations.value =
        //                     WeatherStationFilter.clone(_stations);
        //             } else {
        //                 console.warn(
        //                     `Cannot update analysis chart filters from route: ${String(
        //                         _route.name
        //                     )}`
        //                 );
        //             }
        //         },
        //         {
        //             deep: true,
        //             immediate: true,
        //             flush: 'pre',
        //             // onTrigger: (e) => {
        //             //     console.log(`[analysisChart] query::store`, e);
        //             // },
        //         }
        //     );
        // };

        /** Define watcher to trigger under the specified conditions. */
        const onQueryDateRangeFilter = () => {
            const { dates } = state;
            const { currentRoute } = properties;

            // DEFINE dependencies.
            const _deps = /** @type {const} */ ([queryDateRangeFilter]);

            // RETURN change the date range filter.
            return watch(
                _deps,
                (current, previous) => {
                    try {
                        const [_dates] = current;

                        // ASSERT a change has occurred.
                        const isDirty = !compare(_dates, previous[0]);

                        // ASSERT current route is the graph page.
                        if (isDirty && isRouteAllowed(currentRoute.value)) {
                            // IF current route is allowed,
                            // UPDATE all store variables on change detection.
                            dates.value = DateRangeFilter.clone(_dates);
                        } else {
                            if (isDirty) {
                                console.warn(
                                    `Cannot update date range filter from route: ${String(
                                        currentRoute.value.name
                                    )}`
                                );
                            }
                        }
                    } catch (e) {
                        console.log(e);
                    }
                },
                {
                    deep: true,
                    immediate: true,
                    flush: 'pre',
                    // onTrigger: (e) => {
                    //     console.log(`[analysisChart] query::dates`, e);
                    // },
                }
            );
        };

        /** Define watcher to trigger under the specified conditions. */
        const onQueryLimitFilterRecord = () => {
            const { limits } = state;
            const { currentRoute } = properties;

            // DEFINE dependencies.
            const _deps = /** @type {const} */ ([queryLimitFilterRecord]);

            // RETURN change the date range filter.
            return watch(
                _deps,
                (current, previous) => {
                    try {
                        const [_limits] = current;

                        // ASSERT a change has occurred.
                        const isDirty = !compare(_limits, previous[0]);

                        // ASSERT current route is the graph page.
                        if (isDirty && isRouteAllowed(currentRoute.value)) {
                            // IF current route is allowed,
                            // UPDATE all store variables on change detection.
                            limits.value = LimitFilterRecord.clone(_limits);
                        } else {
                            if (isDirty) {
                                console.warn(
                                    `Cannot update limit filters from route: ${String(
                                        currentRoute.value.name
                                    )}`
                                );
                            }
                        }
                    } catch (e) {
                        console.log(e);
                    }
                },
                {
                    deep: true,
                    immediate: true,
                    flush: 'pre',
                    // onTrigger: (e) => {
                    //     console.log(`[analysisChart] query::limits`, e);
                    // },
                }
            );
        };

        /** Define watcher to trigger under the specified conditions. */
        const onQueryScaleFilterRecord = () => {
            const { scales } = state;
            const { currentRoute } = properties;

            // DEFINE dependencies.
            const _deps = /** @type {const} */ ([queryScaleFilterRecord]);

            // RETURN change the date range filter.
            return watch(
                _deps,
                (current, previous) => {
                    try {
                        const [_scales] = current;

                        // ASSERT a change has occurred.
                        const isDirty = !compare(_scales, previous[0]);

                        // ASSERT current route is the graph page.
                        if (isDirty && isRouteAllowed(currentRoute.value)) {
                            // IF current route is allowed,
                            // UPDATE all store variables on change detection.
                            scales.value = ScaleFilterRecord.clone(_scales);
                        } else {
                            if (isDirty) {
                                console.warn(
                                    `Cannot update scale filters from route: ${String(
                                        currentRoute.value.name
                                    )}`
                                );
                            }
                        }
                    } catch (e) {
                        console.log(e);
                    }
                },
                {
                    deep: true,
                    immediate: true,
                    flush: 'pre',
                    // onTrigger: (e) => {
                    //     console.log(`[analysisChart] query::scales`, e);
                    // },
                }
            );
        };

        /** Define watcher to trigger under the specified conditions. */
        const onQueryLocationsFilter = () => {
            const { locations } = state;
            const { currentRoute } = properties;

            // DEFINE dependencies.
            const _deps = /** @type {const} */ ([queryLocationsFilter]);

            // RETURN change the date range filter.
            return watch(
                _deps,
                (current, previous) => {
                    try {
                        const [_current] = current;
                        const [_previous] = previous;

                        const currentCheckedLocations = NodeRecord.where(
                            _current?.tree?.nodes,
                            (n) =>
                                Node.isLocationNode(n) &&
                                NodeState.isChecked(n.state)
                        );
                        const currentCheckedLocationIDs =
                            currentCheckedLocations.map((n) => n.id);
                        const currentIDs = currentCheckedLocationIDs.sort(
                            (a, b) => a.localeCompare(b)
                        );

                        const previousCheckedLocations = NodeRecord.where(
                            previous[0]?.tree?.nodes,
                            (n) =>
                                Node.isLocationNode(n) &&
                                NodeState.isChecked(n.state)
                        );
                        const previousCheckedLocationIDs =
                            previousCheckedLocations.map((n) => n.id);
                        const previousIDs =
                            previousCheckedLocationIDs.sort((a, b) =>
                                a.localeCompare(b)
                            ) ?? [];

                        const isDirty =
                            _previous === undefined ||
                            !compare(currentIDs, previousIDs);

                        // ASSERT current route is the graph page.
                        if (isDirty && isRouteAllowed(currentRoute.value)) {
                            // IF current route is allowed,
                            // UPDATE all store variables on change detection.
                            locations.value = LocationFilter.clone(_current);
                        } else {
                            if (isDirty) {
                                console.warn(
                                    `Cannot update locations filter from route: ${String(
                                        currentRoute.value.name
                                    )}`
                                );
                            }
                        }
                    } catch (e) {
                        console.log(e);
                    }
                },
                {
                    deep: true,
                    immediate: true,
                    flush: 'pre',
                    // onTrigger: (e) => {
                    //     console.log(`[analysisChart] query::locations`, e);
                    // },
                }
            );
        };

        /** Define watcher to trigger under the specified conditions. */
        const onQueryWeatherStationsFilter = () => {
            const { weatherStations } = state;
            const { currentRoute } = properties;

            // DEFINE dependencies.
            const _deps = /** @type {const} */ ([queryWeatherStationsFilter]);

            // RETURN change the date range filter.
            return watch(
                _deps,
                (current, previous) => {
                    try {
                        const [_current] = current;
                        const [_previous] = previous;

                        const currentCheckedWeatherStations = NodeRecord.where(
                            _current?.tree?.nodes,
                            (n) =>
                                Node.isWeatherStationNode(n) &&
                                NodeState.isChecked(n.state)
                        );
                        const currentCheckedWeatherStationIDs =
                            currentCheckedWeatherStations.map((n) => n.id);
                        const currentIDs = currentCheckedWeatherStationIDs.sort(
                            (a, b) => a.localeCompare(b)
                        );

                        const previousCheckedWeatherStations = NodeRecord.where(
                            _previous?.tree?.nodes,
                            (n) =>
                                Node.isWeatherStationNode(n) &&
                                NodeState.isChecked(n.state)
                        );
                        const previousCheckedWeatherStationIDs =
                            previousCheckedWeatherStations.map((n) => n.id);
                        const previousIDs =
                            previousCheckedWeatherStationIDs.sort((a, b) =>
                                a.localeCompare(b)
                            ) ?? [];

                        const isDirty =
                            _previous === undefined ||
                            !compare(currentIDs, previousIDs);

                        // ASSERT current route is the graph page.
                        if (isDirty && isRouteAllowed(currentRoute.value)) {
                            // IF current route is allowed,
                            // UPDATE all store variables on change detection.
                            weatherStations.value =
                                WeatherStationFilter.clone(_current);
                        } else {
                            if (isDirty) {
                                console.warn(
                                    `Cannot update weather station filter from route: ${String(
                                        currentRoute.value.name
                                    )}`
                                );
                            }
                        }
                    } catch (e) {
                        console.log(e);
                    }
                },
                {
                    deep: true,
                    immediate: true,
                    flush: 'pre',
                    // onTrigger: (e) => {
                    //     console.log(`[analysisChart] query::weatherStations`, e);
                    // },
                }
            );
        };

        /** Define watcher to trigger under the specified conditions. */
        const onUpdateTitle = () => {
            // DESTRUCTURE reactive references.
            const { AxisLabel } = constants;
            const { isLoading, currentTemperatureScale } = properties;
            const { resolvedOptions, graph } = state;

            // RETURN watcher.
            return watch(
                [
                    graph,
                    isLoading,
                    currentTemperatureScale,
                    getCheckedLocationIDs,
                    getCheckedWeatherStationIDs,
                ],
                (current) => {
                    try {
                        // DESTRUCTURE current values
                        const [
                            _graph,
                            _isLoading,
                            _temperatureScale,
                            _locations,
                            _weatherStations,
                        ] = current;

                        // FORMAT state to get the graph title.
                        const getGraphTitle = () => {
                            // Prepare the parameters.
                            const metric =
                                _graph === 'TRH'
                                    ? `${AxisLabel.T} and ${AxisLabel.RH}`
                                    : AxisLabel[_graph];
                            const unit = methods.getGraphUnit(
                                _graph,
                                _temperatureScale
                            );
                            const count = [..._locations, ..._weatherStations]
                                .length;

                            // RETURN formatted title.
                            return formatGraphTitle(
                                metric,
                                unit,
                                count,
                                _isLoading
                            );
                        };

                        // UPDATE the title text.
                        const update = getGraphTitle();
                        resolvedOptions.value.title.text = update;
                    } catch (e) {
                        console.log(e);
                    }
                },
                {
                    deep: true,
                    immediate: true,
                    flush: 'pre',
                    // onTrigger: (e) => {
                    //     console.log(`[analysisChart] update::title`, e);
                    // },
                }
            );
        };

        /** Define watcher to trigger under the specified conditions. */
        const onUpdateSubtitle = () => {
            const { resolvedOptions, dates } = state;
            return watch(
                dates,
                (current) => {
                    try {
                        // UPDATE the subtitle text.
                        const update = formatGraphSubtitle(current);
                        resolvedOptions.value.subtitle.text = update;
                    } catch (e) {
                        console.log(e);
                    }
                },
                {
                    deep: true,
                    immediate: true,
                    flush: 'pre',
                    // onTrigger: (e) => {
                    //     console.log(`[analysisChart] update::subtitle`, e);
                    // },
                }
            );
        };

        /** Define watcher to trigger under the specified conditions. */
        const onUpdateData = () => {
            const {
                DefaultQueryParams,
                Status,
                MaxLocationCapacity,
                UnitByAxis,
                AxisType,
            } = constants;
            const { locationIndex, weatherStationIndex } = service;
            const { graph, dates, locationsData, weatherStationsData } = state;
            const { currentTemperatureScale } = properties;
            const { getGraphYAxisTypes, enable, disable, resetXZoom } = methods;

            /**
             * Is the resource collection dirty?
             * @type {<T extends (string | number)>(current: T[], previous: T[]) => boolean}
             */
            const isResourceSelectionDirty = (current, previous) => {
                const dirty = !compare(current, previous);
                return dirty;
            };

            /**
             * Get the series name.
             * @type {(axis: keyof AxisType, resource: LocationResource | WeatherStationResource) => string}
             */
            const getSeriesName = (axis, resource) => {
                if ('dataLoggerSerialNumber' in resource) {
                    return `${LocationResource.getLabel(resource)} (${axis})`;
                } else {
                    return `${resource.name} (${axis})`;
                }
            };

            /**
             * Create a plot line with the specified settings.
             * @param {string} id
             * @param {Partial<Highcharts.SeriesLineOptions>} props
             * @returns {Highcharts.SeriesLineOptions}
             */
            const createLineSeries = (id, props) => {
                const config = Series.createLine(id, props);
                const series = Series.overrideLine(config);
                return series;
            };

            /**
             * Create a plot Column with the specified settings.
             * @param {string} id
             * @param {Partial<Highcharts.SeriesColumnOptions>} props
             * @returns {Highcharts.SeriesColumnOptions}
             */
            const createColumnSeries = (id, props) => {
                const config = Series.createColumn(id, props);
                const series = Series.overrideColumn(config);
                return series;
            };

            /**
             * Create a location series.
             * @param {keyof AxisType} axis
             * @param {LocationResource | WeatherStationResource} resource
             * @param {Array<[ x: number, y: number ]>} data
             * @param {string} unit Unit, calculated during refresh.
             * @param {IInterval} interval
             * @param {string} [linkedTo] Optional, linked id.
             * @returns {Highcharts.SeriesLineOptions | Highcharts.SeriesColumnOptions}
             */
            const createResourceSeries = (
                axis,
                resource,
                unit,
                data,
                type,
                interval,
                linkedTo = undefined,
                precision = 2,
            ) => {
                // FILTER data by the date range interval.
                const filteredData = data.filter(([x]) =>
                    methods.isDateWithinFilterRange(x, interval)
                );

                // IF resource is defined and not empty.
                if (!isNil(resource)) {
                    // DEFINE series id.
                    const id = getSeriesID(axis, `${resource.id}`);

                    // DEFINE series props.
                    /** @type {Partial<Highcharts.SeriesLineOptions | Highcharts.SeriesColumnOptions>} */
                    const props = {
                        name: getSeriesName(axis, resource),
                        type: type,
                        data: filteredData,
                        yAxis: YAxis.formatID(axis),
                        xAxis: 0,
                        turboThreshold: 10000,
                        dataGrouping: {
                            enabled: false,
                        },
                        custom: {
                            resource,
                        },
                        // USED FOR TOOLTIPS!!!
                        enableMouseTracking: true,
                        showInLegend: true,
                        tooltip: {
                            pointFormat: `<b>{series.name}: {point.y} ${unit}</b>`,
                            valueDecimals: precision,
                        },
                        // linkedTo,
                    };

                    if (props.type === 'column') {
                        //TODO: Add Column properties
                    }

                    // DEFINE series.
                    const series =
                        props.type === 'line'
                            ? createLineSeries(id, props)
                            : createColumnSeries(id, props);

                    // RETURN series.
                    return series;
                }
                // RETURN null for empty / missing series.
                return null;
            };

            /**
             * Get the axis unit (with respect to the passed in temperature scale.)
             * @param {keyof AxisType} axis
             * @param {'F' | 'C' | undefined} temperatureScale
             */
            const getAxisUnit = (axis, temperatureScale) => {
                const getUnit = UnitByAxis.get(axis);
                return !isNil(getUnit) ? getUnit(temperatureScale) : '';
            };

            /** Debounced trigger for the X-axis reset zoom operation. */
            const triggerResetXZoom = useDebounceFn(() => resetXZoom(), 2000, {
                maxWait: 5000,
            });

            // TRIGGER watch update when:
            // - The current route changes...
            // - The date range filter is changed...
            // - The checked locations are changed...
            // - The checked weather stations are changed...
            // - The selected graph changes...
            // - The account's temperature scale changes...
            const _deps = /** @type {const} */ ([
                graph,
                dates,
                currentTemperatureScale,
                getCheckedLocationIDs,
                getCheckedWeatherStationIDs,
            ]);
            return watch(
                _deps,
                async (current, previous) => {
                    try {
                        // PREPARE
                        const _graph = {
                            get current() {
                                return current[0];
                            },
                            get previous() {
                                return previous[0];
                            },
                            get axes() {
                                return getGraphYAxisTypes(this.current);
                            },
                            get isDirty() {
                                const isNotInitialized =
                                    this.previous === undefined;
                                return (
                                    isNotInitialized ||
                                    this.current !== this.previous
                                );
                            },
                        };

                        const _dates = {
                            get current() {
                                return current[1];
                            },
                            get previous() {
                                return previous[1];
                            },
                            get isDirty() {
                                const isNotInitialized =
                                    this.previous === undefined;
                                const isStartDateDirty =
                                    this.current?.start !==
                                    this.previous?.start;
                                const isEndDateDirty =
                                    this.current?.end !== this.previous?.end;
                                return (
                                    isNotInitialized ||
                                    isStartDateDirty ||
                                    isEndDateDirty
                                );
                            },
                            get interval() {
                                // GET interval.
                                const start =
                                    !isNil(this.current.start) &&
                                    Number.isFinite(this.current.start)
                                        ? getUnixTime(
                                              this.current.start.valueOf()
                                          )
                                        : null;

                                const end =
                                    !isNil(this.current.end) &&
                                    Number.isFinite(this.current.end)
                                        ? getUnixTime(
                                              this.current.end.valueOf()
                                          )
                                        : null;

                                const range = { start, end };
                                return range;
                            },
                        };

                        const _temperatureScale = {
                            get current() {
                                return current[2];
                            },
                            get previous() {
                                return previous[2];
                            },
                            get isDirty() {
                                const isNotInitialized =
                                    this.previous === undefined;
                                return (
                                    isNotInitialized ||
                                    this.current !== this.previous
                                );
                            },
                        };

                        const _locationIDs = {
                            get current() {
                                return current[3];
                            },
                            get previous() {
                                return previous[3];
                            },
                            get isDirty() {
                                const isNotInitialized =
                                    this.previous === undefined;
                                const isLengthChanged =
                                    this.current?.length !==
                                    this.previous?.length;
                                const hasDifference = isResourceSelectionDirty(
                                    this.current,
                                    this.previous
                                );
                                return (
                                    isNotInitialized ||
                                    isLengthChanged ||
                                    hasDifference
                                );
                            },
                        };

                        const _weatherStationIDs = {
                            get current() {
                                return current[4];
                            },
                            get previous() {
                                return previous[4];
                            },
                            get isDirty() {
                                const isNotInitialized =
                                    this.previous === undefined;
                                const isLengthChanged =
                                    this.current?.length !==
                                    this.previous?.length;
                                const hasDifference = isResourceSelectionDirty(
                                    this.current,
                                    this.previous
                                );
                                return (
                                    isNotInitialized ||
                                    isLengthChanged ||
                                    hasDifference
                                );
                            },
                        };

                        const _locations = {
                            get index() {
                                return locationIndex.cache.locations.value
                                    .index;
                            },
                            get isDirty() {
                                return (
                                    _graph.isDirty ||
                                    _dates.isDirty ||
                                    _temperatureScale.isDirty ||
                                    _locationIDs.isDirty
                                );
                            },
                            get requests() {
                                const { axes } = _graph;
                                const _requests = axes.flatMap((axis) =>
                                    _locationIDs.current
                                        .slice(0, MaxLocationCapacity)
                                        .map((id) => ({
                                            id,
                                            axis,
                                            key: getSeriesID(axis, String(id)),
                                        }))
                                );
                                return _requests;
                            },
                            async fetchSelectedData() {
                                const { requests } = this;
                                const promises = requests.map((request) => {
                                    // GET resource index.
                                    const { index } =
                                        service.locationIndex.cache.locations
                                            .value;
                                    // GET resource instance.
                                    const id = request.id;
                                    const resource = index.get(request.id);
                                    const name =
                                        LocationResource.getLabel(resource) ??
                                        '${location} (${axis})';
                                    // GET filtered date interval.
                                    const { start, end } = _dates.interval;
                                    const dateStart =
                                        String(start) ??
                                        DefaultQueryParams.get('dateStart');
                                    const dateEnd =
                                        String(end) ??
                                        DefaultQueryParams.get('dateEnd');
                                    // GET the metric.
                                    const metric = request.axis;
                                    // DEFINE fetch promise.
                                    const promise = fetchLocationData({
                                        id,
                                        name,
                                        dateStart,
                                        dateEnd,
                                        metric,
                                    });
                                    // RETURN mapped promise.
                                    return promise;
                                });
                                // RETURN all.
                                return await Promise.all(promises);
                            },
                            async refreshSelectedData() {
                                try {
                                    // GET the current cache instance.
                                    const cache = locationsData.value;
                                    // FETCH all the selected data.
                                    const responses =
                                        await this.fetchSelectedData();
                                    // FOR EACH response retreived, save data.
                                    responses.forEach((response) => {
                                        const axis = response.metric;
                                        const key = `${response.id}`;
                                        const current = cache.get(axis) ?? {};
                                        const next = Object.assign(current, {
                                            [key]: response.data,
                                        });
                                        cache.set(axis, next);
                                    });
                                    // UPDATE the location data.
                                    locationsData.value = cache;
                                } catch (e) {
                                    throw e;
                                }
                            },
                        };

                        const _weatherStations = {
                            get index() {
                                return weatherStationIndex.cache.stations.value
                                    .index;
                            },
                            get isDirty() {
                                return (
                                    _graph.isDirty ||
                                    _dates.isDirty ||
                                    _temperatureScale.isDirty ||
                                    _weatherStationIDs.isDirty
                                );
                            },
                            get requests() {
                                const { axes } = _graph;
                                const _requests = axes.flatMap((axis) =>
                                    _weatherStationIDs.current.map((id) => ({
                                        id,
                                        axis,
                                        key: getSeriesID(axis, String(id)),
                                    }))
                                );
                                return _requests;
                            },
                            async fetchSelectedData() {
                                const { requests } = this;
                                const promises = requests.map((request) => {
                                    // GET resource index.
                                    const { index } =
                                        service.weatherStationIndex.cache
                                            .stations.value;
                                    // GET resource instance.
                                    const id = request.id;
                                    const resource = index.get(
                                        String(request.id)
                                    );
                                    const name =
                                        resource?.name ??
                                        '${station} (${axis})';
                                    // GET filtered date interval.
                                    const { start, end } = _dates.interval;
                                    const dateStart =
                                        String(start) ??
                                        DefaultQueryParams.get('dateStart');
                                    const dateEnd =
                                        String(end) ??
                                        DefaultQueryParams.get('dateEnd');
                                    // GET the metric.
                                    const metric = request.axis;
                                    // DEFINE fetch promise.
                                    const promise = fetchWeatherStationData({
                                        id: Number(id),
                                        metric,
                                        name,
                                        dateStart,
                                        dateEnd,
                                    });
                                    // RETURN mapped promise.
                                    return promise;
                                });
                                // RETURN all.
                                return await Promise.all(promises);
                            },
                            async refreshSelectedData() {
                                try {
                                    // GET the current cache instance.
                                    const cache = weatherStationsData.value;
                                    // FETCH all the selected data.
                                    const responses =
                                        await this.fetchSelectedData();
                                    // FOR EACH response retreived, save data.
                                    responses.forEach((response) => {
                                        const axis = response.metric;
                                        const key = `${response.id}`;
                                        const current = cache.get(axis) ?? {};
                                        const next = Object.assign(current, {
                                            [key]: response.data,
                                        });
                                        cache.set(axis, next);
                                    });
                                    // UPDATE the weather stations data.
                                    weatherStationsData.value = cache;
                                } catch (e) {
                                    throw e;
                                }
                            },
                        };

                        // HANDLE
                        try {
                            // START loading.
                            // enable(Status.LOADING);
                            enable(Status.REFRESHING);
                            // IF location selection IS DIRTY...
                            if (_locations.isDirty) {
                                // REFRESH location data cache.
                                await _locations.refreshSelectedData();
                            }
                            // IF weather station selection IS DIRTY...
                            if (_weatherStations.isDirty) {
                                // REFRESH weather station data cache.
                                await _weatherStations.refreshSelectedData();
                            }
                            // IF either are dirty, reset X axis zoom.
                            if (
                                _locations.isDirty ||
                                _weatherStations.isDirty
                            ) {
                                // GET the axes for the current graph.
                                const { axes } = _graph;

                                // FOR EACH axis, clear the available series and then populate it.
                                const axisSeriesList = axes.map(
                                    (axis, axisIndex) => {
                                        // SELECT resources.
                                        const resources = [
                                            ..._locationIDs.current.map(
                                                (id) => {
                                                    return clone(
                                                        _locations.index.get(
                                                            Number(id)
                                                        )
                                                    );
                                                }
                                            ),
                                            ..._weatherStationIDs.current.map(
                                                (id) => {
                                                    const station = clone(
                                                        _weatherStations.index.get(
                                                            String(id)
                                                        )
                                                    );
                                                    station.id = String(
                                                        station.id
                                                    );
                                                    return station;
                                                }
                                            ),
                                        ];

                                        // FOR EACH resource, create non empty series.
                                        const axisSeries = resources.map(
                                            (resource) => {
                                                // GET unit.
                                                const ts =
                                                    /** @type {'F' | 'C'} */ (
                                                        currentTemperatureScale.value
                                                    );
                                                const unit = getAxisUnit(
                                                    axis,
                                                    ts
                                                );

                                                // GET data from the axis cache.
                                                const key = `${resource.id}`;

                                                // CHECK if location.
                                                const isLocation = is.number(
                                                    resource.id
                                                );

                                                const data =
                                                    methods.findResourceData(
                                                        axis,
                                                        isLocation
                                                            ? locationsData
                                                            : weatherStationsData,
                                                        key
                                                    ) ?? [];

                                                const type =
                                                    axis === 'PI' ||
                                                    axis === 'DC' ||
                                                    axis === 'MOLD'
                                                        ? 'column'
                                                        : 'line';

                                                // GET current date interval.
                                                const interval =
                                                    DateRange.clone(
                                                        dates.value
                                                    );

                                                // DEFINE linkedTo if axisIndex > 0;
                                                const linkedTo =
                                                    axisIndex === 1
                                                        ? getSeriesID(
                                                              axes[0],
                                                              String(
                                                                  resource.id
                                                              )
                                                          )
                                                        : undefined;

                                                const precision =
                                                    axis === 'RH' ? 0 : 1;

                                                // DEFINE series.
                                                return createResourceSeries(
                                                    axis,
                                                    resource,
                                                    unit,
                                                    data,
                                                    type,
                                                    interval,
                                                    linkedTo,
                                                    precision,
                                                );
                                            }
                                        );

                                        // RETURN axisSeries.
                                        return { axis, seriesList: axisSeries };
                                    }
                                );

                                // COMMIT series.
                                axisSeriesList.forEach(
                                    ({ axis, seriesList }) => {
                                        // UPDATES the `series.value` reactive property.
                                        methods.setAxisSeries(axis, seriesList);
                                    }
                                );

                                triggerResetXZoom();
                            }

                            // setXAxisExtremes(
                            //     _dates.current.start,
                            //     _dates.current.end
                            // );
                        } finally {
                            // STOP loading.
                            // disable(Status.LOADING);
                            disable(Status.REFRESHING);
                        }
                    } catch (e) {
                        console.log(e);
                    }
                },
                {
                    deep: true,
                    immediate: true,
                    flush: 'pre',
                    // onTrigger: (e) => {
                    //     console.log(`[analysisChart] refresh::data`, e);
                    // },
                }
            );
        };

        /** Define watcher to trigger under the specified conditions. */
        const onRefreshPlotLines = () => {
            const {
                AxisType,
                AxisByLimitKey,
                ColorByLimitKey,
                LabelsByLimitKey,
                AxisAlignByPlotLineGraph,
            } = constants;
            const { graph, limits } = state;
            const {
                isLimitVisible,
                isPlotLineVisible,
                getGraphYAxisTypes,
                clearAxisPlotLines,
                setPlotLine,
            } = methods;

            /**
             * Get the ids by the limit key.
             * @type {(key: ILimitFilter['key']) => { lower: string, upper: string }}
             */
            const getLimitPlotLineIDs = (key) => {
                const axis = AxisByLimitKey.get(key);
                const lower = YAxisPlotLine.formatID(axis, 'min');
                const upper = YAxisPlotLine.formatID(axis, 'max');
                return { lower, upper };
            };

            /**
             * Create a plot line with the specified settings.
             * @param {string} id
             * @param {Object} payload
             * @param {number} payload.value
             * @param {string} payload.label
             * @param {string} payload.color
             * @param {Highcharts.DashStyleValue} [payload.dashStyle]
             * @param {'left' | 'right'} [payload.align]
             * @returns {Highcharts.YAxisPlotLinesOptions}
             */
            const createPlotLine = (
                id,
                { value, label, color, align = 'left', dashStyle = 'Dash' }
            ) => {
                return YAxisPlotLine.create(id, {
                    value,
                    label: { text: label, align },
                    color,
                    dashStyle,
                });
            };

            /**
             * Create a minimum and maximum plot line.
             * @param {Extract<keyof AxisType, 'T' | 'RH' | 'DP'>} axis
             * @param {ILimitFilter} filter
             * @param {'left' | 'right'} [align]
             * @returns {[ minLine: Highcharts.YAxisPlotLinesOptions, maxLine: Highcharts.YAxisPlotLinesOptions ]}
             */
            const createLimitPlotLines = (axis, filter, align = 'left') => {
                // CREATE plot lines based on the filter type.
                const ids = getLimitPlotLineIDs(filter.key);
                const color = ColorByLimitKey.get(filter.key);
                const labels = LabelsByLimitKey.get(filter.key);
                const minLine = createPlotLine(ids.lower, {
                    value: filter.lower,
                    label: labels.lower,
                    color,
                    align,
                });
                const maxLine = createPlotLine(ids.upper, {
                    value: filter.upper,
                    label: labels.upper,
                    color,
                    align,
                });
                return [minLine, maxLine];
            };

            // TRIGGER watch update when:
            // - The current route changes...
            // - The limits filter is changed / toggled...
            // - The selected graph is changed...
            const _deps = /** @type {const} */ ([graph, limits]);
            return watch(
                _deps,
                (current) => {
                    try {
                        const [_graph, _limits] = current;

                        const axisTypes =
                            /** @type {AxisType[keyof AxisType][]} */ (
                                Object.keys(AxisType)
                            );
                        axisTypes.forEach((axis) => clearAxisPlotLines(axis));

                        // GET the axes for the current graph.
                        const axes = getGraphYAxisTypes(_graph);

                        // FOR EACH axis, clear the available plot lines.
                        axes.forEach((axis) => clearAxisPlotLines(axis));

                        // FOR EACH limit, create and register a plotline instance.
                        Object.keys(_limits).forEach(
                            /** @param {ILimitFilter['key']} key */
                            (key) => {
                                if (
                                    AxisByLimitKey.has(key) &&
                                    isLimitVisible(key) &&
                                    isPlotLineVisible(graph.value)
                                ) {
                                    // IF valid limit key, get the filter values.
                                    const axis = AxisByLimitKey.get(key);
                                    const filter = _limits[key];

                                    // COMPUTE the alignment for the current graph and axis.
                                    const getAlignmentByAxis =
                                        AxisAlignByPlotLineGraph[graph.value];
                                    const align =
                                        getAlignmentByAxis?.(axis) ?? 'left';

                                    // DEFINE create plotlines.
                                    const [minLine, maxLine] =
                                        createLimitPlotLines(
                                            axis,
                                            filter,
                                            align
                                        );

                                    // COMMIT plot lines.
                                    setPlotLine(axis, 'min', minLine);
                                    setPlotLine(axis, 'max', maxLine);
                                }
                            }
                        );

                        // FOR THE MOLD REFERENCE LINE, create and register a plotline instance.
                        if (
                            isLimitVisible('MOLD') &&
                            isPlotLineVisible(graph.value)
                        ) {
                            // IF valid limit key, get the filter values.
                            const axis = 'MOLD';
                            const line = createPlotLine(
                                YAxisPlotLine.formatID('MOLD', 'reference'),
                                {
                                    value: 1.0,
                                    align: 'left',
                                    label: 'Germination',
                                    color: 'black',
                                    dashStyle: 'Solid',
                                }
                            );

                            // COMMIT plot lines.
                            setPlotLine(axis, 'reference', line);
                        }
                    } catch (e) {
                        console.log(e);
                    }
                },
                {
                    deep: true,
                    immediate: true,
                    flush: 'pre',
                    // onTrigger: (e) => {
                    //     console.log(`[analysisChart] refresh::plotlines`, e);
                    // },
                }
            );
        };

        /** Define watcher to trigger under the specified conditions. */
        const onRefreshXAxes = () => {
            //imports
            const { chart, graph, dates } = state;
            const { isChartReady } = properties;

            //utils

            /**
             * Set the axis extremes dynamically, if the chart exists.
             * @param {Interval} [dates]
             * @returns {boolean} `false` if chart could not be resized dynamically.
             */
            const setXAxisExtremes = (dates) => {
                const min = dates?.start.valueOf();
                const max = dates?.end.valueOf();

                if (chart.value && isChartReady.value) {
                    // Loop through all xAxes (for main chart and navigator).
                    chart.value.xAxis?.forEach((axis) => {
                        setTimeout(() => axis.setExtremes(min, max, true), 200);
                    });
                    return !isNil(chart.value.xAxis);
                }
                // Did not set dynamically.
                return false;
            };

            //dependencies
            const _deps = /** @type {const} */ ([graph, dates]);

            //watcher - returned
            return watch(
                _deps,
                async (current, previous) => {
                    try {
                        const _graph = {
                            get current() {
                                return current[0];
                            },
                            get previous() {
                                return previous[0];
                            },
                            get isDirty() {
                                const isNotInitialized =
                                    this.previous === undefined;
                                return (
                                    isNotInitialized ||
                                    this.current !== this.previous
                                );
                            },
                        };
                        const _dates = {
                            get current() {
                                return current[1];
                            },
                            get previous() {
                                return previous[1];
                            },
                            get isDirty() {
                                const isNotInitialized =
                                    this.previous === undefined;
                                const isStartDateDirty =
                                    this.current?.start !==
                                    this.previous?.start;
                                const isEndDateDirty =
                                    this.current?.end !== this.previous?.end;
                                return (
                                    isNotInitialized ||
                                    isStartDateDirty ||
                                    isEndDateDirty
                                );
                            },
                            get interval() {
                                // GET interval.
                                const start =
                                    !isNil(this.current.start) &&
                                    Number.isFinite(this.current.start)
                                        ? getUnixTime(
                                              this.current.start.valueOf()
                                          )
                                        : null;

                                const end =
                                    !isNil(this.current.end) &&
                                    Number.isFinite(this.current.end)
                                        ? getUnixTime(
                                              this.current.end.valueOf()
                                          )
                                        : null;

                                const range = { start, end };
                                return range;
                            },
                        };

                        if (_dates.isDirty) {
                            setXAxisExtremes(_dates.current);
                        }
                    } catch (e) {
                        console.log(e);
                    }
                },
                {
                    deep: true,
                    immediate: true,
                    flush: 'pre',
                    // onTrigger: (e) => {
                    //     console.log(`[analysisChart] refresh::xAxis`, e);
                    // },
                }
            );
        };

        /** Define watcher to trigger under the specified conditions. */
        const onRefreshYAxes = () => {
            const {
                UnitByAxis,
                AxisByLimitKey,
                AxisType,
                AxisFormatByAxis,
                AxisTitleByAxis,
            } = constants;
            const { chart, graph, plotLines, scales, series } = state;
            const { currentTemperatureScale, isChartReady } = properties;
            const { getGraphYAxisTypes, setYAxes } = methods;

            /**
             * Map containing axis scales.
             */
            const ScaleByAxis = new Map(
                /** @type {[keyof AxisType, (scales?: ScaleFilterRecord) => ScaleFilter][]} */ ([
                    ['T', (scales) => ScaleFilter.create(scales.temp)],
                    ['RH', (scales) => ScaleFilter.clone(scales.rh)],
                    ['DP', (scales) => ScaleFilter.clone(scales.dp)],
                    [
                        'MOLD',
                        (_) =>
                            ScaleFilter.create({
                                lower: 0,
                                upper: 3,
                                checked: false,
                            }),
                    ],
                    [
                        'PI',
                        (_) =>
                            ScaleFilter.create({
                                lower: 0,
                                upper: 150,
                                checked: false,
                            }),
                    ],
                    [
                        'TWPI',
                        (_) =>
                            ScaleFilter.create({
                                lower: 0,
                                upper: 150,
                                checked: false,
                            }),
                    ],
                    [
                        'DC',
                        (_) =>
                            ScaleFilter.create({
                                lower: -4,
                                upper: 4,
                                checked: false,
                            }),
                    ],
                    [
                        'EMC',
                        (_) =>
                            ScaleFilter.create({
                                lower: 0,
                                upper: 30,
                                checked: false,
                            }),
                    ],
                ])
            );

            /**
             * Get the axis scale.
             * @param {keyof AxisType} axis
             * @param {ScaleFilterRecord} record
             * @returns {[ min: number , max: number, checked: boolean ]}
             */
            const getYAxisScale = (axis, record) => {
                const getScaleFilter = ScaleByAxis.get(axis);
                const filter = getScaleFilter(record);
                if (!filter.checked) {
                    const min = Number.isFinite(filter.lower)
                        ? filter.lower
                        : null;
                    const max = Number.isFinite(filter.upper)
                        ? filter.upper
                        : null;
                    return [filter.lower, filter.upper, filter.checked];
                }
                return [null, null, true];
            };

            /**
             * Set the axis extremes dynamically, if the chart exists.
             * @returns {boolean} `false` if chart could not be resized dynamically.
             */
            const setYAxisExtremes = (axis, min, max, checked) => {
                const _min = !checked && Number.isFinite(min) ? min : null;
                const _max = !checked && Number.isFinite(max) ? max + 1 : null;
                if (chart.value && isChartReady.value) {
                    const _axis = /** @type {Highcharts.Axis} */ (
                        chart.value.get(YAxis.formatID(axis))
                    );
                    if (!isNil(_axis)) {
                        // Trigger after a delay.
                        setTimeout(() => {
                            _axis.setExtremes(_min, _max);
                        }, 50);
                        return true;
                    }
                }
                // Did not set dynamically.
                return false;
            };

            // TRIGGER watch update when:
            // - The current route changes...
            // - The selected graph changes...
            // - The account's temperature scale changes...
            // - The plotlines change.
            // - The series change.
            const _deps = /** @type {const} */ ([
                graph,
                currentTemperatureScale,
                plotLines,
                scales,
                series,
            ]);
            return watch(
                _deps,
                async (current, previous) => {
                    try {
                        const [
                            _graph,
                            _temperatureScale,
                            _axisPlotLinesList,
                            _axisScales,
                            _axisSeries,
                        ] = current;

                        // GET the axes for the current graph.
                        const axes = getGraphYAxisTypes(_graph);

                        // NOTE: DO NOT CLEAR the current axes.
                        // Cleaing the axes will prevent the series from rendering,
                        // since they must be defined when switching graphs!!!
                        // clearYAxes(_graph);

                        // FOR EACH axis type, create them. (plotlines will be resolved later).
                        const axisList = axes.map((axis) => {
                            // DEFINE id.
                            const id = YAxis.formatID(axis);

                            // DEFINE label format.
                            const getAxisLabelFormat =
                                AxisFormatByAxis.get(axis);

                            // DEFINE axis title.
                            const getAxisTitle = AxisTitleByAxis.get(axis);

                            // DEFINE axis unit.
                            const unit = UnitByAxis.get(axis);
                            const currentUnit = unit(
                                /** @type {any} */ (_temperatureScale)
                            );

                            // Get the min and max values. Could be null, intentionally!
                            const [min, max, checked] = getYAxisScale(
                                axis,
                                _axisScales
                            );

                            // DEFINE settings.
                            /** @type {Partial<Highcharts.YAxisOptions>} */
                            const props = {
                                ...YAxis.DefaultAxisOptions,
                                ...{ min, max },
                                id,
                                labels: {
                                    format: getAxisLabelFormat(currentUnit),
                                },
                                title: {
                                    text: getAxisTitle(currentUnit),
                                },
                            };

                            setYAxisExtremes(axis, min, max, checked);

                            // CREATE initial instance.
                            const instance = YAxis.create(id, props);

                            // OVERRIDE and return for special TRH case.
                            if (_graph === 'TRH' && axis === 'RH') {
                                const override = YAxis.override(instance, {
                                    offset: -10,
                                    opposite: false,
                                });
                                return override;
                            } else if (_graph === 'TWPI' && axis === 'PI') {
                                const override = YAxis.override(instance, {
                                    visible: false,
                                });
                                return override;
                            }

                            // RETURN instance.
                            return instance;
                        });

                        // SET plot lines on each axis, accordingly.
                        _axisPlotLinesList.forEach((lines, axis) => {
                            const axisIndex = axisList.findIndex(
                                (ax) => ax.id === YAxis.formatID(axis)
                            );
                            if (axisIndex !== -1) {
                                const axisOptions = axisList[axisIndex];
                                axisOptions.plotLines = lines;
                                axisList[axisIndex] = axisOptions;
                            }
                        });

                        // SET the axes.
                        setYAxes(_graph, axisList);
                    } catch (e) {
                        console.log(e);
                    }
                },
                {
                    deep: true,
                    immediate: true,
                    flush: 'pre',
                    // onTrigger: (e) => {
                    //     console.log(`[analysisChart] refresh::yAxis`, e);
                    // },
                }
            );
        };

        /** Define watcher to trigger under the specified conditions. */
        const onRefreshChartOptions = () => {
            const { chartOptions, graph, yAxis, series } = state;
            const { setChartOptions, getGraphYAxisTypes } = methods;

            // TRIGGER watch update when:
            // - The yAxis changes...
            // - The series changes...
            const _deps = /** @type {const} */ ([yAxis, series]);
            return watch(
                _deps,
                (current) => {
                    try {
                        const [_yAxis, _series] = current;

                        // GET source.
                        /** @type {Highcharts.Options} */
                        const source = clone({
                            ...omit(chartOptions.value, 'series', 'navigator'),
                            navigator: omit(
                                chartOptions.value.navigator,
                                'series'
                            ),
                        });

                        // CLEAR previous data structures from the last graph type.
                        source.series = [];
                        source.navigator.series = [];
                        source.navigator.adaptToUpdatedData = true;

                        // GET axes to be added to the graph.
                        const graphAxes = _yAxis.get(graph.value) ?? [];
                        if (Array.isArray(source.yAxis)) {
                            source.yAxis = source.yAxis.filter((axis) =>
                                graphAxes.some((yAxis) => yAxis.id === axis.id)
                            );
                        }

                        // GET series to be added to the graph.
                        const axes = getGraphYAxisTypes(graph.value) ?? [];
                        const graphSeries = axes
                            .flatMap((ax) => _series.get(ax) ?? [])
                            .filter((s) =>
                                graphAxes
                                    .map((ax) => ax.id)
                                    .includes(String(s.yAxis))
                            );

                        const lineSeries =
                            /** @type {Highcharts.SeriesLineOptions[]} */ (
                                graphSeries.filter((s) => s.type === 'line')
                            );

                        // MAP series into the navigator props.
                        /** @type {Partial<Highcharts.NavigatorOptions>} */
                        let graphNavigator = null;
                        graphNavigator = {
                            xAxis: {
                                ordinal: false,
                            },
                            series:
                                lineSeries?.map((s) => ({
                                    ...s,
                                    type: 'line',
                                    enableMouseTracking: false,
                                    showInLegend: false,
                                    showInNavigator: true,
                                })) ?? [],
                        };

                        const symbolSize = [
                            'PI',
                            'TWPI',
                            'DC',
                            'MOLD',
                        ].includes(graph.value)
                            ? 12
                            : 40;

                        // DEFINE override props.
                        /** @type {Partial<Highcharts.Options>} */
                        const props = {
                            yAxis: graphAxes,
                            legend: {
                                enabled: true,
                                verticalAlign: /** @type {"top"} */ ('top'),
                                symbolHeight: symbolSize,
                                symbolWidth: symbolSize,
                            },
                            series: graphSeries,
                            navigator: graphNavigator,
                            exporting: {
                                chartOptions: {
                                    yAxis: graphAxes,
                                    series: graphSeries,
                                },
                            },
                        };

                        // COMMIT changes.
                        const override = Object.assign(source, props);

                        // console.log('debug::diff', diff(override, source));
                        // const override = ChartOptions.override(source, props);
                        setChartOptions(override);
                    } catch (e) {
                        console.log(e);
                    }
                },
                {
                    deep: true,
                    immediate: true,
                    flush: 'pre',
                    // onTrigger: (e) => {
                    //     console.log(`[analysisChart] refresh::chartOptions`, e);
                    // },
                }
            );
        };

        /** Define watcher to trigger under the specified conditions. */
        const onResolveChartOptions = () => {
            const { ExportFormat } = constants;
            const { isLoading } = properties;
            const {
                key,
                resolvedOptions,
                chartOptions,
                enableMouseTracking,
                exportFormat,
                onAfterChartLoaded,
                onAfterSetXAxisExtremes,
                onExportError,
            } = state;

            const _deps = /** @type {const} */ ([
                chartOptions,
                isLoading,
                enableMouseTracking,
                exportFormat,
                onAfterChartLoaded,
                onAfterSetXAxisExtremes,
                onExportError,
            ]);

            return watch(
                _deps,
                (current, previous, onCleanup) => {
                    try {
                        // DESTRUCTURE reactive values.
                        const [
                            _chartOptions,
                            _isLoading,
                            _enableMouseTracking,
                            _exportFormat,
                            _onAfterChartLoaded,
                            _onAfterSetXAxisExtremes,
                            _onExportError,
                        ] = current;

                        // TRANSFORM input values to valid options.
                        const _exportType = ExportFormat.get(_exportFormat);

                        // DISABLE mouse tracking.
                        _chartOptions.series.forEach((s) => {
                            s.enableMouseTracking = false;
                        });

                        // DEFINE other bindings.
                        /** @type {Partial<Highcharts.Options>} Bindings to resolve. */
                        const bindings = {
                            chart: {
                                events: {
                                    load: _onAfterChartLoaded,
                                },
                            },
                            title: {
                                text: resolvedOptions.value.title.text,
                            },
                            subtitle: {
                                text: resolvedOptions.value.subtitle.text,
                            },
                            /** @type {Highcharts.ExportingOptions} */
                            exporting: {
                                error: _onExportError,
                                type: _exportType,
                            },
                            plotOptions: {
                                series: {
                                    enableMouseTracking:
                                        !_isLoading && _enableMouseTracking,
                                },
                            },
                            xAxis: {
                                events: {
                                    afterSetExtremes: _onAfterSetXAxisExtremes,
                                },
                            },
                        };

                        try {
                            // if (_chartOptions.yAxis?.length > 1) {
                            //     debugger;
                            // }
                            // OVERRIDE current chart options settings,
                            // AND UPDATE the resolved options data.
                            // NOTE: This line triggers the UI update.
                            resolvedOptions.value = ChartOptions.override(
                                _chartOptions,
                                bindings
                            );
                        } catch (e) {
                            console.error(e);
                        }

                        // HIDE the context button.
                        resolvedOptions.value.exporting = Object.assign(
                            resolvedOptions.value.exporting,
                            {
                                buttons: {
                                    contextButton: {
                                        enabled: false,
                                    },
                                },
                            }
                        );

                        // RE=ENABLE mouse tracking after short delay.
                        const onCancel = setTimeout(() => {
                            resolvedOptions.value.series.forEach(
                                (s) => (s.enableMouseTracking = true)
                            );
                        }, 500);

                        /** Update the unique chart key. */
                        key.value = `chart-${Date.now()}`;

                        onCleanup(() => clearTimeout(onCancel));
                    } catch (e) {
                        console.log(e);
                    }
                },
                {
                    deep: true,
                    immediate: true,
                    flush: 'pre',
                    // onTrigger: (e) => {
                    //     console.log(`[analysisChart] resolve::chartOptions`, e);
                    // },
                }
            );
        };

        // DEFINE and RETURN watcher handle collection.
        return [
            onUpdateChartOverlay(),
            // onQueryStore(),
            onQueryDateRangeFilter(),
            onQueryLimitFilterRecord(),
            onQueryScaleFilterRecord(),
            onQueryLocationsFilter(),
            onQueryWeatherStationsFilter(),
            onUpdateTitle(),
            onUpdateSubtitle(),
            onUpdateData(),
            onRefreshPlotLines(),
            onRefreshXAxes(),
            onRefreshYAxes(),
            onRefreshChartOptions(),
            onResolveChartOptions(),
        ];
    }

    // /**
    //  * Define watcher for the resolved chart options.
    //  * @param {Pick<AnalysisChartContext, 'constants' | 'service' | 'state' | 'properties' | 'methods'>} context
    //  * @returns {V.WatchStopHandle}
    //  */
    // static defineResourceFilterWatcher(context) {
    //     const { constants, state, properties } = context;
    //     const { currentRoute, currentDateRange, currentTemperatureScale } = properties;
    //     const {
    //         resolvedOptions,
    //         graph,
    //         dates,
    //         limits,
    //         scales,
    //         locations,
    //         weatherStations,
    //     } = state;

    // }

    /**
     * Define watchers that respond to changes in the reactive state.
     * @param {Pick<AnalysisChartContext, 'service' | 'constants' | 'state' | 'properties' | 'methods'>} context
     */
    static defineWatchers = (context) => {
        const { constants, methods } = context;

        /**
         * Watcher handles.
         * @type {{ handles: V.WatchStopHandle[] }}
         */
        const _watchers = reactive({ handles: [] });

        /**
         * Set the watch handles.
         * @param {V.WatchStopHandle[]} [handles]
         */
        const _setWatchHandles = (handles) => {
            _watchers.handles = handles ?? [];
        };

        /** Stop watch handles. */
        const stop = () => {
            // Unsubscribe, if handles exist.
            _watchers.handles?.forEach((h) => h?.());
            // Clear handles.
            _setWatchHandles(null);
        };

        /** Register watch effects. */
        const listen = () => {
            // Clear any running handles.
            stop();

            // Set the listeners.
            _setWatchHandles([
                ...AnalysisChart.defineChartOptionsWatchers(context),
            ]);
        };

        // RETURN subscribtion and unsubscription functions.
        return Object.seal({
            stop,
            listen,
        });
    };

    /**
     * Define Vuex subscribers for the Analysis Chart.
     * @param {Pick<AnalysisChartContext, 'service' | 'constants' | 'state' | 'properties' | 'methods'>} context
     */
    static defineSubscribers = (context) => {
        const { service } = context;
        const { store } = service;

        /**
         * Reactive unsubscription object.
         * @private
         * @type {{ mutations?: () => void, actions?: () => void }}
         */
        const _unsubscribe = reactive({
            mutations: null,
            actions: null,
        });

        /**
         * Define mutation and action subscribers.
         * @private
         */
        const _createSubscriptions = () => {
            // DEFINE mutation listener.
            const _removeMutationListeners = store.subscribe(
                (mutation, state) => {
                    // HANDLE mutation events.
                    // TODO: Add listeners.
                }
            );

            // DEFINE action listener.
            const _removeActionListeners = store.subscribeAction({
                before: async (action, state) => {
                    // HANDLE action events (before the action is applied).
                    // TODO: Add listeners.
                },
                after: async (action, state) => {
                    // HANDLE action events (after the action is applied).
                    // TODO: Add listeners.
                },
            });

            // RETURN mutation and action subscribers.
            return [_removeMutationListeners, _removeActionListeners];
        };

        // DEFINE unsubscriber.
        const unsubscribe = () => {
            // REMOVE action listeners, if present.
            if (
                !isNil(_unsubscribe?.actions) &&
                typeof _unsubscribe.actions === 'function'
            ) {
                _unsubscribe.actions();
                _unsubscribe.actions = null;
                console.log(`unsubscribe::actions`);
            }
            // REMOVE mutation listeners, if present.
            if (
                !isNil(_unsubscribe?.mutations) &&
                typeof _unsubscribe.mutations === 'function'
            ) {
                _unsubscribe.mutations();
                _unsubscribe.mutations = null;
                console.log(`unsubscribe::mutations`);
            }
        };

        // DEFINE subscriber.
        const subscribe = () => {
            // REMOVE listeners, if present.
            unsubscribe();

            // DEFINE new subscriptions.
            const [_removeMutationListeners, _removeActionListeners] =
                _createSubscriptions();

            // SAVE unsubscribers.
            _unsubscribe.mutations = _removeMutationListeners;
            _unsubscribe.actions = _removeActionListeners;
        };

        // RETURN subscribtion and unsubscription functions.
        return Object.seal({
            subscribe,
            unsubscribe,
        });
    };
}

//=== BEHAVIOUR ====//
/** @typedef {ReturnType<useService>} AnalysisChartService */
/** @typedef {ReturnType<useConstants>} AnalysisChartConstants */
/** @typedef {ReturnType<useState>} AnalysisChartState */
/** @typedef {ReturnType<useProperties>} AnalysisChartProperties */
/** @typedef {ReturnType<useMethods>} AnalysisChartMethods */
/** @typedef {ReturnType<useSubscribers>} AnalysisChartSubscribers */
/**
 * @typedef {Object} AnalysisChartContext
 * @property {AnalysisChartService} service
 * @property {AnalysisChartConstants} constants
 * @property {AnalysisChartState} state
 * @property {AnalysisChartProperties} properties
 * @property {AnalysisChartMethods} methods
 * @property {AnalysisChartSubscribers} subscribers
 * @property {() => Promise<void>} initialize (Initializes the chart.)
 */

/** Define contextual globals. */
const useService = () => {
    /** Router. */
    const router = useRouter();
    /** @type {Store<ECNBState>} Store. */
    const store = useStore();
    /** Cache. */
    const cache = useECNBCache(store);
    /** LocationResource index. */
    const locationIndex = useLocationIndex(cache);
    /** WeatherStationResource index. */
    const weatherStationIndex = useWeatherStationIndex(cache);
    // RETURN context.
    return Object.freeze({
        router,
        store,
        cache,
        locationIndex,
        weatherStationIndex,
    });
};

/** Define constants. */
const useConstants = AnalysisChart.defineConstants;

/**
 * Define initial state for the Analysis Chart.
 * @param {Pick<AnalysisChartContext, 'service' | 'constants'>} context
 */
const useState = (context) => {
    // REFERENCES
    const _highcharts = AnalysisChart.defineHighchartsRefs();
    const _status = AnalysisChart.defineStatusRefs();
    const _filters = AnalysisChart.defineFilterRefs();
    const _filterRecords = AnalysisChart.defineFilterRecordRefs();
    const _chartOptions = AnalysisChart.defineChartOptionsRefs();
    const _series = AnalysisChart.defineDataRefs();

    // RETURN destructured values.
    return Object.seal({
        ..._highcharts,
        ..._status,
        ..._filters,
        ..._filterRecords,
        ..._chartOptions,
        ..._series,
    });
};

/**
 * Define reactive, computed properties for the Analysis Chart.
 * @param {Pick<AnalysisChartContext, 'service' | 'constants' | 'state'>} context
 */
const useProperties = (context) => {
    // COMPUTED PROPERTIES
    const _router = AnalysisChart.defineRouterProperties(context);
    const _store = AnalysisChart.defineStoreProperties(context);
    const _index = AnalysisChart.defineResourceProperties(context);
    const _status = AnalysisChart.defineStatusProperties(context);
    const _conditions = AnalysisChart.defineConditionProperties(context);
    const _graph = AnalysisChart.defineGraphProperties(context);
    const _filter = AnalysisChart.defineFilterProperties(context);

    // RETURN destructured values.
    return Object.seal({
        ..._router,
        ..._store,
        ..._index,
        ..._status,
        ..._conditions,
        ..._graph,
        ..._filter,
    });
};

/**
 * Define API for the Analysis Chart behaviours.
 * @param {Pick<AnalysisChartContext, 'service' | 'constants' | 'state' | 'properties'>} context
 * @param {V.SetupContext<AnalysisChartConstants['GraphEvents']['print'][keyof AnalysisChartConstants['GraphEvents']['print']][]>['emit']} emit
 */
const useMethods = (context, emit) => {
    // METHODS
    const _highcharts = AnalysisChart.defineHighchartsAPI(context);
    const _graph = AnalysisChart.defineGraphAPI(context);
    const _status = AnalysisChart.defineStatusAPI(context);
    const _exports = AnalysisChart.defineExportAPI(context, _status, emit);
    const _filters = AnalysisChart.defineFilterAPI(context);
    const _filterRecords = AnalysisChart.defineFilterRecordAPI(context);
    const _chartOptions = AnalysisChart.defineChartOptionsAPI(context);
    const _series = AnalysisChart.defineSeriesAPI(context, _chartOptions);
    const _yAxis = AnalysisChart.defineYAxisAPI(context, _chartOptions);
    const _plotLines = AnalysisChart.defineYAxisPlotLinesAPI(
        context,
        _chartOptions
    );
    const _data = AnalysisChart.defineDataAPI(context);

    // RETURN destructured values.
    const $methods = Object.seal({
        ..._highcharts,
        ..._graph,
        ..._status,
        ..._exports,
        ..._filters,
        ..._filterRecords,
        ..._chartOptions,
        ..._series,
        ..._yAxis,
        ..._plotLines,
        ..._data,
    });

    // RETURN methods.
    return $methods;
};

/**
 * Define API for the Analysis Chart behaviours.
 * @param {Pick<AnalysisChartContext, 'service' | 'constants' | 'state' | 'properties' | 'methods'>} context
 */
const useSubscribers = (context) => {
    // METHODS
    const _watchers = AnalysisChart.defineWatchers(context);
    const _subscribers = AnalysisChart.defineSubscribers(context);

    // RETURN destructured values.
    return Object.seal({
        ..._watchers,
        ..._subscribers,
    });
};

// <!-- EXPORTS -->

/**
 * Define the behaviour for the analysis chart.
 * @param {V.SetupContext<AnalysisChartConstants['GraphEvents']['print'][keyof AnalysisChartConstants['GraphEvents']['print']][]>['emit']} emit
 * @returns {AnalysisChartContext}
 */
export const useAnalysisChart = (emit) => {
    // Create the context.
    const service = useService();
    const constants = useConstants();
    const state = useState({ service, constants });
    const properties = useProperties({ service, constants, state });
    const methods = useMethods({ service, constants, state, properties }, emit);
    const subscribers = useSubscribers({
        service,
        constants,
        state,
        properties,
        methods,
    });

    /**
     * Initialize the analysis chart.
     */
    const initialize = async () => {
        const { Status } = constants;
        const {
            enableMouseTracking,
            onAfterChartLoaded,
            onAfterSetXAxisExtremes,
            onExportError,
            zoomed,
        } = state;

        // Initialize the Highcharts instance.
        methods.initializeModules();

        // Reset the state of the chart, before watchers are called.
        methods.resetZoom();
        // methods.resetChartOptions();
        // methods.resetLimitsFilterRecord();
        // methods.resetScaleFilterRecord();
        // methods.resetGraphType('T');

        // Bind reactive options and callback methods.
        enableMouseTracking.value = true;
        onAfterChartLoaded.value = (e) => {
            if (properties.isPrinting.value === true) {
                console.log(`export::chart`, e);
                console.log(`Chart created for export successfully.`);
            } else {
                console.log(`load::chart`, e);
                /** @type {Highcharts.StockChart} Get the loaded chart instance from the event target. */
                state.chart.value = /** @type {any} */ (e.target ?? null);
                methods.resetXZoom();
            }
        };
        onAfterSetXAxisExtremes.value = (e) => {
            if (['navigator', 'zoom'].includes(e?.trigger ?? '')) {
                zoomed.value = true;
            }
            console.log(`set::extremes [xAxis]`, e, { zoomed });
        };
        onExportError.value = methods.print.onError;

        // "Should" be initialized.
        methods.enable(Status.INITIALIZED);
    };

    return Object.seal({
        initialize,
        service,
        constants,
        state,
        properties,
        methods,
        subscribers,
    });
};

// <!-- DEFAULT -->
export default useAnalysisChart;
