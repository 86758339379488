// <!-- API -->
import { computed, ref, reactive, watch } from 'vue';

// <!-- UTILITIES -->
import {
    Tree,
    Config,
    Node,
    NodeRecord,
    NodeState,
    NodeSelector,
    NodeCodes,
    NodeTypes,
} from '@/utils/tree';
import { clone, isNil, startCase } from 'lodash-es';
import debounce from 'just-debounce-it';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useECNBCache } from '@/hooks/store/useECNBCache';
import { useWeatherStationIndex } from '@/hooks/cache/useWeatherStationIndex';
import { useAlerts } from '@/components/alerts/hooks/useAlerts';

// <!-- EVENTS -->
import { TREEVIEW } from '@/components/sidebar/events';

// <!-- TYPES -->
/** @typedef {import('@/components/alerts/hooks/useAlerts').AlertDef} AlertDef */

/** @typedef {import('@/hooks/store/useECNBCache').ECNBCache} ECNBCache */
/** @typedef {import('@/store/types/ECNBStore').ECNBState} ECNBState */
/** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */

/** @typedef {ReturnType<useService>} IWeatherStationFilterService */
/** @typedef {ReturnType<useState>} IWeatherStationFilterState */
/** @typedef {ReturnType<useConstants>} IWeatherStationFilterConstants */
/** @typedef {ReturnType<useProperties>} IWeatherStationFilterProperties */
/** @typedef {ReturnType<useMethods>} IWeatherStationFilterMethods */
/**
 * @typedef {Object} IWeatherStationFilterContext
 * @property {IWeatherStationFilterService} service
 * @property {IWeatherStationFilterState} state
 * @property {IWeatherStationFilterConstants} constants
 * @property {IWeatherStationFilterProperties} properties
 * @property {IWeatherStationFilterMethods} methods
 */

// <!-- INTERFACES -->

/**
 * @typedef {Object} ITreeButton
 * @property {string} label Display text.
 * @property {boolean} enabled Is the button currently enabled?
 * @property {Function} onButtonClick Action to invoke when the button is clicked.
 */

// <!-- ENUMS -->

const ButtonID = Object.freeze(
    /** @type {const} */ ({
        [TREEVIEW.ExpandEvent]: 'expand',
        [TREEVIEW.CollapseEvent]: 'collapse',
        [TREEVIEW.CheckAllEvent]: 'check:all',
        [TREEVIEW.CheckNoneEvent]: 'check:none',
    })
);

/** @typedef {typeof ButtonID[keyof typeof ButtonID]} ButtonID */

const ButtonLabel = Object.freeze(
    /** @type {const} */ ({
        [TREEVIEW.ExpandEvent]: 'Expand',
        [TREEVIEW.CollapseEvent]: 'Collapse',
        [TREEVIEW.CheckAllEvent]: 'Check All',
        [TREEVIEW.CheckNoneEvent]: 'Check None',
    })
);

/** @typedef {typeof ButtonLabel[keyof typeof ButtonLabel]} ButtonLabel */

// <!-- COMPOSABLES -->
/**
 * Define composable services.
 * @param {Object} props
 * @param {Readonly<V.Ref<number>>} [props.checkedLimit]
 */
const useService = (props) => {
    /** @type {Store<ECNBState>} */
    const store = useStore();

    /** @type {ECNBCache} */
    const cache = useECNBCache(store);

    /** WeatherStation cache index. */
    const weatherStationIndex = useWeatherStationIndex(cache);

    /** Router. */
    const router = useRouter();

    /** @type {V.ComputedRef<number>} */
    const checkedLimit = computed(() => {
        const capacity = props.checkedLimit?.value ?? Infinity;
        return capacity;
    });

    /** @type {ReturnType<useAlerts>} */
    const alerts = useAlerts();

    // RETURN services.
    return {
        store,
        router,
        cache,
        weatherStationIndex,
        checkedLimit,
        alerts,
    };
};

/** Define static constants. */
const useConstants = () => {
    // <!-- EXPOSE -->
    return {
        ButtonID,
        ButtonLabel,
    };
};

/**
 * Define reactive state.
 * @param {Pick<IWeatherStationFilterContext, 'service' | 'constants'>} context
 */
const useState = (context) => {
    const { service } = context;
    /** @type {Treeview.Tree} */
    const tree = reactive(Tree.create());
    /** @type {V.Ref<boolean>} Are we busy? */
    const busy = ref(false);
    /** @type {V.Ref<ITreeButton[]>} Sidebar buttons. */
    const buttons = ref([]);
    /** Alerts state reference. */
    const alerts = service.alerts.state.alerts;
    /** @type {V.Ref<V.WatchStopHandle[]>} */
    const handles = ref([]);
    // RETURN state.
    return {
        tree,
        busy,
        buttons,
        alerts,
        handles,
    };
};

/**
 * Define computed properties.
 * @param {Pick<IWeatherStationFilterContext, 'service' | 'constants' | 'state'>} context
 */
const useProperties = (context) => {
    const { service, state } = context;
    const { weatherStationIndex } = service;
    const { tree, buttons, busy } = state;

    /** @type {V.ComputedRef<Boolean>} */
    const isBusy = computed(() => {
        // const _buttons = buttons.value ?? [];
        // const _isMissingButtons = _buttons.length <= 0;
        const _isBusy = busy.value === true;
        const _isFetchingWeatherStations =
            weatherStationIndex.isFetching.value === true;
        return _isBusy || _isFetchingWeatherStations;
    });

    /** @type {V.ComputedRef<import('@/models/weather/WeatherStation').WeatherStationResource[]>} */
    const availableWeatherStations = computed(() => {
        const _stations = weatherStationIndex.stations.value ?? [];
        return _stations;
    });

    /** @type {V.ComputedRef<Boolean>} */
    const isEmpty = computed(() => {
        const _s = availableWeatherStations.value ?? [];
        return _s.length === 0;
    });

    // <!-- EXPOSE -->
    return {
        availableWeatherStations,
        isBusy,
        isEmpty,
    };
};

/**
 * Define component methods.
 * @param {Pick<IWeatherStationFilterContext, 'service' | 'constants' | 'state' | 'properties'>} context
 */
const useMethods = (context) => {
    // DESTRUCTURE context.
    const { service, constants, state } = context;
    const { store, weatherStationIndex, alerts } = service;
    const { ButtonID, ButtonLabel } = constants;
    const { handles, config, nodes, tree, busy, buttons } = state;
    const { pushAlert, clearAlert, createAlert } = alerts.methods;

    /** Expand all nodes. */
    const onExpandAll = () => {
        // CLEAR alerts.
        initializeAlerts();

        // GET node record.
        const nodes = NodeRecord.clone(tree.nodes);

        // UPDATE state for all records.
        Object.keys(nodes).forEach((key) => {
            const state = NodeState.enable(nodes[key].state, 'opened');
            nodes[key].state = state;
        });

        // UPDATE the state.
        tree.nodes = nodes;
    };

    /** Collapse all nodes. */
    const onCollapseAll = () => {
        // CLEAR alerts.
        initializeAlerts();

        // GET node record.
        const nodes = NodeRecord.clone(tree.nodes);

        // UPDATE state for all records.
        Object.keys(nodes).forEach((key) => {
            const state = NodeState.disable(nodes[key].state, 'opened');
            nodes[key].state = state;
        });

        // UPDATE the state.
        tree.nodes = nodes;
    };

    /** Uncheck all nodes. */
    const onCheckNone = () => {
        // CLEAR alerts.
        initializeAlerts();

        // GET node record.
        const nodes = NodeRecord.clone(tree.nodes);

        // UPDATE state for all records.
        Object.keys(nodes).forEach((key) => {
            nodes[key].state = NodeState.override(nodes[key].state, {
                indeterminate: false,
                checked: false,
            });
        });

        // UPDATE the state.
        tree.nodes = nodes;
    };

    /**
     * Check all weather station nodes.
     */
    const onCheckAllWeatherStationNodes = () => {
        // CLEAR alerts.
        initializeAlerts();

        // GET node record.
        const nodes = NodeRecord.clone(tree.nodes);

        // UNCHECK all nodes.
        Object.keys(nodes).forEach((key) => {
            nodes[key].state = NodeState.override(nodes[key].state, {
                indeterminate: false,
                checked: false,
            });
        });

        // GET sorted nodes for the node subtrees.
        const sortedNodes = NodeRecord.sorted(nodes);
        // const sortedNodes = Object.values(nodes).sort((a, b) => {
        //     const ancestorsA = NodeRecord.getAncestorsWithSelf(nodes, a);
        //     const ancestorsB = NodeRecord.getAncestorsWithSelf(nodes, b);
        //     const valuesA = ancestorsA.map((n) => n.text.trim()).join('/');
        //     const valuesB = ancestorsB.map((n) => n.text.trim()).join('/');
        //     return valuesA.localeCompare(valuesB);
        // });

        // GET sorted weather stations for the tree.
        const sortedWeatherStations = sortedNodes.filter(
            Node.isWeatherStationNode
        );

        // CHECK if limit is finite.
        if (Number.isFinite(service.checkedLimit.value)) {
            // RAISE alert.
            raiseCapacityWarning(
                sortedWeatherStations.length,
                service.checkedLimit.value
            );
        }

        // CHECK weather stations within the limit.
        const slicedWeatherStations = sortedWeatherStations.slice(
            0,
            service.checkedLimit.value
        );
        slicedWeatherStations.forEach((l) => {
            nodes[l.id].state = NodeState.enable(nodes[l.id].state, 'checked');
        });

        // UPDATE the state.
        tree.nodes = nodes;
    };

    /**
     * Get the button click handler.
     * @param {string} id
     * @returns {(e: MouseEvent) => void}
     */
    const getButtonClickHandler = (id) => {
        const logButtonClick = (e) => console.log(`click:${id}`, e);
        switch (id) {
            case TREEVIEW.ExpandEvent:
                return (e) => {
                    logButtonClick(e);
                    onExpandAll();
                };
            case TREEVIEW.CollapseEvent:
                return (e) => {
                    logButtonClick(e);
                    onCollapseAll();
                };
            case TREEVIEW.CheckAllEvent:
                return (e) => {
                    logButtonClick(e);
                    onCheckAllWeatherStationNodes();
                };
            case TREEVIEW.CheckNoneEvent:
                return (e) => {
                    logButtonClick(e);
                    onCheckNone();
                };
            default:
                return logButtonClick;
        }
    };

    /**
     * Check a single node.
     * @param {Treeview.Node} e Node being checked.
     */
    const onNodeChecked = (e) => {
        // CLEAR alerts.
        initializeAlerts();

        // GET sorted nodes.
        const _tree = NodeRecord.clone(tree.nodes);
        const sorted = Object.values(_tree).sort((a, b) => {
            const _a = NodeRecord.getPath(_tree, a.id);
            const _b = NodeRecord.getPath(_tree, b.id);
            if (a.id === 'h0' || (a.id.startsWith('l') && a.parent === 'h0')) {
                _a.unshift('Z999');
            }
            if (b.id === 'h0' || (b.id.startsWith('l') && b.parent === 'h0')) {
                _b.unshift('Z999');
            }
            return _a.join('/').localeCompare(_b.join('/'));
        });

        // GET checked weather station nodes.
        const checked = sorted.filter((n) => NodeState.isChecked(n.state));
        const checkedWeatherStations = checked.filter(
            Node.isWeatherStationNode
        );

        // CHECK if limit is finite.
        if (Number.isFinite(service.checkedLimit.value)) {
            // RAISE alert.
            raiseCapacityWarning(
                checkedWeatherStations.length,
                service.checkedLimit.value
            );

            // IF CAPACITY IS EXCEEDED, UNCHECK THE PREVIOUS NODE AND ALL ITS CHILDREN.
            if (checkedWeatherStations.length > service.checkedLimit.value) {
                // CALCULATE overflow.
                const overflow =
                    checkedWeatherStations.length - service.checkedLimit.value;

                // GET the target node.
                const target = e?.id ? _tree[e.id] : null;

                // UNCHECK the target node and all its weather station descendants.
                const unchecked = NodeRecord.getDescendantsOf(_tree, target)
                    .filter(Node.isWeatherStationNode)
                    .map((n) => n.id)
                    .slice(-1 * overflow);

                // ENSURE target is included in uncheck flow.
                unchecked.unshift(target.id);

                // FILTER checked for unaffected nodes.
                const resolved = checked.map((node) => {
                    // DISABLE checked state for the blocked nodes.
                    const isUnchecked = unchecked.includes(node.id);
                    if (isUnchecked) {
                        node.state = NodeState.disable(node.state, 'checked');
                    }
                    return node;
                });

                // CHANGE resolved nodes in the tree structure.
                const updated = sorted.map((node) => {
                    // RETURN updated or self.
                    const update = resolved.find((n) => n.id === node.id);
                    return update ?? node;
                });

                // CREATE NODE RECORD.
                const nodes = NodeRecord.fromCollection(updated);

                // UPDATE the state.
                tree.nodes = nodes;
            } else {
                // DO NOTHING, since there's no limitation issue.
                return;
            }
        }
    };

    /**
     * Get the button click handler.
     * @param {string} id
     * @returns {(e: Treeview.Node) => void}
     */
    const getTreeEventHandler = (id) => {
        const logTreeEvent = (e) => console.log(`tree:${id}`, e);
        switch (id) {
            case TREEVIEW.ExpandEvent:
                return (e) => {
                    logTreeEvent(e);
                    // onExpandAll();
                };
            case TREEVIEW.CollapseEvent:
                return (e) => {
                    logTreeEvent(e);
                    // onCollapseAll();
                };
            case TREEVIEW.NodeCheckedEvent:
                return (e) => {
                    logTreeEvent(e);
                    onNodeChecked(e);
                };
            case TREEVIEW.CheckNoneEvent:
                return (e) => {
                    logTreeEvent(e);
                    // onCheckNone();
                };
            default:
                return logTreeEvent;
        }
    };

    /**
     * Get all the weather station nodes in the hierarchy root's descendant chain.
     * @param {Treeview.Node} root
     * @param {Treeview.NodeRecord} nodes (Schema)
     * @returns {Treeview.Node[]}
     */
    const getWeatherStationNodeDescendants = (root, nodes) => {
        const _children = root?.children ?? [];
        const _directWeatherStations = _children
            .filter((id) =>
                NodeSelector.isSpecificFormat(id, NodeTypes.Station)
            )
            .map((id) => nodes[id]);
        if (_children.length > 0) {
            const _hChildren = _children.filter((id) =>
                NodeSelector.isSpecificFormat(id, NodeTypes.Hierarchy)
            );
            const _hNodes = _hChildren
                .map((id) => nodes[id])
                .filter((n) => !isNil(n));
            const _nestedWeatherStations = _hNodes.flatMap((n) =>
                getWeatherStationNodeDescendants(n, nodes)
            );
            return [..._directWeatherStations, ..._nestedWeatherStations];
        }
        return _directWeatherStations;
    };

    /** Init alerts. */
    const initializeAlerts = () => {
        // CLEAR all alerts.
        state.alerts.value = [];
    };

    // /** Init buttons. */
    // const initializeButtons = () => {
    //     // DEFINE button details.
    //     const _buttons = Object.values(ButtonID).map((id) => {
    //         /** @type {ITreeButton} */
    //         const button = {
    //             label: ButtonLabel[id] ?? '<Missing>',
    //             enabled: true, // TODO: Dynamic toggle?
    //             onButtonClick: getButtonClickHandler(id),
    //         };
    //         return button;
    //     });
    //     // REPLACE existing buttons.
    //     buttons.value = _buttons;
    // };

    /** Initialize the configuration and nodes of the treeview. */
    const initializeTree = () => {
        /**
         * Compare two resources with name properties.
         * @param {{ id?: string | number, name: string }} a
         * @param {{ id?: string | number, name: string }} b
         * @returns {number}
         */
        const compareByResourceName = (a, b) => {
            const _a = `${a?.name.trim() ?? ''}`;
            const _b = `${b?.name.trim() ?? ''}`;
            return _a.localeCompare(_b);
        };

        /** @type {(resource: {id: string | number}) => string} */
        const formatWeatherStationID = ({ id }) => NodeSelector.format('s', id);

        /** @type {(resource: {text: string}) => string} */
        const formatWeatherStationText = ({ text }) => {
            try {
                // SPLIT by comma.
                let [municipality, state] = text.split(',');

                // SPLIT by delimiter.
                let subregions = municipality.includes('/')
                    ? municipality.split('/')
                    : [municipality];

                subregions = subregions.map((s) => s.toLocaleLowerCase());

                // PASCAL CASE
                municipality =
                    subregions.length === 1
                        ? startCase(subregions[0])
                        : subregions.map((s) => startCase(s)).join('/');

                // SUBSTITUTE abbreviations.

                // RETURN formatted text.
                return `${municipality}, ${state}`;
            } catch (e) {
                console.warn(
                    `Weather Station does not conform to normal standard.`
                );
                return text;
            }
        };

        // DEFINE initial tree state.
        const _tree = Tree.create({ config: { roots: [], leaves: [] } });

        // QUERY subtree node data.
        const _stations = queryWeatherStations();
        const roots = _stations
            .map((station) => {
                const id = formatWeatherStationID(station);
                const text = formatWeatherStationText({ text: station.name });
                const children = [];
                const parent = null;
                const state = NodeState.create({
                    opened: true,
                    checked: false,
                });
                const node = Node.create({
                    id,
                    text,
                    children,
                    parent,
                    state,
                    type: NodeTypes.Station,
                });

                // Add node.
                _tree.nodes[node.id] = node;

                return node;
            })
            .sort((a, b) => {
                const [_a, _stateA] = a.text.split(',');
                const [_b, _stateB] = b.text.split(',');
                const comparison = _stateA.localeCompare(_stateB);
                return comparison === 0 ? _a.localeCompare(_b) : comparison;
            });

        // ASSIGN roots.
        _tree.config.roots = roots.map((s) => s.id);

        // SAVE tree to local state.
        Object.assign(state.tree, _tree);

        // QUERY tree form the Vuex store.
        queryTree();

        // SAVE _tree to the Vuex store.
        commitTree(state.tree);

        // QUERY uri path.
        queryCurrentRouteParams();

        // SAVE _tree to the Vuex store.
        commitTree(state.tree);
    };

    /**
     * Get the latest weather stations.
     * @returns {import('@/models/weather/WeatherStation').WeatherStationResource[]}
     */
    const queryWeatherStations = () => {
        const _weatherStations = clone(
            service.weatherStationIndex.stations.value
        );
        return _weatherStations;
    };

    /**
     * Query current route path.
     */
    const queryCurrentRouteParams = () => {
        const currentRoute = service.router.currentRoute;
        if (['Home', 'Analysis'].includes(String(currentRoute.value.name))) {
            if ('station' in currentRoute.value.query) {
                // GET the selected weather station id.
                const qs = currentRoute.value.query['station'];
                const selected = Array.isArray(qs) ? qs : [qs];

                // GET the selected nodes, if they exist.
                const sorted = NodeRecord.sorted(tree.nodes);
                const targets = sorted
                    .filter(Node.isWeatherStationNode)
                    .filter((n) =>
                        selected.includes(NodeSelector.readResourceID(n.id))
                    )
                    .map((n) => n.id);

                // PASS 1 // CHECK every target present in the sorted array.
                sorted.forEach((node, index, array) => {
                    const isTarget = targets.includes(node.id);
                    if (isTarget) {
                        console.log(
                            `selected ${node.id} via query params!`,
                            node
                        );
                        node.state = NodeState.enable(node.state, 'checked');
                        array[index] = node;
                    }
                });

                // IF CAPACITY is a finite value, check capacity constraints.
                if (Number.isFinite(service.checkedLimit.value)) {
                    // PASS 2 // CHECK if checked weather stations are now greater than capacity limits.
                    const checked = sorted.filter((n) =>
                        NodeState.isChecked(n.state)
                    );
                    const checkedWeatherStations = checked.filter(
                        Node.isWeatherStationNode
                    );
                    const ignored = [];

                    // PASS 3 // UNCHECK until constraint violations are resolved.
                    while (
                        checkedWeatherStations.length > 0 &&
                        checkedWeatherStations.length + ignored.length >
                            service.checkedLimit.value
                    ) {
                        // UNCHECK (except targets) UNTIL WITHIN THE LIMIT.
                        const peek =
                            checkedWeatherStations[
                                checkedWeatherStations.length - 1
                            ];
                        const isTarget = targets.includes(peek.id);
                        if (!isTarget) {
                            const unchecked = checkedWeatherStations.pop();
                            unchecked.state = NodeState.disable(
                                unchecked.state,
                                'checked'
                            );
                            const index = sorted.findIndex(
                                (n) => n.id === unchecked.id
                            );
                            sorted[index] = unchecked;
                        } else {
                            const target = checkedWeatherStations.pop();
                            ignored.push(target);
                        }
                    }
                }

                // PASS 4 // EXPAND all opened nodes (and their immediate parents) in the tree.
                const checkedLeaves = sorted
                    .filter(Node.isLeaf)
                    .filter((n) => NodeState.isChecked(n.state));
                const expandedNodes = checkedLeaves.flatMap((n) => {
                    return NodeRecord.getAncestorsWithSelf(tree.nodes, n).map(
                        (n) => n.id
                    );
                });
                const updates = sorted.map((node) => {
                    // EXPAND node if hierarchy node.
                    if (expandedNodes.includes(node.id)) {
                        node.state = NodeState.enable(node.state, 'opened');
                        const descendants = NodeRecord.getDescendantsOf(
                            tree.nodes,
                            node
                        );
                        const checkedDescendants = descendants.filter((n) =>
                            NodeState.isChecked(n.state)
                        );
                        const isEveryChildChecked = checkedDescendants
                            .filter(Node.isWeatherStationNode)
                            .every((n) => NodeState.isChecked(n.state));
                        const isSomeChildrenChecked = checkedDescendants
                            .filter(Node.isWeatherStationNode)
                            .some((n) => NodeState.isChecked(n.state));
                        node.state = NodeState.set(
                            node.state,
                            'indeterminate',
                            isSomeChildrenChecked
                        );
                        node.state = NodeState.set(
                            node.state,
                            'checked',
                            isEveryChildChecked
                        );
                    }
                    return node;
                });

                // PASS 5 // AGGREGATE into record and update tree.
                const nodes = NodeRecord.fromCollection(updates);
                tree.nodes = nodes;

                // DELETE query.
                const query = Object.assign({}, currentRoute.value.query);
                delete query.station;
                // service.router.replace({ query });
            }
        }
    };

    /**
     * Query tree from the store, if it's non-null.
     */
    const queryTree = () => {
        const storeTree = service.store.state.analysis.filters.stations.tree;
        if (!!storeTree && storeTree.config.roots?.length > 0) {
            Object.values(storeTree.nodes).forEach((node) => {
                const _target = state.tree.nodes[node?.id];
                _target.state = Object.assign(_target.state, node?.state);
                state.tree.nodes[node?.id] = _target;
            });
            // Object.assign(state.tree, Tree.override(state.tree, storeTree));
        }

        // "check all hack, if too many weather stations are checked."
        if (Number.isFinite(service.checkedLimit.value)) {
            const checked = NodeRecord.sorted(tree.nodes);
            const checkedWeatherStations = checked
                .filter(Node.isWeatherStationNode)
                .filter((n) => NodeState.isChecked(n.state));
            if (checkedWeatherStations.length > service.checkedLimit.value) {
                raiseCapacityWarning(
                    checkedWeatherStations.length,
                    service.checkedLimit.value
                );
                // TODO: Perhaps we have more complex state otherwise?
                onCheckNone();
            }
        }
    };

    /**
     * Raise a capacity warning, if necessary.
     * @param {number} count Number of resources selected.
     * @param {number} limit Total number allowed.
     */
    const raiseCapacityWarning = (count, limit) => {
        if (count >= limit) {
            // CLEAR alerts.
            state.alerts.value = [];

            // RAISE alert.
            onAlertRaised({
                id: 'at-or-over-capacity',
                content: `You may only graph ${limit} weather station datasets at a time.`,
                type: 'warning',
                dismissable: true,
            });
            return;
        }
    };

    /** @type {(e: Pick<AlertDef, 'id'> & Partial<AlertDef> & { type?: string }) => void} */
    const onAlertRaised = (e) => {
        const _alert = createAlert(e);
        pushAlert(_alert);
    };

    /**
     * Clear alert when selected.
     * @type {(e: { id: string }) => void}
     */
    const onAlertDismissed = (e) => {
        console.dir(e);
        clearAlert(e.id);
    };

    /** Debounced tree commit. */
    const commitTree = debounce(
        /**
         * Commit the current tree.
         * @param {Treeview.Tree} currentTree
         */
        (currentTree) => {
            const instance = Tree.clone(currentTree);
            service.store.commit(`analysis/setWeatherStationTree`, instance);
        },
        800,
        false
    );

    const stop = () => {
        handles.value.forEach((h) => h?.());
        handles.value = [];
    };

    const listen = () => {
        stop();
        handles.value = [
            // watch(
            //     [service.store.state.analysis.filters.weather stations],
            //     (current, previous) => {
            //         const [_filter] = current;
            //         // const _config = Config.create();
            //         // const _nodes = NodeRecord.fromCollection([]);
            //         // /** @type {Treeview.Tree} */
            //         // const _tree = defineTreeview(
            //         //     Config.create({
            //         //         ..._config,
            //         //         padding: 25,
            //         //         checkMode: 0,
            //         //         checkboxes: true,
            //         //     }),
            //         //     _nodes
            //         // );
            //         // state.tree.config = _tree.config;
            //         // state.tree.nodes = _tree.nodes;
            //     },
            //     {
            //         deep: true,
            //         flush: 'pre',
            //         immediate: true,
            //     }
            // ),
            watch(
                [tree],
                (current, previous) => {
                    console.log(`update:tree`);
                    // GET the updated tree.
                    const [_tree] = current;
                    // UPDATE the tree in the store.
                    commitTree(_tree);
                },
                {
                    deep: true,
                    flush: 'post',
                    immediate: true,
                }
            ),
        ];
    };

    // <!-- EXPOSE -->
    return {
        getButtonClickHandler,
        getTreeEventHandler,
        initializeAlerts,
        initializeTree,
        raiseCapacityWarning,
        queryWeatherStations,
        onAlertRaised,
        onAlertDismissed,
        stop,
        listen,
    };
};

// <!-- DEFINITION -->
/**
 * Define component interface.
 * @param {Object} props
 * @param {Readonly<V.Ref<number>>} [props.checkedLimit]
 * @returns {IWeatherStationFilterContext}
 */
export const useWeatherStationFilter = (props) => {
    // <!-- DEFINE -->
    const service = useService(props);
    const constants = useConstants();
    const state = useState({ service, constants });
    const properties = useProperties({ service, constants, state });
    const methods = useMethods({ service, constants, state, properties });

    // <!-- EXPOSE -->
    return {
        service,
        constants,
        state,
        properties,
        methods,
    };
};

// <!-- DEFAULT -->
export default useWeatherStationFilter;
