// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';

/**
 * Utility for formatting the graph title.
 * @param {String} metric Graph metric.
 * @param {String} [unit] Graph unit.
 * @param {Number} [count] Number of datasets loaded.
 * @param {Boolean} [isLoading]
 */
export const formatGraphTitle = (
    metric,
    unit = '',
    count = 0,
    isLoading = false
) => {
    const prefix = !!unit.length ? `${metric} (${unit}) ` : `${metric} `;
    // const suffix = !!count ? ` of ${count} Location(s)` : ``;
    const indicator = isLoading ? ` (Loading...)` : ``;
    return `${prefix}Graph${indicator}`;
};

/**
 * Get the start and end date range.
 * @param {import("@/utils/filters").IDateRangeFilter} dates
 */
export const formatGraphSubtitle = (dates) => {
    const from =
        isNil(dates) ||
        isNil(dates.start) ||
        Number.isNaN(dates.start.valueOf())
            ? null
            : new Date(dates.start);
    const to =
        isNil(dates) || isNil(dates.end) || Number.isNaN(dates.end.valueOf())
            ? null
            : new Date(dates.end);
    const prefix = !!from ? `${from.toLocaleDateString()}` : null;
    const suffix = !!to ? `${to.toLocaleDateString()}` : null;
    const tags = dates?.checked?.includes('all')
        ? ' (All Dates)'
        : dates?.checked?.includes('overlap')
        ? ' (Overlapping Dates)'
        : ' (Custom)';
    const segments = [prefix, suffix + tags].filter(
        (segment) => !!segment && segment.length > 0
    );
    return segments.join(' - ');
};

/**
 * Generate filename.
 * @param {String[]} tags
 */
export const formatGraphFilename = (tags = []) => {
    const timestamp = new Date().toISOString();
    const prefix = `ecnb`;
    const suffixes = !!tags.length ? tags : [];
    const filename = [prefix, 'export', ...suffixes, timestamp]
        .filter((s) => !!s.length)
        .map((s) => s.replace(/[^a-z0-9/[/]]/gi, '_').toLowerCase())
        .join('-');
    return filename;
};

export default {
    formatGraphTitle,
    formatGraphSubtitle,
    formatGraphFilename,
};
