// <!-- UTILITIES -->
import { createEventHook } from '@vueuse/core';

// <!-- TYPES -->
/**
 * @template [TData=any] AgGrid row node data type.
 * @typedef UseGridReadyEventReturn
 * @prop {import('@vueuse/core').EventHook<AgGrid.Events.GridReadyEvent<TData>>} gridReady `GridReadyEvent` hook that will trigger when the AgGrid instance is ready.
 * @prop {import('@vueuse/core').EventHookOn<AgGrid.Events.GridReadyEvent<TData>>} onGridReady Invoke callback that will consume the `GridReadyEvent` when the `gridReady` event is triggered.
 * @prop {import('@vueuse/core').EventHookTrigger<AgGrid.Events.GridReadyEvent<TData>>} readyGrid Trigger `gridReady` event hook.
 */

/**
 * Define event hook.
 * @template [TData=any] AgGrid row node data type.
 * @returns {UseGridReadyEventReturn<TData>}
 */
export const useGridReadyEvent = () => {
    const gridReady = createEventHook();
    return {
        gridReady,
        onGridReady: gridReady.on,
        readyGrid: gridReady.trigger,
    };
};

// <!-- DEFAULT -->
export default useGridReadyEvent;
