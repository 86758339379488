// <!-- API -->
import { computed, ref, reactive, watch } from 'vue';

// <!-- UTILITIES -->
import {
    Tree,
    Config,
    Node,
    NodeRecord,
    NodeState,
    NodeSelector,
    NodeCodes,
    NodeTypes,
} from '@/utils/tree';
import { clone, isNil } from 'lodash-es';
import debounce from 'just-debounce-it';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useECNBCache } from '@/hooks/store/useECNBCache';
import { useLocationIndex } from '@/hooks/cache/useLocationIndex';
import { useLocationHierarchyIndex } from '@/hooks/cache/useLocationHierarchyIndex';
import { useAlerts } from '@/components/alerts/hooks/useAlerts';

// <!-- EVENTS -->
import { TREEVIEW } from '@/components/sidebar/events';
import { LocationHierarchyResource } from '@/models/locations/LocationHierarchy';
import { LocationResource } from '@/models/locations/Location';
import is from '@sindresorhus/is';

// <!-- TYPES -->
/** @typedef {import('@/components/alerts/hooks/useAlerts').AlertDef} AlertDef */

/** @typedef {import('@/hooks/store/useECNBCache').ECNBCache} ECNBCache */
/** @typedef {import('@/store/types/ECNBStore').ECNBState} ECNBState */
/** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */

/** @typedef {ReturnType<useService>} ILocationFilterService */
/** @typedef {ReturnType<useState>} ILocationFilterState */
/** @typedef {ReturnType<useConstants>} ILocationFilterConstants */
/** @typedef {ReturnType<useProperties>} ILocationFilterProperties */
/** @typedef {ReturnType<useMethods>} ILocationFilterMethods */
/**
 * @typedef {Object} ILocationFilterContext
 * @property {ILocationFilterService} service
 * @property {ILocationFilterState} state
 * @property {ILocationFilterConstants} constants
 * @property {ILocationFilterProperties} properties
 * @property {ILocationFilterMethods} methods
 */

// <!-- INTERFACES -->

/**
 * @typedef {Object} ITreeButton
 * @property {string} label Display text.
 * @property {boolean} enabled Is the button currently enabled?
 * @property {Function} onButtonClick Action to invoke when the button is clicked.
 */

// <!-- ENUMS -->

const ButtonID = Object.freeze(
    /** @type {const} */ ({
        [TREEVIEW.ExpandEvent]: 'expand',
        [TREEVIEW.CollapseEvent]: 'collapse',
        [TREEVIEW.CheckAllEvent]: 'check:all',
        [TREEVIEW.CheckNoneEvent]: 'check:none',
    })
);

/** @typedef {typeof ButtonID[keyof typeof ButtonID]} ButtonID */

const ButtonLabel = Object.freeze(
    /** @type {const} */ ({
        [TREEVIEW.ExpandEvent]: 'Expand',
        [TREEVIEW.CollapseEvent]: 'Collapse',
        [TREEVIEW.CheckAllEvent]: 'Check All',
        [TREEVIEW.CheckNoneEvent]: 'Check None',
    })
);

/** @typedef {typeof ButtonLabel[keyof typeof ButtonLabel]} ButtonLabel */

// <!-- COMPOSABLES -->
/**
 * Define composable services.
 * @param {Object} props
 * @param {Readonly<V.Ref<number>>} [props.checkedLimit]
 */
const useService = (props) => {
    /** @type {Store<ECNBState>} */
    const store = useStore();

    /** @type {ECNBCache} */
    const cache = useECNBCache(store);

    /** Location cache index. */
    const locationIndex = useLocationIndex(cache);

    /** Location hierarchy cache index. */
    const hierarchyIndex = useLocationHierarchyIndex(cache);

    /** Router. */
    const router = useRouter();

    /** @type {V.ComputedRef<number>} */
    const checkedLimit = computed(() => {
        const capacity = props.checkedLimit?.value ?? Infinity;
        return capacity;
    });

    /** @type {ReturnType<useAlerts>} */
    const alerts = useAlerts();

    // RETURN services.
    return {
        store,
        router,
        cache,
        locationIndex,
        hierarchyIndex,
        checkedLimit,
        alerts,
    };
};

/** Define static constants. */
const useConstants = () => {
    // <!-- EXPOSE -->
    return {
        ButtonID,
        ButtonLabel,
    };
};

/**
 * Define reactive state.
 * @param {Pick<ILocationFilterContext, 'service' | 'constants'>} context
 */
const useState = (context) => {
    const { service } = context;
    /** @type {Treeview.Tree} */
    const tree = reactive(Tree.create());
    /** @type {V.Ref<boolean>} Are we busy? */
    const busy = ref(false);
    /** @type {V.Ref<ITreeButton[]>} Sidebar buttons. */
    const buttons = ref([]);
    /** Alerts state reference. */
    const alerts = service.alerts.state.alerts;
    /** @type {V.Ref<V.WatchStopHandle[]>} */
    const handles = ref([]);
    // RETURN state.
    return {
        tree,
        busy,
        buttons,
        alerts,
        handles,
    };
};

/**
 * Define computed properties.
 * @param {Pick<ILocationFilterContext, 'service' | 'constants' | 'state'>} context
 */
const useProperties = (context) => {
    const { service, state } = context;
    const { locationIndex, hierarchyIndex } = service;
    const { tree, buttons, busy } = state;

    /** @type {V.ComputedRef<Boolean>} */
    const isBusy = computed(() => {
        const _buttons = buttons.value ?? [];
        const _isBusy = busy.value === true;
        const _isMissingButtons = _buttons.length <= 0;
        const _isFetchingLocations = locationIndex.isFetching.value === true;
        const _isFetchingHierarchies = hierarchyIndex.isFetching.value === true;
        return (
            _isMissingButtons ||
            _isBusy ||
            _isFetchingLocations ||
            _isFetchingHierarchies
        );
    });

    /** @type {V.ComputedRef<LocationResource[]>} */
    const availableLocations = computed(() => {
        const _locations = locationIndex.locations.value ?? [];
        return _locations;
    });

    /** @type {V.ComputedRef<LocationHierarchyResource[]>} */
    const availableHierarchies = computed(() => {
        const _hierarchies = hierarchyIndex.hierarchies.value ?? [];
        return _hierarchies;
    });

    /** @type {V.ComputedRef<Boolean>} */
    const isEmpty = computed(() => {
        const _l = availableLocations.value ?? [];
        const _h = availableHierarchies.value ?? [];
        return _l.length === 0 && _h.length === 0;
    });

    // <!-- EXPOSE -->
    return {
        availableHierarchies,
        availableLocations,
        isBusy,
        isEmpty,
    };
};

/**
 * Define component methods.
 * @param {Pick<ILocationFilterContext, 'service' | 'constants' | 'state' | 'properties'>} context
 */
const useMethods = (context) => {
    // DESTRUCTURE context.
    const { service, constants, state } = context;
    const { store, locationIndex, hierarchyIndex, alerts } = service;
    const { ButtonID, ButtonLabel } = constants;
    const { handles, config, nodes, tree, busy, buttons } = state;
    const { pushAlert, clearAlert, createAlert } = alerts.methods;

    /** Expand all nodes. */
    const onExpandAll = () => {
        // CLEAR alerts.
        initializeAlerts();

        // GET node record.
        const nodes = NodeRecord.clone(tree.nodes);

        // UPDATE state for all records.
        Object.keys(nodes).forEach((key) => {
            const state = NodeState.enable(nodes[key].state, 'opened');
            nodes[key].state = state;
        });

        // UPDATE the state.
        tree.nodes = nodes;
    };

    /** Collapse all nodes. */
    const onCollapseAll = () => {
        // CLEAR alerts.
        initializeAlerts();

        // GET node record.
        const nodes = NodeRecord.clone(tree.nodes);

        // UPDATE state for all records.
        Object.keys(nodes).forEach((key) => {
            const state = NodeState.disable(nodes[key].state, 'opened');
            nodes[key].state = state;
        });

        // UPDATE the state.
        tree.nodes = nodes;
    };

    /** Uncheck over-capacity nodes. */
    const onUncheckExcess = (capacity = Infinity) => {
        if (Number.isNaN(capacity) || !Number.isFinite(capacity)) {
            // Do nothing if capacity is considered 'infinite'.
            return;
        }

        // GET node record.
        const nodes = NodeRecord.clone(tree.nodes);
        const sortedNodes = NodeRecord.sorted(nodes);
        const sortedLocationNodes = sortedNodes.filter(Node.isLocationNode);
        const checkedLocationNodes = sortedLocationNodes.filter((n) =>
            NodeState.isChecked(n.state)
        );
        if (checkedLocationNodes.length <= capacity) {
            // Do nothing if less checked nodes than capacity.
            return;
        }

        // GET the excess location nodes.
        const excessLocationNodes = checkedLocationNodes.slice(capacity);
        excessLocationNodes.forEach((node) => {
            nodes[node.id].state = NodeState.override(nodes[node.id].state, {
                indeterminate: false,
                checked: false,
            });
        });

        // GET all hierarchy nodes in the tree.
        const hierarchyNodes = sortedNodes.filter(Node.isNotLocationNode);

        // UNCHECK empty hierarchy nodes.
        hierarchyNodes.forEach((hierarchy) => {
            // GET the hierarchy node from the stateful record.
            const node = nodes[hierarchy.id];

            // FIND all checked location leaves for this hierarchy node.
            const checkedLocations = NodeRecord.getLeavesOf(nodes, node)
                .filter(Node.isLocationNode)
                .filter((n) => NodeState.isChecked(n.state));

            // COUNT checked leaves.
            const isHierarchyEmpty = checkedLocations.length <= 0;

            // WHEN hierarchy node has no checked locations
            // UNCHECK it in the tree.
            if (isHierarchyEmpty) {
                nodes[node.id].state = NodeState.override(
                    nodes[node.id].state,
                    {
                        indeterminate: false,
                        checked: false,
                    }
                );
            }
        });

        // UPDATE the state.
        tree.nodes = nodes;
    };

    /** Uncheck all nodes. */
    const onCheckNone = () => {
        // CLEAR alerts.
        initializeAlerts();

        // GET node record.
        const nodes = NodeRecord.clone(tree.nodes);

        // UPDATE state for all records.
        Object.keys(nodes).forEach((key) => {
            nodes[key].state = NodeState.override(nodes[key].state, {
                indeterminate: false,
                checked: false,
            });
        });

        // UPDATE the state.
        tree.nodes = nodes;
    };

    /**
     * Check all location nodes.
     */
    const onCheckAllLocationNodes = () => {
        // CLEAR alerts.
        initializeAlerts();

        // GET node record.
        const nodes = NodeRecord.clone(tree.nodes);

        // UNCHECK all nodes.
        Object.keys(nodes).forEach((key) => {
            nodes[key].state = NodeState.override(nodes[key].state, {
                indeterminate: false,
                checked: false,
            });
        });

        // GET sorted nodes for the node subtrees.
        const sortedNodes = NodeRecord.sorted(nodes);
        // const sortedNodes = Object.values(nodes).sort((a, b) => {
        //     const ancestorsA = NodeRecord.getAncestorsWithSelf(nodes, a);
        //     const ancestorsB = NodeRecord.getAncestorsWithSelf(nodes, b);
        //     const valuesA = ancestorsA.map((n) => n.text.trim()).join('/');
        //     const valuesB = ancestorsB.map((n) => n.text.trim()).join('/');
        //     return valuesA.localeCompare(valuesB);
        // });

        // GET sorted locations for the tree.
        const sortedLocations = sortedNodes.filter(Node.isLocationNode);

        // CHECK if limit is finite.
        if (Number.isFinite(service.checkedLimit.value)) {
            // RAISE alert.
            raiseCapacityWarning(
                sortedLocations.length,
                service.checkedLimit.value
            );
        }

        // CHECK locations within the limit.
        const slicedLocations = sortedLocations.slice(
            0,
            service.checkedLimit.value
        );
        slicedLocations.forEach((l) => {
            nodes[l.id].state = NodeState.enable(nodes[l.id].state, 'checked');
        });

        // UPDATE indeterminate states.
        const sortedHierarchies = sortedNodes.filter(Node.isHierarchyNode);
        sortedHierarchies.forEach((h) => {
            const locations = NodeRecord.getDescendantsOf(nodes, h).filter(
                Node.isLocationNode
            );
            const isEveryLocationChecked = locations.every((l) =>
                NodeState.isChecked(l.state)
            );
            const areSomeLocationsChecked =
                isEveryLocationChecked ||
                locations.some((l) => NodeState.isChecked(l.state));
            nodes[h.id].state = NodeState.override(nodes[h.id].state, {
                indeterminate: areSomeLocationsChecked,
                checked: isEveryLocationChecked,
            });
        });

        // UPDATE the state.
        tree.nodes = nodes;
    };

    /**
     * Get the button click handler.
     * @param {string} id
     * @returns {(e: MouseEvent) => void}
     */
    const getButtonClickHandler = (id) => {
        const logButtonClick = (e) => console.log(`click:${id}`, e);
        switch (id) {
            case TREEVIEW.ExpandEvent:
                return (e) => {
                    logButtonClick(e);
                    onExpandAll();
                };
            case TREEVIEW.CollapseEvent:
                return (e) => {
                    logButtonClick(e);
                    onCollapseAll();
                };
            case TREEVIEW.CheckAllEvent:
                return (e) => {
                    logButtonClick(e);
                    onCheckAllLocationNodes();
                };
            case TREEVIEW.CheckNoneEvent:
                return (e) => {
                    logButtonClick(e);
                    onCheckNone();
                };
            default:
                return logButtonClick;
        }
    };

    /**
     * Check a single node.
     * @param {Treeview.Node} e Node being checked.
     */
    const onNodeChecked = (e) => {
        // CLEAR alerts.
        initializeAlerts();

        // GET sorted nodes.
        const _tree = NodeRecord.clone(tree.nodes);
        const sorted = Object.values(_tree).sort((a, b) => {
            const _a = NodeRecord.getPath(_tree, a.id);
            const _b = NodeRecord.getPath(_tree, b.id);
            if (a.id === 'h0' || (a.id.startsWith('l') && a.parent === 'h0')) {
                _a.unshift('Z999');
            }
            if (b.id === 'h0' || (b.id.startsWith('l') && b.parent === 'h0')) {
                _b.unshift('Z999');
            }
            return _a.join('/').localeCompare(_b.join('/'));
        });

        // GET checked location nodes.
        const checked = sorted.filter((n) => NodeState.isChecked(n.state));
        const checkedLocations = checked.filter(Node.isLocationNode);

        // CHECK if limit is finite.
        if (Number.isFinite(service.checkedLimit.value)) {
            // RAISE alert.
            raiseCapacityWarning(
                checkedLocations.length,
                service.checkedLimit.value
            );

            // IF CAPACITY IS EXCEEDED, UNCHECK THE PREVIOUS NODE AND ALL ITS CHILDREN.
            if (checkedLocations.length > service.checkedLimit.value) {
                // CALCULATE overflow.
                const overflow =
                    checkedLocations.length - service.checkedLimit.value;

                // GET the target node.
                const target = e?.id ? _tree[e.id] : null;

                // UNCHECK the target node and all its location descendants.
                const unchecked = NodeRecord.getDescendantsOf(_tree, target)
                    .filter(Node.isLocationNode)
                    .map((n) => n.id)
                    .slice(-1 * overflow);

                // ENSURE target is included in uncheck flow.
                unchecked.unshift(target.id);

                // FILTER checked for unaffected nodes.
                const resolved = checked.map((node) => {
                    // DISABLE checked state for the blocked nodes.
                    const isUnchecked = unchecked.includes(node.id);
                    if (isUnchecked) {
                        node.state = NodeState.disable(node.state, 'checked');
                    }
                    return node;
                });

                // CHANGE resolved nodes in the tree structure.
                const updated = sorted.map((node) => {
                    // RETURN updated or self.
                    const update = resolved.find((n) => n.id === node.id);
                    return update ?? node;
                });

                // CREATE NODE RECORD.
                const nodes = NodeRecord.fromCollection(updated);

                // UPDATE indeterminate states.
                const updatedHierarchies = updated.filter(Node.isHierarchyNode);
                updatedHierarchies.forEach((h) => {
                    const locations = NodeRecord.getDescendantsOf(
                        nodes,
                        h
                    ).filter(Node.isLocationNode);
                    const isEveryLocationChecked = locations.every((l) =>
                        NodeState.isChecked(l.state)
                    );
                    const areSomeLocationsChecked =
                        isEveryLocationChecked ||
                        locations.some((l) => NodeState.isChecked(l.state));
                    nodes[h.id].state = NodeState.override(nodes[h.id].state, {
                        indeterminate: areSomeLocationsChecked,
                        checked: isEveryLocationChecked,
                    });
                });

                // UPDATE the state.
                tree.nodes = nodes;
            } else {
                // DO NOTHING, since there's no limitation issue.
                return;
            }
        }
    };

    /**
     * Get the button click handler.
     * @param {string} id
     * @returns {(e: Treeview.Node) => void}
     */
    const getTreeEventHandler = (id) => {
        const logTreeEvent = (e) => console.log(`tree:${id}`, e);
        switch (id) {
            case TREEVIEW.ExpandEvent:
                return (e) => {
                    logTreeEvent(e);
                    // onExpandAll();
                };
            case TREEVIEW.CollapseEvent:
                return (e) => {
                    logTreeEvent(e);
                    // onCollapseAll();
                };
            case TREEVIEW.NodeCheckedEvent:
                return (e) => {
                    logTreeEvent(e);
                    onNodeChecked(e);
                };
            case TREEVIEW.CheckNoneEvent:
                return (e) => {
                    logTreeEvent(e);
                    // onCheckNone();
                };
            default:
                return logTreeEvent;
        }
    };

    /**
     * Get all the locations in the hierarchy's descendant chain.
     * @param {LocationHierarchyResource} hierarchy
     * @returns {LocationResource[]}
     */
    const getLocationDescendants = (hierarchy) => {
        const _children = hierarchy?.children ?? [];
        const _directLocations = hierarchy.locations;
        if (_children.length > 0) {
            const _nestedLocations = _children.flatMap((h) =>
                getLocationDescendants(h)
            );
            return [..._directLocations, ..._nestedLocations];
        } else {
            return _directLocations;
        }
    };

    /**
     * Get all the location nodes in the hierarchy root's descendant chain.
     * @param {Treeview.Node} root
     * @param {Treeview.NodeRecord} nodes (Schema)
     * @returns {Treeview.Node[]}
     */
    const getLocationNodeDescendants = (root, nodes) => {
        const _children = root?.children ?? [];
        const _directLocations = _children
            .filter((id) =>
                NodeSelector.isSpecificFormat(id, NodeTypes.Location)
            )
            .map((id) => nodes[id]);
        if (_children.length > 0) {
            const _hChildren = _children.filter((id) =>
                NodeSelector.isSpecificFormat(id, NodeTypes.Hierarchy)
            );
            const _hNodes = _hChildren
                .map((id) => nodes[id])
                .filter((n) => !isNil(n));
            const _nestedLocations = _hNodes.flatMap((n) =>
                getLocationNodeDescendants(n, nodes)
            );
            return [..._directLocations, ..._nestedLocations];
        }
        return _directLocations;
    };

    /**
     * Get all the immediate children for a hierarchy node.
     * @param {LocationHierarchyResource} hierarchy
     * @returns {string[]}
     */
    const getHierarchyNodeChildren = (hierarchy) => {
        // SORT hierarchy children by name and MAP to their IDs.
        const subHierarchies = clone(hierarchy.children)
            .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
            .map((child) =>
                NodeSelector.format(NodeCodes.Hierarchy, `${child.id}`)
            );
        // SORT location children by name and MAP to their IDs.
        const subLocations = clone(hierarchy.locations)
            .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
            .map((child) =>
                NodeSelector.format(NodeCodes.Location, `${child.id}`)
            );
        // RETURN sorted collection.
        return [...subHierarchies, ...subLocations];
    };

    /** Init alerts. */
    const initializeAlerts = () => {
        // CLEAR all alerts.
        state.alerts.value = [];
    };

    /** Init buttons. */
    const initializeButtons = () => {
        // DEFINE button details.
        const _buttons = Object.values(ButtonID).map((id) => {
            /** @type {ITreeButton} */
            const button = {
                label: ButtonLabel[id] ?? '<Missing>',
                enabled: true, // TODO: Dynamic toggle?
                onButtonClick: getButtonClickHandler(id),
            };
            return button;
        });
        // REPLACE existing buttons.
        buttons.value = _buttons;
    };

    /** Initialize the configuration and nodes of the treeview. */
    const initializeTree = () => {
        /**
         * Filter function to test if location has an assigned hierarchy parent.
         * @type {(location: LocationResource) => boolean}
         */
        const hasAssignedHierarchyParent = (location) => {
            return (
                typeof location.hierarchyId === 'number' &&
                Number.isInteger(location.hierarchyId)
            );
        };

        /**
         * Compare two resources with name properties.
         * @param {{ id?: string | number, name: string }} a
         * @param {{ id?: string | number, name: string }} b
         * @returns {number}
         */
        const compareByResourceName = (a, b) => {
            const _a = `${a?.name.trim() ?? ''}`;
            const _b = `${b?.name.trim() ?? ''}`;
            return _a.localeCompare(_b);
        };

        /** @type {(resource: {id: string | number}) => string} */
        const formatHierarchyID = ({ id }) => NodeSelector.format('h', id);

        /** @type {(resource: {id: string | number}) => string} */
        const formatLocationID = ({ id }) => NodeSelector.format('l', id);

        /** @type {() => Treeview.Node} */
        const createUnassignedHierarchyNode = () => {
            const id = `h0`;
            const parent = null;
            const children = [];
            const text = 'Unassigned';
            const state = NodeState.create({});
            const hnode = Node.create({
                id,
                text,
                state,
                parent,
                children,
                type: NodeTypes.Hierarchy,
            });
            // RETURN the created node.
            return hnode;
        };

        // DEFINE initial tree state.
        const _tree = Tree.create({ config: { roots: [], leaves: [] } });

        // QUERY subtree node data.
        const _roots = queryRoots();
        const _locations = queryLocations();
        const _unassigned = queryUnassigned();

        // COMPUTE assigned locations.
        const _assigned = _locations.filter(
            (l) =>
                hasAssignedHierarchyParent(l) &&
                !_unassigned.some((u) => u.id === l.id)
        );

        // COMPUTE conditionals.
        const hasOneOrMoreSubtrees = _roots.length > 0;
        const hasOneOrMoreLocations = _locations.length > 0;
        const hasOneOrMoreUnassignedLocations =
            hasOneOrMoreLocations && _unassigned.length > 0;
        const hasOneOrMoreAssignedLocations =
            hasOneOrMoreLocations && _assigned.length > 0;

        // ASSIGN hierarchy roots.
        if (hasOneOrMoreSubtrees) {
            // SORT top-level roots.
            const sortedRoots = _roots.sort(compareByResourceName);
            // MAP roots into ids.
            const sortedRootIDs = sortedRoots.map(formatHierarchyID);
            // UNROLL subtrees for each root, sorted by display text.
            const sortedHierarchyResources = sortedRoots.flatMap((r) => {
                const subtree = r?.unroll() ?? [];
                return subtree.sort(compareByResourceName);
            });

            // DEFINE hierarchy nodes for each subtree resource.
            const hNodes = sortedHierarchyResources.map((resource) => {
                const id = formatHierarchyID(resource);
                const parent = !!resource.parentId
                    ? formatHierarchyID({ id: resource.parentId })
                    : null;

                const hierarchies = resource.children.map((r) => clone(r));
                const sortedHierarchies = hierarchies.sort(
                    compareByResourceName
                );
                const childHierarchyNodeIDs =
                    sortedHierarchies.map(formatHierarchyID);

                const locations = resource.locations.map((r) => clone(r));
                const sortedLocations = locations.sort(compareByResourceName);
                const childLocationNodeIDs =
                    sortedLocations.map(formatLocationID);

                const children =
                    childHierarchyNodeIDs.concat(childLocationNodeIDs);
                const text = resource.name ?? 'Untitled';
                const state = NodeState.create({});
                const hnode = Node.create({
                    id,
                    text,
                    state,
                    parent,
                    children,
                    type: NodeTypes.Hierarchy,
                });

                // RETURN the created node.
                return hnode;
            });

            // FILTER hierarchy nodes without locations.
            const hNodesWithLocations = hNodes.filter((hnode) => {
                const id = Number(NodeSelector.readResourceID(hnode.id));
                const resource = sortedHierarchyResources.find(
                    (h) => h.id === id
                );
                const locations = getLocationDescendants(resource);
                return locations.length > 0;
            });

            // ASSIGN root ids.
            _tree.config.roots = sortedRootIDs;

            // ASSIGN hierarchy nodes.
            hNodesWithLocations.forEach((hnode) => {
                _tree.nodes[hnode.id] = hnode;
            });
        }

        // ASSIGN assigned location nodes.
        if (hasOneOrMoreAssignedLocations) {
            // SORT assigned locations by resource name.
            const sortedLocationResources = _assigned.sort(
                compareByResourceName
            );
            // DEFINE location nodes.
            const lNodes = sortedLocationResources.map((resource) => {
                const id = formatLocationID(resource);
                const parent = hasAssignedHierarchyParent(resource)
                    ? formatHierarchyID({ id: resource.hierarchyId })
                    : `h0`;
                const children = [];
                const text = resource.name ?? '[]';
                const state = NodeState.create({});
                const lnode = Node.create({
                    id,
                    text,
                    state,
                    parent,
                    children,
                    type: NodeTypes.Location,
                });
                // RETURN the created node.
                return lnode;
            });
            // ASSIGN location nodes.
            lNodes.forEach((lnode) => {
                _tree.config.leaves = [..._tree.config.leaves, lnode.id];
                _tree.nodes[lnode.id] = lnode;
            });
        }

        // ASSIGN unassigned locations.
        if (hasOneOrMoreUnassignedLocations) {
            // DEFINE unassigned hierarchy node.
            const unassigned = createUnassignedHierarchyNode();
            // SORT unassigned locations by resource name.
            const sortedLocationResources = _unassigned.sort(
                compareByResourceName
            );
            // ASSIGN unassigned locations to parent hierarchy node.
            unassigned.children = sortedLocationResources.map(formatLocationID);
            // DEFINE location nodes.
            const lNodes = sortedLocationResources.map((resource) => {
                const id = formatLocationID(resource);
                const parent = `h0`;
                const children = [];
                const text = resource.name ?? '[]';
                const state = NodeState.create({});
                const lnode = Node.create({
                    id,
                    text,
                    state,
                    parent,
                    children,
                    type: NodeTypes.Location,
                });
                // RETURN the created node.
                return lnode;
            });
            // ASSIGN configuration root.
            _tree.config.roots.push(unassigned.id);
            // ASSIGN node.
            _tree.nodes[unassigned.id] = unassigned;
            // ASSIGN location nodes.
            lNodes.forEach((lnode) => {
                _tree.config.leaves = [..._tree.config.leaves, lnode.id];
                _tree.nodes[lnode.id] = lnode;
            });
        }

        // FILTER configuration roots that should not exist in the tree.
        _tree.config.roots = _tree.config.roots.filter((root) => {
            const isValidID = is.nonEmptyStringAndNotWhitespace(root);
            const isValidRoot = root in _tree.nodes;
            return isValidID && isValidRoot;
        });

        // SAVE tree to local state.
        Object.assign(state.tree, _tree);

        // QUERY tree form the Vuex store.
        queryTree();

        // SAVE _tree to the Vuex store.
        commitTree(state.tree);

        // QUERY uri path.
        queryCurrentRouteParams();

        // SAVE _tree to the Vuex store.
        commitTree(state.tree);
    };

    /**
     * Get the latest location hierarchies.
     * @returns {LocationHierarchyResource[]}
     */
    const queryRoots = () => {
        const _roots = clone(service.hierarchyIndex.hierarchies.value);
        return _roots;
    };

    /**
     * Get the latest locations.
     * @returns {LocationResource[]}
     */
    const queryLocations = () => {
        const _locations = clone(service.locationIndex.locations.value);
        return _locations;
    };

    /**
     * Get the latest unassigned locations.
     * @returns {LocationResource[]}
     */
    const queryUnassigned = () => {
        const _unassigned = clone(service.hierarchyIndex.unassigned.value);
        const _locations = queryLocations();
        return _locations.filter((loc) => _unassigned.has(loc.id));
    };

    /**
     * Query current route path.
     */
    const queryCurrentRouteParams = () => {
        const currentRoute = service.router.currentRoute;
        if (['Home', 'Analysis'].includes(String(currentRoute.value.name))) {
            if ('location' in currentRoute.value.query) {
                // GET the selected location id.
                const qs = currentRoute.value.query['location'];
                const selected = Array.isArray(qs) ? qs : [qs];

                // GET the selected nodes, if they exist.
                const sorted = NodeRecord.sorted(tree.nodes);
                const targets = sorted
                    .filter(Node.isLocationNode)
                    .filter((n) =>
                        selected.includes(NodeSelector.readResourceID(n.id))
                    )
                    .map((n) => n.id);

                // PASS 1 // CHECK every target present in the sorted array.
                sorted.forEach((node, index, array) => {
                    const isTarget = targets.includes(node.id);
                    if (isTarget) {
                        console.log(
                            `selected ${node.id} via query params!`,
                            node
                        );
                        node.state = NodeState.enable(node.state, 'checked');
                        array[index] = node;
                    }
                });

                // IF CAPACITY is a finite value, check capacity constraints.
                if (Number.isFinite(service.checkedLimit.value)) {
                    // PASS 2 // CHECK if checked locations are now greater than capacity limits.
                    const checked = sorted.filter((n) =>
                        NodeState.isChecked(n.state)
                    );
                    const checkedLocations = checked.filter(
                        Node.isLocationNode
                    );
                    const ignored = [];

                    // PASS 3 // UNCHECK until constraint violations are resolved.
                    while (
                        checkedLocations.length > 0 &&
                        checkedLocations.length + ignored.length >
                            service.checkedLimit.value
                    ) {
                        // UNCHECK (except targets) UNTIL WITHIN THE LIMIT.
                        const peek =
                            checkedLocations[checkedLocations.length - 1];
                        const isTarget = targets.includes(peek.id);
                        if (!isTarget) {
                            const unchecked = checkedLocations.pop();
                            unchecked.state = NodeState.disable(
                                unchecked.state,
                                'checked'
                            );
                            const index = sorted.findIndex(
                                (n) => n.id === unchecked.id
                            );
                            sorted[index] = unchecked;
                        } else {
                            const target = checkedLocations.pop();
                            ignored.push(target);
                        }
                    }
                }

                // PASS 4 // EXPAND all opened nodes (and their immediate parents) in the tree.
                const checkedLeaves = sorted
                    .filter(Node.isLeaf)
                    .filter((n) => NodeState.isChecked(n.state));
                const expandedNodes = checkedLeaves.flatMap((n) => {
                    return NodeRecord.getAncestorsWithSelf(tree.nodes, n).map(
                        (n) => n.id
                    );
                });
                const updates = sorted.map((node) => {
                    // EXPAND node if hierarchy node.
                    if (expandedNodes.includes(node.id)) {
                        node.state = NodeState.enable(node.state, 'opened');
                        const descendants = NodeRecord.getDescendantsOf(
                            tree.nodes,
                            node
                        );
                        const checkedDescendants = descendants.filter((n) =>
                            NodeState.isChecked(n.state)
                        );
                        const isEveryChildChecked = checkedDescendants
                            .filter(Node.isLocationNode)
                            .every((n) => NodeState.isChecked(n.state));
                        const isSomeChildrenChecked = checkedDescendants
                            .filter(Node.isLocationNode)
                            .some((n) => NodeState.isChecked(n.state));
                        node.state = NodeState.set(
                            node.state,
                            'indeterminate',
                            isSomeChildrenChecked
                        );
                        node.state = NodeState.set(
                            node.state,
                            'checked',
                            isEveryChildChecked
                        );
                    }
                    return node;
                });

                // PASS 5 // AGGREGATE into record and update tree.
                const nodes = NodeRecord.fromCollection(updates);
                tree.nodes = nodes;

                // DELETE query.
                const query = Object.assign({}, currentRoute.value.query);
                delete query.location;
                // service.router.replace({ query });
            }
        }
    };

    /**
     * Query tree from the store, if it's non-null.
     */
    const queryTree = () => {
        const storeTree = service.store.state.analysis.filters.locations.tree;
        if (!!storeTree && storeTree.config.roots?.length > 0) {
            Object.values(storeTree.nodes).forEach((node) => {
                const _target = state.tree.nodes[node?.id];
                _target.state = Object.assign(_target.state, node?.state);
                state.tree.nodes[node?.id] = _target;
            });
            // Object.assign(state.tree, Tree.override(state.tree, storeTree));
        }

        // "check all hack, if too many locations are checked."
        if (Number.isFinite(service.checkedLimit.value)) {
            const checked = NodeRecord.sorted(tree.nodes);
            const checkedLocations = checked
                .filter(Node.isLocationNode)
                .filter((n) => NodeState.isChecked(n.state));
            if (checkedLocations.length > service.checkedLimit.value) {
                raiseCapacityWarning(
                    checkedLocations.length,
                    service.checkedLimit.value
                );
                // TODO: Perhaps we have more complex state otherwise?
                onUncheckExcess(service.checkedLimit.value);
                // debugger;
            }
        }
    };

    /**
     * Raise a capacity warning, if necessary.
     * @param {number} count Number of resources selected.
     * @param {number} limit Total number allowed.
     */
    const raiseCapacityWarning = (count, limit) => {
        if (count >= limit) {
            // CLEAR alerts.
            state.alerts.value = [];

            // RAISE alert.
            onAlertRaised({
                id: 'at-or-over-capacity',
                content: `You may only graph ${limit} location datasets at a time.`,
                type: 'warning',
                dismissable: true,
            });
            return;
        }
    };

    /** @type {(e: Pick<AlertDef, 'id'> & Partial<AlertDef> & { type?: string }) => void} */
    const onAlertRaised = (e) => {
        const _alert = createAlert(e);
        pushAlert(_alert);
    };

    /**
     * Clear alert when selected.
     * @type {(e: { id: string }) => void}
     */
    const onAlertDismissed = (e) => {
        console.dir(e);
        clearAlert(e.id);
    };

    /** Debounced tree commit. */
    const commitTree = debounce(
        /**
         * Commit the current tree.
         * @param {Treeview.Tree} currentTree
         */
        (currentTree) => {
            const instance = Tree.clone(currentTree);
            service.store.commit(`analysis/setLocationTree`, instance);
        },
        800,
        false
    );

    const stop = () => {
        handles.value.forEach((h) => h?.());
        handles.value = [];
    };

    const listen = () => {
        stop();
        handles.value = [
            // watch(
            //     [service.store.state.analysis.filters.locations],
            //     (current, previous) => {
            //         const [_filter] = current;
            //         // const _config = Config.create();
            //         // const _nodes = NodeRecord.fromCollection([]);
            //         // /** @type {Treeview.Tree} */
            //         // const _tree = defineTreeview(
            //         //     Config.create({
            //         //         ..._config,
            //         //         padding: 25,
            //         //         checkMode: 0,
            //         //         checkboxes: true,
            //         //     }),
            //         //     _nodes
            //         // );
            //         // state.tree.config = _tree.config;
            //         // state.tree.nodes = _tree.nodes;
            //     },
            //     {
            //         deep: true,
            //         flush: 'pre',
            //         immediate: true,
            //     }
            // ),
            watch(
                [tree],
                (current, previous) => {
                    console.log(`update:tree`);
                    // GET the updated tree.
                    const [_tree] = current;
                    // UPDATE the tree in the store.
                    commitTree(_tree);
                },
                {
                    deep: true,
                    flush: 'post',
                    immediate: true,
                }
            ),
        ];
    };

    // <!-- EXPOSE -->
    return {
        getButtonClickHandler,
        getTreeEventHandler,
        initializeAlerts,
        initializeButtons,
        initializeTree,
        raiseCapacityWarning,
        queryRoots,
        queryLocations,
        queryUnassigned,
        onAlertRaised,
        onAlertDismissed,
        stop,
        listen,
    };
};

// <!-- DEFINITION -->
/**
 * Define component interface.
 * @param {Object} props
 * @param {Readonly<V.Ref<number>>} [props.checkedLimit]
 * @returns {ILocationFilterContext}
 */
export const useLocationFilter = (props) => {
    // <!-- DEFINE -->
    const service = useService(props);
    const constants = useConstants();
    const state = useState({ service, constants });
    const properties = useProperties({ service, constants, state });
    const methods = useMethods({ service, constants, state, properties });

    // <!-- EXPOSE -->
    return {
        service,
        constants,
        state,
        properties,
        methods,
    };
};

// <!-- DEFAULT -->
export default useLocationFilter;
