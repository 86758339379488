// Composables used for various compare metrics related content.

// <!-- API -->
import { watch } from 'vue';

// <!-- UTILITIES -->
import is from '@sindresorhus/is';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import { useNARAStandardsGrid, useNARAStandardsQuery } from './grid';
import { LocationResource } from '@/models/locations/Location';
import { computedEager } from '@vueuse/core';

// <!-- TYPES -->

/** @typedef {import('./grid/useNARAStandardsGrid').NARAStandardsRowData} NARAStandardsRowData */

/**
 * @typedef UseNARAStandardsOptions
 * @prop {import('vuex').Store<import('@/store/types/ECNBStore').ECNBState>} [store] Track an existing store reference, if it was already provisioned.
 * @prop {import('@tanstack/vue-query').QueryClient} [queryClient] Track an existing store reference, if it was already provisioned.
 * @prop {NARAStandardsRowData[] | V.Ref<NARAStandardsRowData[]>} [data] Possibly reactive initial data.
 * @prop {import('@/hooks/grid/useAgGridPagination').UseAgGridPaginationOptions} [pagination]
 * @prop {string | V.Ref<string>} [domLayout] Possibly reactive value controlling DOM layout of the grid.
 */

/**
 * @typedef UseNARAStandardsReturn
 * @prop {import('./grid/useNARAStandardsQuery').UseNARAStandardsQueryReturn} query
 * @prop {import('./grid/useNARAStandardsGrid').UseNARAStandardsGridReturn} grid
 */

/**
 * Define the composable.
 * @param {UseNARAStandardsOptions} [props]
 * @return {UseNARAStandardsReturn}
 */
export const useNARAStandards = (props = {}) => {
    // DEFINE composables.
    const componentQueries = useNARAStandardsQuery(props);
    const componentGrid = useNARAStandardsGrid(props);

    // DESTRUCTURE required properties.
    const { data, isSelectionEmpty } = componentQueries;
    const { setRowData } = componentGrid;

    // SERVICES

    // Used only for resolving cached location information.
    const store = props?.store ?? useStore();

    // HELPER METHOD

    /**
     * Get the loaded location information from the store.
     *
     * @param {integer|string} id
     * @returns {LocationResource}
     */
    const resolveLocationResource = (id) => {
        return store.state.cache.locations.index.get(Number(id));
    };

    /**
     * Resolve the window start date.
     *
     * @param {IDate} [filterStartDate]
     * @param {IDate} [dataStartDate]
     */
    const resolveWindowStartDate = (filterStartDate, dataStartDate) => {
        if (
            is.nullOrUndefined(filterStartDate) &&
            is.nullOrUndefined(dataStartDate)
        ) {
            return null;
        }

        const filter_time = filterStartDate?.valueOf() ?? Number.NaN;
        const data_time = dataStartDate?.valueOf() ?? Number.NaN;
        const time = Number.isNaN(filter_time)
            ? data_time
            : Math.max(filter_time, data_time);
        const date = Number.isNaN(time) ? null : new Date(time);
        return date?.toLocaleDateString('en-ca');
    };

    /**
     * Resolve the window end date.
     *
     * @param {IDate} [filterEndDate]
     * @param {IDate} [dataEndDate]
     */
    const resolveWindowEndDate = (filterEndDate, dataEndDate) => {
        if (
            is.nullOrUndefined(filterEndDate) &&
            is.nullOrUndefined(dataEndDate)
        ) {
            return null;
        }

        const filter_time = filterEndDate?.valueOf() ?? Number.NaN;
        const data_time = dataEndDate?.valueOf() ?? Number.NaN;
        const time = Number.isNaN(filter_time)
            ? data_time
            : Math.min(filter_time, data_time);
        const date = Number.isNaN(time) ? null : new Date(time);
        return date?.toLocaleDateString('en-ca');
    };

    /**
     * Transform resource into a row data instance.
     *
     * @param {import('./grid/useNARAStandardsQuery').LocationDistributionsResource} resource
     * @returns {import('./grid/useNARAStandardsGrid').NARAStandardsRowData}
     */
    const transformResourceToRowData = (resource) => {
        if (!resource.location) {
            return null;
        }
        // RESOLVE the loaded location information.
        const location = resolveLocationResource(resource.location.id);
        // FORMAT the output.
        return {
            id: resource.location.id,
            name: location?.name ?? resource.location.name,
            path: location?.path ?? resource.location.path,
            label: location?.label ?? resource.location.label,
            // path: resource.location.path,
            standard: resource.standard?.name,
            min_temp: resource.standard?.min_temp,
            max_temp: resource.standard?.max_temp,
            min_rh: resource.standard?.min_rh,
            max_rh: resource.standard?.max_rh,
            start_date: resolveWindowStartDate(
                new Date(resource.metrics?.filter_start),
                new Date(resource.location?.min_date)
            ),
            end_date: resolveWindowEndDate(
                new Date(resource.metrics?.filter_end),
                new Date(resource.location?.max_date)
            ),
            ...resource.metrics,
        };
    };

    // STATE

    // DEFINE computed metrics computation.
    const dataWithComputedMetrics = computedEager(() => {
        return data.value.map(transformResourceToRowData);
    });

    // WATCHERS

    watch(
        dataWithComputedMetrics,
        (current, previous) => {
            console.log(`sn::debug::update`, { current, previous });
            if (!!isSelectionEmpty.value) {
                // If no selection is present, assign nothing.
                setRowData([]);
            } else {
                // If selection is present, use current response data.
                setRowData(current);
            }
        },
        {
            deep: false,
            immediate: true,
        }
    );

    // EXPOSE

    return {
        grid: componentGrid,
        query: componentQueries,
    };
};
