// <!-- API -->
import { ref, computed } from 'vue';
import {
    createEventHook,
    computedEager,
    resolveRef,
    refDefault,
    promiseTimeout,
} from '@vueuse/core';

// <!-- UTILITIES -->
import is from '@sindresorhus/is';

// <!-- COMPOSABLES -->
import { Store, useStore } from 'vuex';
import { useOnline } from '@vueuse/core';

// <!-- MODELS -->
import { ECNBState } from '@/store/types/ECNBStore';
import { Node, NodeRecord, NodeSelector, NodeState } from '@/utils/tree';
import { ReportFileName } from './ReportFileName';

// <!-- REQUESTS -->
import { ReportDownloadRequest } from './ReportDownloadRequest';

// <!-- ENUMS -->
import { ReportType } from '@/utils/enums';
import { ReportDownloadEvent } from './ReportDownloadEvent';
import { ReportDownloadStatus } from './ReportDownloadStatus';

// <!-- TYPES -->
/**
 * Individual properties used in report download event options.
 * @typedef {{ reportType: keyof typeof ReportType }} ReportTypeProperty Report type.
 * @typedef {{ filename: ReportFileName }} ReportFileNameProperty Report filename.
 * @typedef {{ account: Pick<import('models/accounts/Account').AccountResource, 'id' | 'name'> }} AccountResourceProperty Account.
 * @typedef {{ data: Blob }} ReportBlobProperty Report blob response.
 * @typedef {{ error: unknown }} ErrorProperty Error instance or message.
 */

/**
 * Report download event options.
 * @typedef {Combine<ReportTypeProperty & ReportFileNameProperty & Partial<import('./ReportDownloadRequest').ReportChartsParam>>} DownloadEventArgs
 * @typedef {Combine<ReportTypeProperty & ReportFileNameProperty>} DownloadResolvedEventArgs
 * @typedef {Combine<ReportTypeProperty & ReportFileNameProperty & ErrorProperty>} DownloadRejectedEventArgs
 * @typedef {Combine<ReportTypeProperty & ReportFileNameProperty & Partial<ErrorProperty>>} DownloadFinishedEventArgs
 */

/**
 * Report download event hooks.
 * @typedef {import('@vueuse/core').EventHook<DownloadEventArgs>} DownloadEventHook Triggered to begin the report download operation.
 * @typedef {import('@vueuse/core').EventHook<DownloadResolvedEventArgs>} DownloadResolvedEventHook Triggered when report download is successful.
 * @typedef {import('@vueuse/core').EventHook<DownloadRejectedEventArgs>} DownloadRejectedEventHook Triggered when report download is unsuccessful.
 * @typedef {import('@vueuse/core').EventHook<DownloadFinishedEventArgs>} DownloadFinishedEventHook Triggered after report download operation concludes, either successfully or unsuccessfully.
 */

/**
 * Report download controller component.
 * @typedef {ReturnType<ReportDownload.events>} ReportDownloadEventHooks
 * @typedef {ReturnType<ReportDownload.services>} ReportDownloadServices
 * @typedef {ReturnType<ReportDownload.state>} ReportDownloadState
 * @typedef {ReturnType<ReportDownload.properties>} ReportDownloadProperties
 */

// <!-- CONTROLLER -->

/**
 * Controller utility for managing report downloads.
 */
export class ReportDownload {
    // STATIC INTERNAL METHODS

    /**
     * Creates services that interact with this controller.
     */
    static services() {
        return {
            /** @type {Store<ECNBState>} */
            store: useStore(),
            /** @type {V.Ref<boolean>} */
            online: useOnline({ window }),
        };
    }

    /**
     * Creates event hooks used by this controller.
     */
    static events() {
        return {
            /**
             * Triggered to begin the report download operation.
             * @type {DownloadEventHook}
             */
            download: createEventHook(),
            /**
             * Triggered when report download is successful.
             * @type {DownloadResolvedEventHook}
             */
            success: createEventHook(),
            /**
             * Triggered when report download is unsuccessful.
             * @type {DownloadRejectedEventHook}
             */
            failure: createEventHook(),
            /**
             * Triggered after report download operation concludes, either successfully or unsuccessfully.
             * @type {DownloadFinishedEventHook}
             */
            finish: createEventHook(),
        };
    }

    /**
     * Creates reactive state used by this controller.
     * @param {object} [options] Options used to control instantiated values.
     * @param {MaybeComputedRef<string>} [options.tag] Tag to append to the report filename.
     * @param {MaybeComputedRef<number>} [options.delay] Time in milliseconds to delay.
     */
    static state(options = {}) {
        return {
            /**
             * Contains stateful information about the controller operations.
             * @type {V.Ref<keyof typeof ReportDownloadStatus>}
             */
            status: ref(null),
            /**
             * Represents delay before download request is sent.
             */
            delay: refDefault(resolveRef(options.delay), 1000),
        };
    }

    /**
     * Creates the computed properties used by this controller.
     * @param {{ services: ReportDownloadServices, state: ReportDownloadState }} context
     */
    static properties(context) {
        /**
         * Create the computed properties for online availability tracking.
         */
        const availability = () => {
            const { online } = context.services;
            const isOnline = computedEager(() => !!online.value);
            const isOffline = computedEager(() => !online.value);
            const isUnavailable = computedEager(() => isOffline.value);
            return {
                isOnline,
                isOffline,
                isUnavailable,
            };
        };

        /**
         * Create the computed properties for a status tracker.
         * @returns
         */
        const status = () => {
            const { status } = context.state;
            const isRequesting = computedEager(
                () => status.value === ReportDownloadStatus.requesting
            );
            const isDownloading = computedEager(
                () => status.value === ReportDownloadStatus.downloading
            );
            const isBusy = computedEager(
                () => isRequesting.value || isDownloading.value
            );
            const isIdle = computedEager(() => !isBusy.value);
            return {
                isRequesting,
                isDownloading,
                isBusy,
                isIdle,
            };
        };

        /**
         * Create the computed properties for the selected account resource.
         */
        const account = () => {
            const { store } = context.services;
            const account = computedEager(() => store.state.accounts.account);
            const id = computedEager(() => account.value.id);
            const name = computedEager(() => account.value.name);
            const selected = computedEager(() => !!account.value && !!id.value);
            return {
                account,
                accountId: id,
                accountName: name,
                isAccountSelected: selected,
            };
        };

        /**
         * Create the computed properties for the date range filter.
         */
        const dates = () => {
            const { store } = context.services;
            const startDate = computedEager(() => {
                const date = store.state.analysis.filters.dates.start;
                return date;
            });
            const endDate = computedEager(() => {
                const date = store.state.analysis.filters.dates.end;
                return date;
            });

            // TODO: Move?
            // const foramttedStartDate = computedEager(() => {
            //     const date = store.state.analysis.filters.dates.start;
            //     const parsed = DateTimeISO.parse(date);
            //     const formatted = formatISO(parsed, {
            //         format: 'extended',
            //         representation: 'date',
            //     });
            //     return formatted;
            // });

            // TODO: Move?
            // const foramttedEndDate = computedEager(() => {
            //     const date = store.state.analysis.filters.dates.end;
            //     const parsed = DateTimeISO.parse(date);
            //     const formatted = formatISO(parsed, {
            //         format: 'extended',
            //         representation: 'date',
            //     });
            //     return formatted;
            // });

            return {
                startDate,
                endDate,
            };
        };

        /**
         * Create the computed properties for the selected locations.
         */
        const checked = () => {
            const { store } = context.services;

            /** @type {V.ComputedRef<string[]>} */
            const checkedLocations = computed(() => {
                const filter = store.state.analysis.filters.locations;
                const checkedLocationsInTree = NodeRecord.where(
                    filter.tree.nodes,
                    (n) =>
                        Node.isLocationNode(n) && NodeState.isChecked(n.state)
                );
                const childLocations = checkedLocationsInTree
                    .map((n) => n.id)
                    .map(NodeSelector.readResourceID)
                    .map(Number)
                    .filter((id) => !Number.isNaN(id))
                    .map(String);
                return childLocations;
            });

            /** @type {V.ComputedRef<string[]>} */
            const checkedWeatherStations = computed(() => {
                const filter = store.state.analysis.filters.stations;
                const checkedStationsInTree = NodeRecord.where(
                    filter.tree.nodes,
                    (n) =>
                        Node.isWeatherStationNode(n) &&
                        NodeState.isChecked(n.state)
                );
                const childStations = checkedStationsInTree
                    .map((n) => n.id)
                    .map(NodeSelector.readResourceID)
                    .filter((id) => !Number.isNaN(Number(id)));
                return childStations;
            });

            return {
                checkedLocations,
                checkedWeatherStations,
            };
        };

        return {
            ...availability(),
            ...status(),
            ...account(),
            ...checked(),
            ...dates(),
        };
    }

    // CONSTRUCTOR

    /**
     * Construct the report download controller.
     * @param {Combine<{ tag?: MaybeComputedRef<string>, delay?: MaybeComputedRef<number> }>} [options]
     */
    constructor(options = {}) {
        this.services = ReportDownload.services();
        this.events = ReportDownload.events();
        this.state = ReportDownload.state(options);
        this.properties = ReportDownload.properties(this);

        this.boot();
        this.register();
    }

    // INTERNAL METHODS

    /**
     * Initialize and setup the report download controller.
     */
    boot() {}

    /**
     * Register the event handlers that are minimally required.
     */
    register() {
        // Setup the downloader reference.
        // THEN, get reference to its event listeners.
        const downloader = this;
        const { onDownload, onFinished } = downloader.lifecycle;

        // Register the `onDownload` event listener.
        onDownload(async (options) => {
            // Tracks presence of a fatal download error.
            let error = null;

            // Assert downloader is online.
            if (downloader.properties.isOffline.value) {
                error = new Error(
                    `You are disconnected from your online network. Please reconnect before downloading a report.`
                );
                downloader.cancel({ ...options, error });
                return;
            }

            // Assert downloader is not busy. Otherwise, cancel the operation.
            if (downloader.properties.isBusy.value) {
                error = new Error(
                    `The report downloader is currently busy. Please wait until the current operation is completed.`
                );
                downloader.cancel({ ...options, error });
                return;
            }

            // Assert that the graphing server is currently available.
            try {
                const healthcheck = await ReportDownloadRequest.ping();
                console.dir(healthcheck);
            } catch (e) {
                throw new Error(
                    `The graphing server is currently unavailable. Please contact your system administrator.`
                );
            }

            // Notify user we will begin requesting data.
            downloader.status.requesting();

            // Wait if there is a delay on this downloader.
            await promiseTimeout(downloader.state.delay.value);

            // Begin unsafe operations.
            try {
                // Create the download request instance.
                const request = ReportDownloadRequest.fromState({
                    account: this.properties.account,
                    checkedLocations: this.properties.checkedLocations,
                    checkedWeatherStations:
                        this.properties.checkedWeatherStations,
                    reportType: options.reportType,
                    startDate: this.properties.startDate,
                    endDate: this.properties.endDate,
                    charts: options.charts,
                });

                // Notify user we will begin requesting data.
                // THEN, Await the response from the request.
                downloader.status.downloading();
                const response = await request.send();

                // Save the blob file using the given filename.
                downloader.saveBlobAsFile(
                    response.data,
                    options.filename.orDefault()
                );

                // If no error is thrown, send success event.
                downloader.resolve(options);
            } catch (e) {
                // When error is caught, assign the error reference.
                // THEN, send the failure event.
                error = e;
                downloader.reject({ ...options, error });
            } finally {
                // When successful or unsuccessful,
                // THEN, send the done event.
                downloader.done({ ...options, error });
            }
        });

        // Register the `onFinished` event listener. Invoked by `done()`.
        onFinished((options) => {
            // Remove requesting or downloading status, return to `idle`.
            // This notifies others that the downloader can queue up more reports.
            downloader.status.idle();
        });
    }

    // PROPERTIES

    /**
     * Get lifecycle event listeners.
     */
    get lifecycle() {
        return {
            onDownload: this.events.download.on,
            onSuccess: this.events.success.on,
            onFailure: this.events.failure.on,
            onFinished: this.events.finish.on,
        };
    }

    /**
     * Get lifecycle event triggers.
     */
    get trigger() {
        return {
            download: this.events.download.trigger,
            success: this.events.success.trigger,
            failure: this.events.failure.trigger,
            done: this.events.finish.trigger,
        };
    }

    /**
     * Get status manipulation methods.
     * // TODO: Refactor into its own service class.
     */
    get status() {
        const { state } = this;
        const _ = {
            /**
             * Change current status to the specified value.
             * @param {keyof typeof ReportDownloadStatus} value
             */
            set: (value) => {
                state.status.value = value;
            },
            /**
             * Change current status to the specified value.
             * @param {unknown} value
             * @return {value is keyof typeof ReportDownloadStatus}
             */
            is: (value) => state.status.value === value,
            idle: () => {
                _.set(ReportDownloadStatus.idle);
            },
            requesting: () => {
                _.set(ReportDownloadStatus.requesting);
            },
            downloading: () => {
                _.set(ReportDownloadStatus.downloading);
            },
        };
        return _;
    }

    // SERVICE METHODS

    /**
     * Download the report of the specified type using the default filename.
     * @param {keyof typeof ReportType} reportType
     * @param {number[]} [charts]
     */
    download(reportType, charts = []) {
        const tag = ReportDownloadEvent.clicked;
        const timestamp = new Date(Date.now()).toISOString();
        console.info(tag, [
            `Download of ${reportType} report requested @ ${timestamp}.`,
        ]);
        const filename = ReportFileName.create({
            reportType,
            name: this.properties.account.value?.name,
        });
        this.trigger.download({
            reportType,
            filename,
            charts,
        });
    }

    /**
     * Download the report of the specified type using the specified filename. Falls back to default filename if one is not provided.
     * @param {keyof typeof ReportType} reportType
     * @param {ReportFileName} [customFilename]
     * @param {number[]} [charts]
     */
    downloadAs(reportType, customFilename = null, charts = []) {
        const tag = ReportDownloadEvent.clicked;
        const timestamp = new Date(Date.now()).toISOString();
        const filename =
            customFilename ??
            ReportFileName.create({
                reportType,
                name: this.properties.account.value?.name,
            });
        console.info(tag, [
            `Download of ${reportType} report requested @ ${timestamp}.`,
            `Saving report to ${filename.orDefault()}`,
        ]);
        this.trigger.download({
            reportType,
            filename,
            charts,
        });
    }

    /**
     * Save the blob as a file with the given filename.
     * @param {Blob} blob
     * @param {string} filename
     */
    saveBlobAsFile(blob, filename) {
        // Assert blob is actually a blob.
        if (!is.blob(blob)) {
            throw new Error(`Cannot save non-blob response!`);
        }

        // Assert filename is non-empty string and not whitespace.
        if (!is.nonEmptyStringAndNotWhitespace(filename)) {
            throw new Error(`Cannot save blob without a filename!`);
        }

        // Create a virtual anchor element and then click it!
        // This isn't appended so it doesn't receive a visible position in the document.
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();

        // Revoke the object URL for the link after download.
        URL.revokeObjectURL(link.href);
        link.remove();
    }

    /**
     * Report a successful download.
     * @param {DownloadResolvedEventArgs} options
     */
    resolve(options) {
        const tag = ReportDownloadEvent.success;
        const timestamp = new Date(Date.now()).toISOString();
        console.info(tag, [
            `Download of ${options.reportType} report succeeded @ ${timestamp}.`,
            `Report saved to ${options.filename.orDefault()}`,
        ]);
        this.trigger.success(options);
    }

    /**
     * Report a failed download.
     * @param {DownloadRejectedEventArgs} options
     */
    reject(options) {
        const tag = ReportDownloadEvent.failure;
        const timestamp = new Date(Date.now()).toISOString();
        const reason =
            options.error instanceof Error
                ? options.error.message
                : String(options.error);
        console.warn(tag, [
            `Download of ${options.reportType} report failed @ ${timestamp}.`,
            reason,
        ]);
        this.trigger.failure(options);
    }

    /**
     * Forcibly cancel the download, due to a known invalid pre-condition, and go to the done state.
     * @param {DownloadRejectedEventArgs} options
     */
    cancel(options) {
        const tag = ReportDownloadEvent.failure;
        const timestamp = new Date(Date.now()).toISOString();
        const reason =
            options.error instanceof Error
                ? options.error.message
                : String(options.error);
        console.warn(tag, [
            `Download of ${options.reportType} report cancelled @ ${timestamp}`,
            reason,
        ]);
        this.trigger.failure(options);
        this.trigger.done(options);
    }

    /**
     * Report completion of a download operation.
     * @param {DownloadFinishedEventArgs} options
     */
    done(options) {
        const tag = ReportDownloadEvent.finished;
        const timestamp = new Date(Date.now()).toISOString();
        console.info(tag, [`Operation complete @ ${timestamp}.`]);
        this.trigger.done(options);
    }
}

// <!-- DEFAULT -->
export default ReportDownload;
