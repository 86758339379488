<template>
    <AnalysisContainer :currentFilterType="'none'">
        <template #sideBar></template>
        <template #mainContent>
            <RightContainer>
                <PageIntro>
                    <PageDescription>
                        Compare NARA Standards data.
                        <TeleportTrigger
                            class="-left-1"
                            @open="viewNARAStandardsModal.open"
                        />
                        <ViewNARAStandardsModal
                            :show="viewNARAStandardsModal.isOpen.value"
                            @close="viewNARAStandardsModal.close"
                        />
                    </PageDescription>
                    <DownloadReportButton
                        :busy="isDownloading"
                        reportType="nara"
                        @download:click="download"
                    />
                </PageIntro>
                <DownloadReportServerHealth
                    class="mb-4"
                    :online="isOnline"
                    :available="isGraphingServerAvailable"
                    :busy="isDownloading"
                />
                <div class="pr-1">
                    <!-- <div class="bg-gray-50 p-2 rounded-lg text-xs font-sans">
                        <span class="font-bold">Debug</span>
                        <div class="whitespace-pre-wrap">
                            {{
                                {
                                    // CONDITIONALS
                                    isReady,
                                    isLoading,
                                    isSelectionEmpty,
                                    isResponseEmpty,
                                    // COMPUTED
                                    rowData: rowData.slice(0, 1),
                                    // columnDefs,
                                    // defaultColumnDef,
                                }
                            }}
                        </div>
                    </div> -->
                    <AgGridVue
                        id="standardsGrid"
                        class="ag-theme-alpine"
                        domLayout="autoHeight"
                        :pagination="pagination"
                        :paginationPageSize="paginationPageSize"
                        :rowData="rowData"
                        :rowHeight="null"
                        :columnDefs="columnDefs"
                        :defaultColDef="defaultColumnDef"
                        @grid-ready="readyGrid"
                        @column-resized="resizeColumn"
                        overlayNoRowsTemplate="No standards to display."
                    />
                </div>
                <Transition
                    as="template"
                    name="fader"
                >
                    <div
                        v-if="isSelectionEmpty"
                        class="transition ease-in-out delay-150 bg-white text-gray-600 hover:bg-gray-50 hover:text-black duration-300 rounded-lg mt-2"
                    >
                        <p
                            class="flex flex-col justify-center items-center tracking-tight"
                            :style="{
                                height: '100px',
                            }"
                        >
                            <span class="block">
                                No locations or weather stations are selected.
                            </span>
                        </p>
                    </div>
                    <div
                        v-else-if="isLoading"
                        class="transition ease-in-out delay-150 bg-white text-gray-600 hover:bg-gray-50 hover:text-black duration-300 rounded-lg mt-2"
                    >
                        <p
                            class="flex justify-center items-center tracking-tight"
                            :style="{
                                height: '100px',
                            }"
                        >
                            <span class="flex flex-row">
                                <LoadingSpinner
                                    class="relative h-5 w-5 p-0 mt-0 mb-0 mr-2 top-0.5"
                                />
                                Loading standards for selected resources.
                            </span>
                        </p>
                    </div>
                </Transition>
            </RightContainer>
        </template>
    </AnalysisContainer>
    <div class="fixed top-10 right-0 mr-10 mt-2 min-w-18">
        <BaseAlert
            :alerts="reportAlerts.alerts.value"
            @dismiss:alert="reportAlerts.forceDismiss"
        />
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onBeforeMount, onBeforeUnmount } from 'vue';

    // <!-- COMPONENTS -->
    import TeleportTrigger from '@/components/modals/TeleportTrigger.vue';
    import RightContainer from '@/components/RightContainer.vue';
    import AnalysisContainer from '~Analysis/components/wrappers/AnalysisContainer.vue';
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import PageIntro from '@/components/PageIntro.vue';
    import PageDescription from '@/components/PageDescription.vue';
    import ViewNARAStandardsModal from '~Analysis/components/modals/ViewNARAStandardsModal.vue';
    import DownloadReportButton from '../components/buttons/DownloadReportButton.vue';
    import DownloadReportServerHealth from '../components/card/DownloadReportServerHealth.vue';
    import BaseAlert from '@/components/alerts/BaseAlert.vue';
    import { AgGridVue } from 'ag-grid-vue3';

    // <!-- COMPOSABLES -->
    import { useModalToggle } from '@/hooks/modals';
    import { useActiveRoute } from '~Analysis/hooks/useActiveRoute';
    import { useNARAStandards } from '~Analysis/hooks/useNARAStandards';
    import { useReportAlerts } from '~Analysis/hooks/reports/useReportAlerts';
    import { useReportDownload } from '~Analysis/hooks/reports/useReportDownload';
    import { useGraphingServerHeartbeat } from '~Analysis/hooks/reports/useGraphingServerHeartbeat';

    // <!-- ENUMS -->
    import { ReportType } from '@/utils/enums';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'Standards',
        components: {
            TeleportTrigger,
            ViewNARAStandardsModal,
            AnalysisContainer,
            LoadingSpinner,
            RightContainer,
            PageIntro,
            PageDescription,
            DownloadReportButton,
            DownloadReportServerHealth,
            BaseAlert,
            AgGridVue,
        },
        setup() {
            // MODALS

            const { modal: viewNARAStandardsModal } = useModalToggle();

            // ACTIVE TAB

            /**
             * Hooks for tracking the active route.
             */
            const targetRoute = useActiveRoute({ name: 'NARA Standards' });

            // NARA STANDARDS

            const { query, grid } = useNARAStandards();
            const { queryClient } = query;

            // DOWNLOAD PDF

            const reportAlerts = useReportAlerts();
            const reportDownloader = useReportDownload();
            const reportAvailability = useGraphingServerHeartbeat();

            const downloadReport = async (
                /** @type {keyof typeof ReportType} */ reportType
            ) => {
                reportAlerts.clicked(reportType);
                reportDownloader.download(reportType);
            };

            // LIFECYCLE

            reportDownloader.onSuccess((e) => {
                reportAlerts.success(e.reportType);
            });

            reportDownloader.onFailure((e) => {
                reportAlerts.failure(e.reportType, String(e.error));
            });

            onBeforeMount(async () => {
                reportAvailability.heartbeat.resume();
            });

            onBeforeUnmount(() => {
                reportAvailability.heartbeat.pause();
            });

            // Handle change in current route.
            targetRoute.onTargetEnter(async (event) => {
                console.log(`target::enter`, {
                    event,
                    target: targetRoute.target.value,
                    isActive: targetRoute.isActive.value,
                });
                await queryClient.invalidateQueries(['accounts']);
                await queryClient.refetchQueries(['accounts']);
            });

            // Handle exit from current route.
            targetRoute.onTargetLeave(async (event) => {
                console.log(`target::leave`, {
                    event,
                    target: targetRoute.target.value,
                    isActive: targetRoute.isActive.value,
                });
                await queryClient.cancelQueries(['accounts']);
            });

            // <!-- EXPOSE -->
            return {
                // MODALS
                viewNARAStandardsModal,
                // STATE
                pagination: grid.pagination,
                paginationPageSize: grid.paginationPageSize,
                defaultColumnDef: grid.defaultColumnDef,
                columnDefs: grid.columnDefs,
                // COMPUTED
                rowData: grid.rowData,
                isLoading: query.isLoading,
                isReady: query.isReady,
                isSelectionEmpty: query.isSelectionEmpty,
                isResponseEmpty: query.isResponseEmpty,
                isDownloading: reportDownloader.isBusy,
                isOnline: reportAvailability.isOnline,
                isGraphingServerAvailable: reportAvailability.isAvailable,
                // METHODS
                readyGrid: grid.readyGrid,
                resizeColumn: grid.resizeColumn,
                // REPORT DOWNLOAD
                download: downloadReport,
                reportAlerts,
            };
        },
    });
</script>

<style lang="scss">
    .fader-enter-active,
    .fader-leave-active {
        transition: opacity 0.3s ease;
    }

    .fader-enter-from,
    .fader-leave-to {
        opacity: 0;
    }
</style>
