<template>
    <AnalysisContainer
        :currentFilterType="selectedFilterType"
        @click:zoom="onClickResetZoom"
        @start:export="$log($event)"
    >
        <template #sideBar></template>
        <template #mainContent>
            <Transition
                as="template"
                mode="out-in"
                name="fader"
            >
                <RightContainer>
                    <!-- <PageIntro>
                        <PageDescription>
                            Page description goes here.
                        </PageDescription>
                    </PageIntro> -->
                    <!-- <div class="bg-gray-50 p-2 rounded-lg text-xs font-sans">
                        <span class="font-bold">Debug</span>
                        <div class="whitespace-pre-wrap">
                            {{
                                {
                                    conditions: {
                                        isInitialized,
                                        isChartReady,
                                        isChartBusy,
                                        isLoading,
                                        isFetchingResource,
                                        isEmptyResourceSelection,
                                        isPrintingDisabled,
                                        isChartEmpty,
                                    },
                                    // state: {
                                    //     // chartOptions,
                                    //     // status,
                                    //     // chart,
                                    //     selectedGraphType: graph,
                                    //     selectedFilterType,
                                    //     title: chartOptions.title,
                                    //     subtitle: chartOptions.subtitle,
                                    //     dates,
                                    //     limits,
                                    //     scales,
                                    //     locations: locations.checked,
                                    //     weatherStations: weatherStations.checked,
                                    //     // locations,
                                    //     // weatherStations,
                                    //     yAxis,
                                    //     plotLines,
                                    //     series,
                                    // },
                                    resolved: {
                                        chart: chartOptions.chart,
                                        xAxis: chartOptions.xAxis,
                                        yAxis: chartOptions.yAxis,
                                        // series: chartOptions.series,
                                        // navigator: chartOptions.navigator,
                                    },
                                }
                            }}
                        </div>
                    </div> -->
                    <div class="pr-1">
                        <div
                            v-if="isEmptyResourceSelection && !isLoading"
                            class="transition ease-in-out delay-150 bg-white text-gray-600 shadow-sm hover:bg-gray-50 hover:text-black hover:shadow-lg duration-300 rounded-lg"
                        >
                            <div class="mx-auto max-w-2xl px-4 text-center">
                                <p
                                    class="flex flex-col justify-center items-center tracking-tight"
                                    :style="{
                                        height: '500px',
                                    }"
                                >
                                    <span class="block">
                                        No locations or weather stations are
                                        selected.
                                    </span>
                                    <span class="block">
                                        Select a data resource to get started.
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div
                            v-else
                            class="shadow-sm"
                        >
                            <!-- <div
                            v-if="isDebug"
                            class="whitespace-pre-line bg-gray-200 p-4 my-5 rounded-lg max-h-96 overflow-y-auto"
                        >
                            <pre>{{
                                {
                                }
                            }}</pre>
                        </div> -->
                            <PageIntro>
                                <div class="mb-5 md:mb-0">
                                    <label
                                        for="graph-type"
                                        class="block mb-1 font-bold text-sm"
                                        >Graph Type</label
                                    >
                                    <select
                                        name="graph-type"
                                        id="graph-type"
                                        :disabled="isChartBusy"
                                        class="block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                                        :class="{
                                            'text-gray-400': isChartBusy,
                                        }"
                                        @change="onGraphTypeChanged"
                                        v-model="graph"
                                    >
                                        <option value="T">Temperature</option>
                                        <option value="RH">
                                            Relative Humidity
                                        </option>
                                        <option value="TRH">
                                            {{
                                                `Temperature & Relative Humidity`
                                            }}
                                        </option>
                                        <option value="DP">Dew Point</option>
                                        <option value="MOLD">Mold</option>
                                        <option value="PI">
                                            Preservation Index
                                        </option>
                                        <option value="TWPI">
                                            Time Weighted Preservation Index
                                        </option>
                                        <option value="DC">
                                            Dimensional Change
                                        </option>
                                        <option value="EMC">
                                            Equilibrium Moisture Content
                                        </option>
                                    </select>
                                </div>
                                <!-- Actions Dropdown -->
                                <ActionsMenu
                                    class="flex justify-end content-center z-30"
                                >
                                    <div class="px-1 py-1">
                                        <MenuItem
                                            v-for="(
                                                format, index
                                            ) in ExportFormatAllowList"
                                            v-slot="{ active, disabled }"
                                            :disabled="
                                                isPrintingDisabled ||
                                                isChartEmpty
                                            "
                                            :key="index"
                                        >
                                            <a
                                                href="#download"
                                                @click="
                                                    (e) => {
                                                        e.preventDefault(); // Prevent route change!!!
                                                        !disabled &&
                                                            print.onExport(
                                                                format
                                                            );
                                                    }
                                                "
                                                class="group flex items-center px-4 py-2 text-sm"
                                                :class="{
                                                    'text-gray-400':
                                                        disabled && active,
                                                    'text-gray-500':
                                                        disabled && !active,
                                                    'hover:cursor-not-allowed':
                                                        disabled,
                                                    'bg-gray-100':
                                                        !disabled && active,
                                                    'text-gray-900':
                                                        !disabled && active,
                                                    'text-gray-700':
                                                        !disabled && !active,
                                                }"
                                            >
                                                <DocumentDownloadIcon
                                                    class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                    :class="{
                                                        'text-gray-400':
                                                            !disabled,
                                                        'group-hover:text-gray-500':
                                                            !disabled,
                                                        'text-gray-200':
                                                            disabled,
                                                    }"
                                                    aria-hidden="true"
                                                />
                                                Save Image (as
                                                {{
                                                    String(
                                                        format
                                                    ).toUpperCase()
                                                }})
                                            </a>
                                        </MenuItem>
                                    </div>
                                </ActionsMenu>
                            </PageIntro>
                            <div
                                v-show="
                                    isChartEmpty &&
                                    !isFetchingResource &&
                                    !isLoading &&
                                    !isRefreshing
                                "
                                class="my-2 transition ease-in-out delay-150 bg-white text-gray-600 hover:bg-gray-50 hover:text-black duration-300 rounded-lg"
                            >
                                <div class="mx-auto max-w-2xl px-4 text-center">
                                    <p
                                        class="flex flex-col justify-center items-center tracking-tight"
                                        :style="{ height: '100px' }"
                                    >
                                        <span class="block"
                                            >No chart data found with current
                                            filter settings.</span
                                        >
                                    </p>
                                </div>
                            </div>
                            <div class="my-2 p-2">
                                <transition
                                    as="div"
                                    enter-active-class="transition duration-200 ease-out"
                                    enter-from-class="transform scale-95 opacity-0"
                                    enter-to-class="transform scale-100 opacity-100"
                                    leave-active-class="transition duration-150 ease-out"
                                    leave-from-class="transform scale-100 opacity-100"
                                    leave-to-class="transform scale-95 opacity-0"
                                >
                                    <div class="relative">
                                        <div
                                            v-if="isResetZoomButtonVisible"
                                            class="block md:hidden"
                                        >
                                            <ModalButton
                                                theme="white"
                                                label="Reset Zoom"
                                                class="z-20"
                                                width="w-full md:w-auto"
                                                @click="onClickResetZoom"
                                            />
                                        </div>
                                        <div
                                            v-if="isResetZoomButtonVisible"
                                            class="float-right mr-20 md:mr-32 lg:mr-36 hidden md:block"
                                        >
                                            <ModalButton
                                                theme="white"
                                                label="Reset Zoom"
                                                class="absolute z-20"
                                                @click="onClickResetZoom"
                                            />
                                        </div>
                                        <div
                                            v-if="!isInitialized"
                                            class="relative top-0 left-0 right-0 bottom-0 inset-0 py-24 bg-gray-100 animate-pulse"
                                        >
                                            <LoadingSpinner
                                                class="animate-pulse"
                                            />
                                        </div>
                                        <div
                                            v-else
                                            :style="'height: 680px'"
                                        >
                                            <StockChart
                                                id="chart"
                                                :key="key"
                                                class="flex shrink-0 ml-auto mr-auto w-96 sm:w-full h-full chart pt-2 z-10"
                                                :class="{
                                                    'animate-pulse': isLoading,
                                                    'pointer-events-none':
                                                        isLoading,
                                                }"
                                                :constructor-type="'stockChart'"
                                                :options="chartOptions"
                                            />
                                        </div>
                                    </div>
                                </transition>
                            </div>
                        </div>
                    </div>
                </RightContainer>
            </Transition>
        </template>
    </AnalysisContainer>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        onMounted,
        onUnmounted,
        computed,
        reactive,
        ref,
    } from 'vue';

    // <!-- COMPONENTS -->
    import RightContainer from '@/components/RightContainer.vue';
    import PageIntro from '@/components/PageIntro.vue';
    import ActionsMenu from '@/components/ActionsMenu.vue';
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import { MenuItem } from '@headlessui/vue';
    import { DocumentDownloadIcon } from '@heroicons/vue/solid';
    import { Chart } from 'highcharts-vue';
    import AnalysisContainer from '~Analysis/components/wrappers/AnalysisContainer.vue';
    import ModalButton from '@/components/modals/ModalButton.vue';

    // <!-- COMPOSABLES -->
    import { useAnalysisChart } from '~Analysis/hooks/useAnalysisChart';

    // <!-- TYPES -->

    /** @typedef {import('@/features/analysis/hooks/useAnalysisChart').AnalysisChartConstants['GraphTypes'][number]} GraphType */
    /** @typedef {Extract<GraphType, 'T' | 'RH' | 'TRH' | 'DP'> | 'all' | ''} FilterType */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'Analysis',
        components: {
            AnalysisContainer,
            RightContainer,
            PageIntro,
            ActionsMenu,
            MenuItem,
            DocumentDownloadIcon,
            LoadingSpinner,
            StockChart: Chart,
            ModalButton,
        },
        emits: ['export::start', 'export::success', 'export::failure'],
        setup(_, context) {
            // DEFINE the analysis chart.
            const analysisChart = useAnalysisChart(context.emit);

            // DESTRUCTURE reactive state and properties.
            const { Status, GraphTypes, ExportFormatAllowList } =
                analysisChart.constants;
            const {
                // HighchartsModule,
                // status,
                key,
                chart,
                graph,
                // dates,
                // limits,
                // scales,
                // locations,
                // weatherStations,
                resolvedOptions,
                // yAxis,
                // plotLines,
                // series,
            } = analysisChart.state;
            const {
                isInitialized,
                isLoading,
                isPrinting,
                isPrintingDisabled,
                isRefreshing,
                isChartReady,
                isChartBusy,
                isChartEmpty,
                isEmptyResourceSelection,
                isFetchingResource,
                isResetZoomButtonVisible,
            } = analysisChart.properties;

            // DEFINE the selected graph type.

            /** @type {V.ComputedRef<FilterType>} */
            const selectedFilterType = computed(() => {
                const type = graph.value;
                if (['all', '*'].includes(type)) {
                    return 'all';
                } else {
                    return /** @type {FilterType} */ (type);
                }
            });

            // DESTRUCTURE chart API.
            const { enable, disable, resetZoom, setGraphType, print } =
                analysisChart.methods;

            // DEFINE click events.

            /**
             * Invoke the reset zoom event.
             * @type {(e: MouseEvent) => void}
             */
            const onClickResetZoom = (event) => {
                console.log(`reset::zoom`, event);
                resetZoom();
            };

            /**
             * Invoke the metric changed event.
             * @type {(e: { target: { value: GraphType }} & Event) => void}
             */
            const onGraphTypeChanged = (event) => {
                enable(Status.REFRESHING);
                console.log(`change::metric`, event);
                setGraphType(event?.target?.value ?? 'T');
                disable(Status.REFRESHING);
            };

            // DEFINE Lifecycle events.

            const { listen, stop } = analysisChart.subscribers;

            // Initialize and register subscribers.
            onMounted(async () => {
                // Init.
                await analysisChart.initialize();

                // Subscribe + watch.
                listen();
            });

            // Unregister subscribers.
            onUnmounted(() => {
                stop();
            });

            // const debugSeries = computed(() => {
            //     const _series = series.value;
            //     const _lines = [];
            //     _series.forEach((s, axis) => {
            //         _lines.push(`${s.length} series for the ${axis} axis.`);
            //     });
            //     return _lines;
            // });

            // EXPOSE
            return {
                // CONSTANTS,
                ExportFormatAllowList,
                // STATE
                // HighchartsModule,
                // status,
                key,
                chart,
                graph,
                // dates,
                // limits,
                // scales,
                // locations,
                // weatherStations,
                chartOptions: resolvedOptions,
                // yAxis,
                // plotLines,
                // series: debugSeries,
                // REACTIVE
                selectedFilterType,
                // CONDITIONALS,
                isDebug: true,
                isInitialized,
                isLoading,
                isPrinting,
                isChartReady,
                isChartBusy,
                isChartEmpty,
                isPrintingDisabled,
                isRefreshing,
                isEmptyResourceSelection,
                isFetchingResource,
                isResetZoomButtonVisible,
                // NAMESPACE
                print,
                // EVENTS
                onClickResetZoom,
                onGraphTypeChanged,
            };
        },
    });
</script>

<style lang="scss">
    .fader-enter-active,
    .fader-leave-active {
        transition: opacity 0.3s ease;
    }

    .fader-enter-from,
    .fader-leave-to {
        opacity: 0;
    }
</style>
