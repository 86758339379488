// <!-- API -->
import { computed, ref, watch } from 'vue';
import {
    computedEager,
    createEventHook,
    makeDestructurable,
} from '@vueuse/core';
import locations, {
    fetchLocationById,
    fetchLocations,
} from '@/api/accounts/locations';
import {
    fetchWeatherStationById,
    fetchWeatherStations,
} from '@/api/accounts/weather';
import { fetchLocationStats, fetchWeatherStationStats } from '@/api/legacy';
import { fetchNARAStandardMetrics } from '@/api/accounts';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';

// // <!-- QUERIES -->
// import useAccountLocations from '@/query/useAccountLocations';
// import useAccountLocationsMetrics from '@/query/useAccountLocationsMetrics';
// import useLocationStatistics from '@/query/useLocationStatistics';
// import useAccountWeatherStations from '@/query/useAccountWeatherStations';
// import useWeatherStationStatistics from '@/query/useWeatherStationStatistics';

// <!-- UTILITIES -->
import is from '@sindresorhus/is';
import { endOfDay, isAfter, isBefore, startOfDay, subYears } from 'date-fns';
import { DateTimeISO } from '@/utils/datetime';
import { DateRange } from '@/utils/filters';
import { Node, NodeSelector, NodeState } from '@/utils/tree';

// <!-- TYPES -->
/** @typedef {import('vuex').Store<ECNBState>} Store */
import { ECNBState } from '@/store/types/ECNBStore';
import { LocationResource } from '@/models/locations/Location';
import { useQueries, useQuery, useQueryClient } from '@tanstack/vue-query';
/** @typedef {import('@/models/locations/LocationStats').LocationStatsResource} LocationStatisticsResource */
/** @typedef {import('@/models/locations/LocationStats').LocationStatsResource} WeatherStationStatisticsResource */

/**
 * Resource statistics data structure.
 *
 * @typedef {Object} LocationStatistics
 * @prop {number} id
 * @prop {'Location'} type
 * @prop {LocationResource} resource
 * @prop {LocationStatisticsResource} statistics
 *
 * @typedef {Object} WeatherStationStatistics
 * @prop {string} id
 * @prop {'Weather Station'} type
 * @prop {import('./useAnalysisChart').WeatherStationResource} resource
 * @prop {WeatherStationStatisticsResource} statistics
 *
 * @typedef {LocationStatistics | WeatherStationStatistics} ResourceStatistics
 */

/**
 * @typedef UseStatisticsOptions
 * @prop {import('vuex').Store<import('@/store/types/ECNBStore').ECNBState>} [store] Track an existing store reference, if it was already provisioned.
 * @prop {import('@tanstack/vue-query').QueryClient} [queryClient] Track an existing store reference, if it was already provisioned.
 */

/**
 * Describes the internal event hooks, that may or may not be exposed from this composable.
 * @typedef {import('@vueuse/core').EventHook<ResourceStatistics[]>} ResourceStatisticsCollectionEventHook
 * @typedef {import('@vueuse/core').EventHook<unknown>} ErrorEventHook
 */

/**
 * @typedef UseStatisticsReturn
 * @prop {V.ComputedRef<ResourceStatistics[]>} data Computed reference collection of data.
 * @prop {V.Ref<unknown>} error Current stored error.
 * @prop {V.ComputedRef<boolean>} isIdle Is the user selection empty?
 * @prop {V.ComputedRef<boolean>} isEmpty Is computed statistics collection empty?
 * @prop {V.ComputedRef<boolean>} isLoading Is the resource data loading?
 * @prop {V.ComputedRef<boolean>} isError Has there been an error?
 * @prop {ResourceStatisticsCollectionEventHook['on']} onResult Fires when all queries resolve, returning the resulting statistics data.
 * @prop {ErrorEventHook['on']} onError Fires when an error occurs.
 */

/**
 * Define the composable.
 * @param {UseStatisticsOptions} [props] @see {@link UseStatisticsOptions}
 * returns {UseStatisticsReturn} @see {@link UseStatisticsReturn}
 */
export const useStatistics = (props = {}) => {
    // EVENTS
    const handleError = createEventHook();

    // SERVICES
    const store = props?.store ?? useStore();
    const queryClient = useQueryClient();

    // METHODS
    /**
     * Get the current date range.
     * @returns {Interval}
     */
    const getCurrentDateRange = () => {
        const { start, end } = store.state.analysis.filters.dates;
        const isInvalidDate = (value) =>
            is.nullOrUndefined(value) || is.nan(value);
        const filterStart = isInvalidDate(start?.valueOf())
            ? Date.UTC(1970, 0, 1, 0, 0, 0, 0)
            : start;
        const filterEnd = isInvalidDate(end?.valueOf())
            ? endOfDay(Date.now())
            : end;
        const filterRange = {
            start: filterStart,
            end: filterEnd,
        };
        return DateRange.clone(filterRange);
    };

    /**
     * Check if location is within the current date range.
     * @param {LocationStatistics['resource']} resource
     */
    const isLocationResourceWithinDateRange = (resource) => {
        const filterRange = dates.value;

        const normalizeStartDate = (value) => {
            return is.nullOrUndefined(value) || is.nan(value)
                ? NaN
                : startOfDay(value);
        };
        const normalizeEndDate = (value) => {
            return is.nullOrUndefined(value) || is.nan(value)
                ? NaN
                : endOfDay(value);
        };
        const parseDate = (value) =>
            is.nullOrUndefined(value) || is.emptyStringOrWhitespace(value)
                ? NaN
                : DateTimeISO.parse(value);

        const start = normalizeStartDate(filterRange.start);
        const end = normalizeEndDate(filterRange.end);
        const min = normalizeStartDate(parseDate(resource?.minDate));
        const max = normalizeEndDate(parseDate(resource?.maxDate));

        // FILTER
        const isMinDateAfterEndBound = isAfter(min, end);
        const isMaxDateBeforeStartBound = isBefore(max, start);

        if (is.nan(min) || is.nan(max)) {
            return true;
        }

        return (
            (is.nan(start) || !isMaxDateBeforeStartBound) &&
            (is.nan(end) || !isMinDateAfterEndBound)
        );
    };

    /**
     * Check if the weather station is within the current date range. Always returns `true`.
     * @param {WeatherStationStatistics['resource']} resource
     */
    const isWeatherStationResourceWithinDateRange = (resource) => true;

    /** Get the checked location tree nodes. */
    const getCheckedLocationNodes = () => {
        const filter = store.state.analysis.filters.locations.tree;
        const nodes = Object.values(filter.nodes);
        return nodes
            .filter(Node.isLocationNode)
            .filter((n) => NodeState.isChecked(n.state));
    };

    /** Get the checked weather station tree nodes. */
    const getCheckedWeatherStationNodes = () => {
        const filter = store.state.analysis.filters.stations.tree;
        const nodes = Object.values(filter.nodes);
        return nodes
            .filter(Node.isWeatherStationNode)
            .filter((n) => NodeState.isChecked(n.state));
    };

    /** Get the location resource collection within a particular date range. */
    const getLocationsWithinDateRange = () => {
        const data = indexLocations.value;
        const filtered = data.filter((r) =>
            isLocationResourceWithinDateRange(r)
        );
        return filtered;
    };

    /** Get the weather station resource collection within a particular date range. */
    const getWeatherStationsWithinDateRange = () => {
        const data = indexWeatherStations.value;
        const filtered = data.filter((r) =>
            isWeatherStationResourceWithinDateRange(r)
        );
        return filtered;
    };

    /** Get the checked location ids. */
    const getCheckedLocationIDs = () => {
        const nodes = checkedLocationNodes.value;
        return nodes
            .map((n) => n.id)
            .map(NodeSelector.readResourceID)
            .map(Number);
    };

    /** Get the checked weather station ids. */
    const getCheckedWeatherStationIDs = () => {
        const nodes = checkedWeatherStationNodes.value;
        return nodes
            .map((n) => n.id)
            .map(NodeSelector.readResourceID)
            .map(String);
    };

    /** Get the filtered locations. */
    const getFilteredLocations = () => {
        const data = indexLocations.value;
        const selectedIDs = checkedLocationIDs.value;
        const withinDateRangeIDs = datedLocations.value.map((r) => r.id);
        const filtered = data.filter((r) => {
            return (
                selectedIDs.includes(r.id) && withinDateRangeIDs.includes(r.id)
            );
        });

        return filtered;
    };

    /** Get the filtered weather stations. */
    const getFilteredWeatherStations = () => {
        const data = indexWeatherStations.value;
        const selectedIDs = checkedWeatherStationIDs.value;
        const withinDateRangeIDs = datedWeatherStations.value.map((r) =>
            String(r.id)
        );
        const filtered = data.filter((r) => {
            return (
                selectedIDs.includes(String(r.id)) &&
                withinDateRangeIDs.includes(String(r.id))
            );
        });

        return filtered;
    };

    /**
     * Calculate valid start and end dates.
     * @param {LocationResource} resource
     */
    const getLocationDateRange = (resource) => {
        const defaultStart = Math.trunc(
            startOfDay(subYears(Date.now(), 1)).valueOf() / 1000
        );
        const defaultEnd = Math.trunc(endOfDay(Date.now()).valueOf() / 1000);
        return {
            minDate: defaultStart,
            maxDate: defaultEnd,
        };
    };

    /**
     * Calculate valid start and end dates.
     * @param {Interval} range
     */
    const getNormalizedDateRange = (range) => {
        // VALIDATE
        const defaultStart = startOfDay(subYears(Date.now(), 1)).valueOf();
        const defaultEnd = endOfDay(Date.now()).valueOf();
        const _start = range?.start?.valueOf() ?? NaN;
        const _end = range?.end?.valueOf() ?? NaN;

        // CLAMP
        const SAFE_MAX_DATE = Date.UTC(3000, 0, 1, 0, 0, 0, 0);
        const SAFE_MIN_DATE = Date.UTC(1, 0, 1, 0, 0, 0, 0);
        const minDate =
            is.nan(_start) || isBefore(_start, SAFE_MIN_DATE)
                ? defaultStart
                : _start;
        const maxDate =
            is.nan(_end) || isAfter(_end, SAFE_MAX_DATE) ? defaultEnd : _end;
        return {
            minDate: Math.round(minDate.valueOf()),
            maxDate: Math.round(maxDate.valueOf()),
        };
    };

    // STATE

    /** @type {V.Ref<import('@/models/accounts/Account').AccountResource>} */
    const selectedAccount = ref(store?.state?.accounts?.account ?? null);

    /** @type {V.Ref<LocationResource[]>} */
    const indexLocations = ref([]);

    /** @type {V.Ref<import('@/models/weather/WeatherStation').WeatherStationResource[]>} */
    const indexWeatherStations = ref([]);

    /** @type {V.Ref<Record<number, import('@/utils/standards').IStandardMetrics>>} */
    const metricsByLocationID = ref({});

    /** @type {V.Ref<Record<number, LocationStatisticsResource>>} */
    const statisticsByLocationID = ref({});

    /** @type {V.Ref<Record<string, WeatherStationStatisticsResource>>} */
    const statisticsByWeatherStationID = ref({});

    // COMPUTED

    const selectedAccountID = computed(() => selectedAccount.value?.id ?? -1);
    const isAccountSelected = computed(
        () => !!store.state.accounts.account?.id && selectedAccountID.value > 0
    );

    const dates = computed(getCurrentDateRange, {
        // onTrigger: (e) => console.log(dates.value),
    });
    const hasDateRange = computedEager(() => !!dates.value);
    const datedLocations = computed(getLocationsWithinDateRange);
    const datedWeatherStations = computed(getWeatherStationsWithinDateRange);
    const checkedLocationNodes = computed(getCheckedLocationNodes);
    const checkedWeatherStationNodes = computed(getCheckedWeatherStationNodes);
    const checkedLocationIDs = computed(getCheckedLocationIDs);
    const checkedWeatherStationIDs = computed(getCheckedWeatherStationIDs);
    const filteredLocations = computed(getFilteredLocations);
    const hasFilteredLocations = computed(
        () => filteredLocations.value.length === 0
    );
    const filteredWeatherStations = computed(getFilteredWeatherStations);
    const hasFilteredWeatherStations = computed(
        () => filteredWeatherStations.value.length === 0
    );

    const QueryKeys = Object.freeze({
        all: /** @type {const} */ (['accounts']),
        /** @param {import('@vueuse/core').MaybeRef<import('@/models/accounts/Account').AccountResource['id']>} account */
        locations: (account) =>
            /** @type {const} */ ([...QueryKeys.all, account, 'locations']),
        /** @param {import('@vueuse/core').MaybeRef<import('@/models/accounts/Account').AccountResource['id']>} account */
        weatherStations: (account) =>
            /** @type {const} */ ([...QueryKeys.all, account, 'stations']),
        /**
         * @param {import('@vueuse/core').MaybeRef<import('@/models/accounts/Account').AccountResource['id']>} account
         * @param {import('@vueuse/core').MaybeRef<Interval>} dates
         */
        metrics: (account, dates) =>
            /** @type {const} */ ([
                ...QueryKeys.all,
                account,
                'locations',
                'metrics',
                dates,
            ]),
        /**
         * @param {import('@vueuse/core').MaybeRef<import('@/models/accounts/Account').AccountResource['id']>} account
         * @param {import('@vueuse/core').MaybeRef<LocationResource['id']>} location
         * @param {import('@vueuse/core').MaybeRef<Interval>} dates
         */
        locationStatistics: (account, location, dates) =>
            /** @type {const} */ ([
                ...QueryKeys.all,
                account,
                'locations',
                location,
                'statistics',
                dates,
            ]),

        /**
         * @param {import('@vueuse/core').MaybeRef<import('@/models/accounts/Account').AccountResource['id']>} account
         * @param {import('@vueuse/core').MaybeRef<import('@/models/weather/WeatherStation').WeatherStationResource['id']>} station
         * @param {import('@vueuse/core').MaybeRef<Interval>} dates
         */
        weatherStationStatistics: (account, station, dates) =>
            /** @type {const} */ ([
                ...QueryKeys.all,
                account,
                'stations',
                station,
                'statistics',
                dates,
            ]),
    });

    const normalizedDates = computed(() => {
        const { minDate, maxDate } = getNormalizedDateRange(dates.value);
        //
        return { start: minDate, end: maxDate };
    });

    /** Create query for account locations. */
    const accountLocations = useQuery({
        queryKey: QueryKeys.locations(selectedAccountID),
        queryFn: ({ queryKey, signal }) => {
            // console.log(`sn::debug::locations`, queryKey);
            return fetchLocations({ id: queryKey[1] }, { signal });
        },
        enabled: isAccountSelected,
        onError: (e) => console.error(e),
        onSuccess: (data) => {
            indexLocations.value = data;
            // console.log(`HIT sn::debug::locations`, data);
        },
        staleTime: 1000 * 60 * 30,
        refetchOnWindowFocus: false,
    });

    /** Create query for account weather stations. */
    const accountWeatherStations = useQuery({
        queryKey: QueryKeys.weatherStations(selectedAccountID),
        queryFn: ({ queryKey, signal }) => {
            // console.log(`sn::debug::stations`, queryKey);
            return fetchWeatherStations({ id: queryKey[1] }, { signal });
        },
        enabled: isAccountSelected,
        onError: (e) => console.error(e),
        onSuccess: (data) => {
            indexWeatherStations.value = data;
            // console.log(`HIT sn::debug::stations`, data);
        },
        staleTime: 1000 * 60 * 30,
        refetchOnWindowFocus: false,
    });

    /** Create query for NARA Standard metrics for all account locations. */
    const accountMetrics = useQuery({
        queryKey: QueryKeys.metrics(selectedAccountID, normalizedDates),
        queryFn: ({ queryKey, signal }) => {
            // console.log(`sn::debug::metrics`, queryKey);
            //
            return fetchNARAStandardMetrics({ id: queryKey[1] }, queryKey[4], {
                signal,
            });
        },
        enabled: isAccountSelected,
        onError: (e) => console.error(e),
        onSuccess: (data) => {
            const payload = {};
            data.metrics.forEach((item) => {
                payload[item?.location?.id] = item?.metrics;
            });
            metricsByLocationID.value = { ...payload };
            // console.log(`HIT sn::debug::metrics`, data);
        },
        staleTime: 1000 * 60 * 30,
        refetchOnWindowFocus: false,
    });

    /** Define query options for each location. */
    const locationStatisticsOptions = computed(() =>
        filteredLocations.value.map((resource) => {
            /**
             * @type {import('@tanstack/vue-query').UseQueryOptions}
             */
            const options = {
                queryKey: QueryKeys.locationStatistics(
                    selectedAccountID,
                    resource.id,
                    dates
                ),
                queryFn: ({ queryKey, signal }) => {
                    // const { minDate, maxDate } = getLocationDateRange(resource);
                    const { minDate, maxDate } = getNormalizedDateRange(
                        dates.value
                    );
                    // console.log(`SEND sn::debug::statistics`, {
                    //     ...resource,
                    //     minDate,
                    //     maxDate,
                    // });
                    return fetchLocationStats(
                        {
                            id: resource?.id,
                            name: resource?.name,
                            minDate: Math.trunc(minDate / 1000),
                            maxDate: Math.trunc(maxDate / 1000),
                            tll: resource?.naraStandard?.min_temp,
                            tul: resource?.naraStandard?.max_temp,
                            rhll: resource?.naraStandard?.min_rh,
                            rhul: resource?.naraStandard?.max_rh,
                        },
                        { signal }
                    );
                },
                onError: (e) => console.error(e),
                /** @param {import('@/models/locations/LocationStats').LocationStatsResource} data */
                onSuccess: (data) => {
                    statisticsByLocationID.value = Object.assign(
                        statisticsByLocationID.value,
                        {
                            [resource.id]: data,
                        }
                    );
                    // console.log(`HIT sn::debug::statistics`, data);
                },
                staleTime: 0,
                retry: false,
                refetchOnWindowFocus: false,
            };
            return options;
        })
    );

    /** Create parallel location statistics queries. */
    const locationStatistics = useQueries({
        queries: locationStatisticsOptions,
    });

    /** Define query options for each weather station. */
    const weatherStationStatisticsOptions = computed(() =>
        filteredWeatherStations.value.map((resource) => {
            /**
             * @type {import('@tanstack/vue-query').UseQueryOptions}
             */
            const options = {
                queryKey: QueryKeys.weatherStationStatistics(
                    selectedAccountID,
                    resource.id,
                    dates
                ),
                queryFn: ({ queryKey, signal }) => {
                    const { minDate, maxDate } = getNormalizedDateRange(
                        dates.value
                    );
                    // console.log(`SEND sn::debug::statistics`, {
                    //     ...resource,
                    //     minDate,
                    //     maxDate,
                    // });
                    return fetchWeatherStationStats(
                        // return fetchWeatherStationStats(
                        {
                            id: Number(resource?.id),
                            name: resource?.name,
                            minDate: Math.trunc(minDate / 1000),
                            maxDate: Math.trunc(maxDate / 1000),
                        },
                        { signal }
                    );
                },
                onError: (e) => console.error(e),
                /** @param {import('@/models/locations/LocationStats').LocationStatsResource} data */
                onSuccess: (data) => {
                    statisticsByWeatherStationID.value = Object.assign(
                        statisticsByWeatherStationID.value,
                        {
                            [resource.id]: data,
                        }
                    );
                    // console.log(`HIT sn::debug::statistics`, data);
                },
                staleTime: 0,
                retry: false,
                refetchOnWindowFocus: false,
            };
            return options;
        })
    );

    /** Create parallel weather station statistics queries. */
    const weatherStationStatistics = useQueries({
        queries: weatherStationStatisticsOptions,
    });

    /** Is the component loading? */
    const isLoading = computed(() => {
        if (
            accountLocations.isLoading.value ||
            accountLocations.isFetching.value
        ) {
            return true;
        }
        if (
            accountWeatherStations.isLoading.value ||
            accountWeatherStations.isFetching.value
        ) {
            return true;
        }
        if (accountMetrics.isLoading.value || accountMetrics.isFetching.value) {
            return true;
        }
        if (locationStatistics.some((q) => !!q.isLoading || !!q.isFetching)) {
            return true;
        }
        if (
            weatherStationStatistics.some(
                (q) => !!q.isLoading || !!q.isFetching
            )
        ) {
            return true;
        }
        return false;
    });

    /** Is the component ready? */
    const isReady = computed(() => !isLoading.value);

    /**
     * Resolved resource statistics data. Empty to start.
     * @type {V.Ref<ResourceStatistics[]>}
     */
    const data = computedEager(() => {
        // LOCATIONS
        const _locations = {
            get filtered() {
                return filteredLocations.value;
            },
            get statistics() {
                return statisticsByLocationID.value;
            },
            get metrics() {
                return metricsByLocationID.value;
            },
            compute() {
                // COMPUTE location resource data.
                const { statistics, metrics } = this;
                return this.filtered.map((location) => {
                    const itemStatistics = statistics[location.id];
                    const itemMetrics = metrics[location.id];

                    /** @type {ResourceStatistics['statistics']} */
                    const item = {
                        ...itemStatistics,
                        // s_t_below: itemMetrics?.temp_below_count ?? NaN,
                        // s_t_in: itemMetrics?.temp_in_count ?? NaN,
                        // s_t_above: itemMetrics?.temp_above_count ?? NaN,
                        // s_rh_below: itemMetrics?.temp_below_count ?? NaN,
                        // s_rh_in: itemMetrics?.temp_in_count ?? NaN,
                        // s_rh_above: itemMetrics?.temp_above_count ?? NaN,
                        s_t_below: itemMetrics?.temp_below_percent ?? NaN,
                        s_t_in: itemMetrics?.temp_in_percent ?? NaN,
                        s_t_above: itemMetrics?.temp_above_percent ?? NaN,
                        s_rh_below: itemMetrics?.rh_below_percent ?? NaN,
                        s_rh_in: itemMetrics?.rh_in_percent ?? NaN,
                        s_rh_above: itemMetrics?.rh_above_percent ?? NaN,
                    };

                    /** @type {ResourceStatistics} */
                    const mapped = {
                        id: location.id,
                        type: 'Location',
                        resource: location,
                        statistics: item,
                    };

                    // Mapped object.
                    return mapped;
                });
            },
        };
        // WEATHER STATIONS
        const _weatherStations = {
            get filtered() {
                return filteredWeatherStations.value;
            },
            get statistics() {
                return statisticsByWeatherStationID.value;
            },
            compute() {
                // COMPUTE weather station resource data.
                const { statistics } = this;
                return this.filtered.map((station) => {
                    /** @type {ResourceStatistics} */
                    const mapped = {
                        id: String(station.id),
                        type: 'Weather Station',
                        resource: station,
                        statistics: statistics[station.id],
                    };
                    // Mapped object.
                    return mapped;
                });
            },
        };
        // COMPUTE merged resource data.
        return [..._locations.compute(), ..._weatherStations.compute()];
    });

    /** Is the treeview selection empty? */
    const isSelectionEmpty = computedEager(
        () =>
            checkedLocationIDs.value.length === 0 &&
            checkedWeatherStationIDs.value.length === 0
    );

    /** Is the resolved data collection empty? */
    const isResponseEmpty = computedEager(
        () => !!data && data?.value?.length === 0
    );

    // LIFECYCLE

    // onSelectionChange(() => {});
    // onDateRangeFilterUpdate(() => {});
    // onAccountLocationsFetched((locations) => {});
    // onLocationMetricsFetched((id, metrics) => {});
    // onLocationStatisticsFetched((id, statistics) => {});
    // onWeatherStationsFetched((stations) => {});

    // WATCHERS

    watch(
        () => store.state.accounts.account,
        (current, previous) => {
            if (!previous || current.id !== previous.id) {
                selectedAccount.value = current;
                indexLocations.value = [];
                indexWeatherStations.value = [];
                statisticsByLocationID.value = {};
                metricsByLocationID.value = {};
                statisticsByWeatherStationID.value = {};
                // console.log(`sn::debug::watch-account?`, current);
            }
        },
        {
            deep: false,
            immediate: true,
        }
    );

    watch(
        () => store.state.analysis.filters.locations.tree.nodes,
        (current, previous) => {
            if (!previous || current.id !== previous.id) {
                // console.log(`sn::debug::watch-account?`, current);
                queryClient.refetchQueries({
                    queryKey: QueryKeys.locations(selectedAccountID),
                });
            }
        },
        {
            deep: true,
            immediate: true,
        }
    );

    watch(
        () => store.state.analysis.filters.stations.tree.nodes,
        (current, previous) => {
            if (!previous || current.id !== previous.id) {
                // console.log(`sn::debug::watch-account?`, current);
                queryClient.refetchQueries({
                    queryKey: QueryKeys.weatherStations(selectedAccountID),
                });
            }
        },
        {
            deep: true,
            immediate: true,
        }
    );

    // EXPOSE
    const exposed = makeDestructurable(
        {
            data,
            queryClient,
            isLoading,
            isReady,
            isSelectionEmpty,
            isResponseEmpty,
        },
        [
            data,
            filteredLocations,
            filteredWeatherStations,
            isLoading,
            isReady,
            isSelectionEmpty,
            isResponseEmpty,
        ]
    );
    return exposed;
};
