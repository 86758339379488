// <!-- API -->
import { ref } from 'vue';
import { useStore } from 'vuex';

// <!-- TYPES -->
import { Store } from 'vuex';
import { ECNBState } from '@/store/types/ECNBStore';
import {
    AxisValue,
    AxisModifier,
    AxisRange,
    AxisRangeFilter,
    ScaleFilter,
    ScaleFilterRecord,
} from '@/utils/filters';
import is from '@sindresorhus/is';
/** @typedef {import('@formkit/core').FormKitNode} FormKitNode */
/** @typedef {import('@/utils/filters').IAxisCategoryID} IAxisCategoryID */
/** @typedef {import('@/utils/filters').IAxisID} IAxisID */
/** @typedef {import('@/utils/filters').IAxisRange} IAxisRange */
/** @typedef {import('@/utils/filters').IAxisRangeFilter} IAxisRangeFilter */
/** @typedef {import('@/utils/filters').IScaleFilter} IScaleFilter */
/** @typedef {import('@/utils/filters').IScaleFilterRecord} IScaleFilterRecord */

/**
 * Create reactive source and live form data properties.
 * @param {Readonly<IScaleFilter>} [source]
 */
const useFormData = (source) => {
    /** @type {V.Ref<IAxisID>} */
    const axis = ref(source.key);

    /** @type {V.Ref<IScaleFilter>} */
    const cleanData = ref(ScaleFilter.clone(source));

    /** @type {V.Ref<IAxisRangeFilter>} */
    const dirtyData = ref(AxisRangeFilter.clone(cleanData.value));

    /**
     * Set the clean data.
     * @param {Readonly<IScaleFilter>} filter
     */
    const setCleanData = (filter) => {
        axis.value = filter.key;
        cleanData.value = ScaleFilter.clone(filter);
        // console.dir({ key: axis.value, ...cleanData.value });
        resetDirtyData();
    };

    /**
     * Set the dirty data.
     * @param {Readonly<IAxisRangeFilter>} filter
     */
    const setDirtyData = (filter) => {
        const data = AxisRangeFilter.clone(filter);
        data.lower = data.lower === -Infinity ? null : data.lower;
        data.upper = data.upper === Infinity ? null : data.upper;
        dirtyData.value = data;
    };

    /**
     * Reset the clean data using the Vuex $store.
     * @param {Readonly<Store<ECNBState>>} store
     */
    const resetCleanData = (store) => {
        // If invalid state values, use temperature scale defaults.
        const DefaultScaleFilterBoundsByAxisKey = Object.freeze({
            /** @type {(scale?: 'F' | 'C') => { lower: number, upper: number }} */
            T: (scale = 'F') =>
                Object.freeze({
                    lower: scale === 'F' ? 20 : -5,
                    upper: scale === 'F' ? 90 : 35,
                }),
            /** @type {(scale?: 'F' | 'C') => { lower: number, upper: number }} */
            RH: (_) =>
                Object.freeze({
                    lower: 20,
                    upper: 90,
                }),
            /** @type {(scale?: 'F' | 'C') => { lower: number, upper: number }} */
            DP: (scale = 'F') =>
                Object.freeze({
                    lower: scale === 'F' ? 10 : -5,
                    upper: scale === 'F' ? 80 : 25,
                }),
        });

        /**
         * Get the scale filter default.
         * @param {'T' | 'RH' | 'DP'} axis
         * @param {'lower' | 'upper'} key
         * @param {'F' | 'C'} scale
         */
        const getScaleFilterDefault = (axis, key, scale = 'F') => {
            return DefaultScaleFilterBoundsByAxisKey[axis]?.(scale)?.[key] ?? 0;
        };

        const AxisByKey = Object.freeze({
            temp: 'T',
            rh: 'RH',
            dp: 'DP',
        });

        /** @type {(value: unknown) => value is number} */
        const isAxisExtent = (value) =>
            !is.nullOrUndefined(value) &&
            is.number(value) &&
            !is.nan(value) &&
            !is.infinite(value);

        /**
         * Clamped temperature value.
         * @param {number} value
         * @param {'F' | 'C'} scale
         * @returns {number}
         */
        const clampedTemperature = (value, scale) => {
            const maxScale = scale === 'F' ? 200 : 100; // there's no real maximum possible temperature, but this is useful to prevent overflow.
            const minScale = scale === 'F' ? -459 : -273; // technically... can't go lower than these. absolute 0 kelvin.
            if (value > maxScale - 1) {
                return maxScale;
            } else if (value < minScale + 1) {
                return minScale;
            } else {
                return value;
            }
        };

        /**
         * Replace a scale filter's invalid entries with its default value when missing.
         * @param {IScaleFilter} source
         * @returns {IScaleFilter}
         */
        const assignWithDefaults = (source) => {
            // GET current temp scale.
            const currentTempScale = /** @type {'F' | 'C'} */ (
                store.state.accounts.account.tempScale
            );

            // CLONE existing.
            const target = ScaleFilter.clone(source);
            const axis = AxisByKey[target.key] ?? 'T';

            // REMOVE invalid.
            target.lower = isAxisExtent(target.lower) ? target.lower : null;
            target.upper = isAxisExtent(target.upper) ? target.upper : null;

            // FILL missing.
            target.lower =
                target.lower ??
                getScaleFilterDefault(axis, 'lower', currentTempScale);
            target.upper =
                target.upper ??
                getScaleFilterDefault(axis, 'upper', currentTempScale);

            // CLAMP temperatures to reasonable range.
            if (['temp', 'dp'].includes(target.key)) {
                target.lower = clampedTemperature(
                    target.lower,
                    currentTempScale
                );
                target.upper = clampedTemperature(
                    target.upper,
                    currentTempScale
                );
            }

            // CLAMP percentage to viable values.
            if (target.key === 'rh') {
                target.lower = Math.max(target.lower, 0);
                target.upper = Math.min(target.upper, 100);
            }

            return target;
        };

        /** Clone the current store's data, with `NaN` values replaced. */
        const current = assignWithDefaults(
            store.state.analysis.filters.scales[axis.value]
        );

        setCleanData(current);
    };

    /**
     * Reset the dirty data to the clean data.
     */
    const resetDirtyData = () => {
        const filter = AxisRangeFilter.clone(cleanData.value);
        setDirtyData(filter);
    };

    /**
     * Update portion of the dirty data using a partial argument.
     * @param {Readonly<Partial<IAxisRangeFilter>>} partial
     */
    const updateDirtyData = (partial) => {
        const current = AxisRangeFilter.clone(dirtyData.value);
        const updated = AxisRangeFilter.create({ ...current, ...partial });
        dirtyData.value = updated;
    };

    /**
     * Get the dirty live data as serialized, printable data.
     */
    const getFormattedData = () => {
        /** @type {{ key: String, lower: String, upper: String, checked: String | Boolean, isValidRange: Boolean }} */
        const data = {};

        // Set the key.
        data.key = axis.value;

        // Deserialize axis values.
        data.lower = AxisValue.deserialize(dirtyData.value?.lower).asLower;
        data.upper = AxisValue.deserialize(dirtyData.value?.upper).asUpper;
        data.checked = AxisModifier.parse(dirtyData.value?.checked);

        // Validate the range.
        data.isValidRange = AxisRange.validate.range({
            lower: AxisValue.serialize(data.lower).asLower,
            upper: AxisValue.serialize(data.upper).asUpper,
        });

        // Return the serialized data.
        return data;
    };

    /**
     * Get the store data as serialized, printable data.
     * @param {Store<ECNBState>} store
     */
    const getStoreData = (store) => {
        /** @type {{ key: String, lower: String, upper: String, checked: String | Boolean, isValidRange: Boolean }} */
        const data = {};

        // Set the key.
        data.key = axis.value;

        // Deserialize axis values.
        const source = store.state.analysis.filters.scales[axis.value];
        data.lower = AxisValue.deserialize(source?.lower).asLower;
        data.upper = AxisValue.deserialize(source?.upper).asUpper;
        data.checked = AxisModifier.parse(source?.checked);

        // Validate the range.
        data.isValidRange = AxisRange.validate.range({
            lower: AxisValue.serialize(data.lower).asLower,
            upper: AxisValue.serialize(data.upper).asUpper,
        });

        // Return the serialized data.
        return {
            [`${data.key}-lower`]: data.lower,
            [`${data.key}-upper`]: data.upper,
            [`${data.key}-checked`]: data.checked,
        };
    };

    /**
     * Access persist helpers that use the Vuex $store.
     * @param {Store<ECNBState>} store Reference to the $store.
     */
    const onSave = (store) => {
        return {
            /**
             * Assign range to the Vuex $store.
             * @param {Readonly<IAxisRange>} [range]
             */
            range(range) {
                // Get the previous checked value.
                const { lower = -Infinity, upper = Infinity } = range ?? {};
                const key = axis.value;
                const previous = [
                    store.state.analysis.filters.scales[key].lower,
                    store.state.analysis.filters.scales[key].upper,
                ];
                const next = [lower, upper];
                console.log(
                    `submit::${axis.value}::range`,
                    `[${previous}] => [${next}]`
                );

                // Patch existing scale.
                const scale = ScaleFilter.clone({
                    ...store.state.analysis.filters.scales[key],
                    lower,
                    upper,
                });

                // Create updated record.
                /** @type {Partial<IScaleFilterRecord>} */
                const payload = { [key]: scale };

                // Commit updates. Saves the input to the Vuex $store.
                store.commit(`analysis/patchScales`, payload);

                // Update the clean data.
                setCleanData(store.state.analysis.filters.scales[key]);

                // Reset the dirty data.
                resetDirtyData();
            },
            /**
             * Assign modifier to the Vuex $store.
             * @param {Boolean} [checked]
             */
            modifier(checked = false) {
                // Get the previous checked value.
                const key = axis.value;
                // const previous =
                //     store.state.analysis.filters.scales[key].checked;
                // const next = checked;
                // console.log(
                //     `submit::${axis.value}::modifier --- ${previous} => ${next}`
                // );

                // Patch existing scale.
                const scale = ScaleFilter.clone({
                    ...store.state.analysis.filters.scales[key],
                    checked,
                });

                // Create updated record.
                /** @type {Partial<IScaleFilterRecord>} */
                const payload = { [key]: scale };

                // Commit updates. Saves the input to the Vuex $store.
                store.commit(`analysis/patchScales`, payload);

                // Update the clean data.
                setCleanData(store.state.analysis.filters.scales[key]);

                // Reset the dirty data.
                resetDirtyData();
            },
        };
    };

    /**
     * Access input handlers.
     */
    const onInput = {
        /**
         * Handle the input from the lower input element.
         * @param {String | Number | null} [value] Input number value.
         */
        lower: (value) => {
            // Parse the string input, using the clean data value if `undefined`.
            const lower = AxisValue.parse(value, {
                whenUndefined: cleanData.value.lower,
                whenInvalid: -Infinity,
            });

            // Get the current upper.
            const upper = AxisValue.parse(dirtyData.value.upper, {
                whenUndefined: cleanData.value.upper,
                whenInvalid: Infinity,
            });

            // Check if the range is valid.
            const isRangeValid = AxisRange.validate.range({ lower, upper });

            // Get the safe range.
            const $range = AxisRange.create({
                lower,
                upper: isRangeValid ? upper : lower,
            });

            // Get the previous range.
            const $previous = AxisRange.create({
                lower: cleanData.value.lower,
                upper: cleanData.value.upper,
            });

            // Log the validation.
            // console.dir({
            //     id: axis.value,
            //     type: 'lower',
            //     previous: $previous,
            //     next: $range,
            // });

            // Get additional handlers, if successful.
            return {
                /**
                 * Save the scale axis range to the Vuex $store.
                 * @param {Store<ECNBState>} store Reference to the $store.
                 */
                onSaveRange: (store) => onSave(store).range($range),
            };
        },
        /**
         * Handle the input from the upper input element.
         * @param {String | Number | null} [value] Input number value.
         */
        upper: (value) => {
            // Parse the string input, using the clean data value if `undefined`.
            const upper = AxisValue.parse(value, {
                whenUndefined: cleanData.value.upper,
                whenInvalid: Infinity,
            });

            // Get the current lower.
            const lower = AxisValue.parse(dirtyData.value.lower, {
                whenUndefined: cleanData.value.lower,
                whenInvalid: -Infinity,
            });

            // Check if the range is valid.
            const isRangeValid = AxisRange.validate.range({ lower, upper });

            // Get the safe range.
            const $range = AxisRange.create({
                lower: isRangeValid ? lower : upper,
                upper,
            });

            // Get the previous range.
            const $previous = AxisRange.create({
                lower: cleanData.value.lower,
                upper: cleanData.value.upper,
            });

            // Log the validation.
            // console.dir({
            //     id: axis.value,
            //     type: 'upper',
            //     previous: $previous,
            //     next: $range,
            // });

            // Get additional handlers, if successful.
            return {
                /**
                 * Save the scale axis range to the Vuex $store.
                 * @param {Store<ECNBState>} store Reference to the $store.
                 */
                onSaveRange: (store) => onSave(store).range($range),
            };
        },
        /**
         * Handle the input from the checkbox input element.
         * @param {String | Boolean | null} [value] Input checkbox value.
         */
        checked: (value) => {
            // Parse the string input, using the clean data value if `undefined`.
            const $value = AxisModifier.parse(value, {
                whenUndefined: cleanData.value.checked,
                whenInvalid: false,
            });

            // Validate the input.
            const checked = $value === true;

            // Log the validation.
            // console.dir({
            //     id: axis.value,
            //     type: 'checked',
            //     previous: cleanData.value.checked,
            //     next: checked,
            // });

            // Get additional handlers, if successful.
            return {
                /**
                 * Save the scale axis range modifier to the Vuex $store.
                 * @param {Store<ECNBState>} store Reference to the $store.
                 */
                onSaveModifier: (store) => onSave(store).modifier(checked),
            };
        },
    };

    return {
        axis,

        cleanData,
        dirtyData,

        setCleanData,
        setDirtyData,
        resetCleanData,
        resetDirtyData,
        updateDirtyData,
        getFormattedData,
        getStoreData,

        onSave,
        onInput,
    };
};

/**
 * Composable that provides access to handlers for manipulating the sidebar filter input.
 * @param {Store<ECNBState>} [store]
 */
export const useScaleFilterRecord = (store = null) => {
    // ==== STATE ====
    /** @type {Store<ECNBState>} Vuex store instance. */
    const $store = store ?? useStore();

    /** @type {Readonly<ReturnType<useFormData>>} Temperature filter state and methods. */
    const T = useFormData($store.state.analysis.filters.scales.temp);

    /** @type {Readonly<ReturnType<useFormData>>} Relative Humidity filter state and methods. */
    const RH = useFormData($store.state.analysis.filters.scales.rh);

    /** @type {Readonly<ReturnType<useFormData>>} Dew Point filter state and methods. */
    const DP = useFormData($store.state.analysis.filters.scales.dp);

    /**
     * Access fomrkit input handlers.
     */
    const onInput = () => {
        /**
         * Invoked when the user inputs a `lower` bound value.
         * @param {IAxisCategoryID} id Target axis.
         * @param {String} [value] Lower value to deserialize.
         * @param {FormKitNode} [node] FormKit node, if one is provided.
         */
        const onInputLower = (id, value = '', node = undefined) => {
            // Log the input event.
            // console.dir({ id, type: 'lower', value, node });
            switch (id) {
                case 'T':
                    T.onInput.lower(value).onSaveRange($store);
                    break;
                case 'DP':
                    DP.onInput.lower(value).onSaveRange($store);
                    break;
                case 'RH':
                case 'TRH':
                    RH.onInput.lower(value).onSaveRange($store);
                    break;
            }
        };

        /**
         * Invoked when the user inputs a `upper` bound value.
         * @param {IAxisCategoryID} id Target axis.
         * @param {String} [value] Lower value to deserialize.
         * @param {FormKitNode} [node] FormKit node, if one is provided.
         */
        const onInputUpper = (id, value = '', node = undefined) => {
            // Log the input event.
            // console.dir({ id, type: 'upper', value, node });
            switch (id) {
                case 'T':
                    T.onInput.upper(value).onSaveRange($store);
                    break;
                case 'DP':
                    DP.onInput.upper(value).onSaveRange($store);
                    break;
                case 'RH':
                case 'TRH':
                    RH.onInput.upper(value).onSaveRange($store);
                    break;
            }
        };

        /**
         * Invoked when the user inputs a `checked` value.
         * @param {IAxisCategoryID} id Target axis.
         * @param {Boolean} [value] Lower value to deserialize.
         * @param {FormKitNode} [node] FormKit node, if one is provided.
         */
        const onInputChecked = (id, value = false, node = undefined) => {
            // Log the input event.
            // console.dir({ id, type: 'checked', value, node });
            switch (id) {
                case 'T':
                    T.onInput.checked(value).onSaveModifier($store);
                    break;
                case 'DP':
                    DP.onInput.checked(value).onSaveModifier($store);
                    break;
                case 'RH':
                case 'TRH':
                    RH.onInput.checked(value).onSaveModifier($store);
                    break;
            }
        };

        // COMPOSE
        const handlers = /** @type {const} */ ({
            get T() {
                const $id = /** @type {const} */ ('T');
                return {
                    /**
                     * Handle the input for the specified filter axis and component.
                     * @param {String} [value] Value being input for the filter component.
                     * @param {FormKitNode} [node] FormKit input node.
                     */
                    lower: (value = '', node = undefined) =>
                        onInputLower($id, value, node),
                    /**
                     * Handle the input for the specified filter axis and component.
                     * @param {String} [value] Value being input for the filter component.
                     * @param {FormKitNode} [node] FormKit input node.
                     */
                    upper: (value = '', node = undefined) =>
                        onInputUpper($id, value, node),
                    /**
                     * Handle the input for the specified filter axis and component.
                     * @param {Boolean} [value] Value being input for the filter component.
                     * @param {FormKitNode} [node] FormKit input node.
                     */
                    checked: (value = false, node = undefined) =>
                        onInputChecked($id, value, node),
                };
            },
            get RH() {
                const $id = /** @type {const} */ ('RH');
                return {
                    /**
                     * Handle the input for the specified filter axis and component.
                     * @param {String} [value] Value being input for the filter component.
                     * @param {FormKitNode} [node] FormKit input node.
                     */
                    lower: (value = '', node = undefined) =>
                        onInputLower($id, value, node),
                    /**
                     * Handle the input for the specified filter axis and component.
                     * @param {String} [value] Value being input for the filter component.
                     * @param {FormKitNode} [node] FormKit input node.
                     */
                    upper: (value = '', node = undefined) =>
                        onInputUpper($id, value, node),
                    /**
                     * Handle the input for the specified filter axis and component.
                     * @param {Boolean} [value] Value being input for the filter component.
                     * @param {FormKitNode} [node] FormKit input node.
                     */
                    checked: (value = false, node = undefined) =>
                        onInputChecked($id, value, node),
                };
            },
            get DP() {
                const $id = /** @type {const} */ ('DP');
                return {
                    /**
                     * Handle the input for the specified filter axis and component.
                     * @param {String} [value] Value being input for the filter component.
                     * @param {FormKitNode} [node] FormKit input node.
                     */
                    lower: (value = '', node = undefined) =>
                        onInputLower($id, value, node),
                    /**
                     * Handle the input for the specified filter axis and component.
                     * @param {String} [value] Value being input for the filter component.
                     * @param {FormKitNode} [node] FormKit input node.
                     */
                    upper: (value = '', node = undefined) =>
                        onInputUpper($id, value, node),
                    /**
                     * Handle the input for the specified filter axis and component.
                     * @param {Boolean} [value] Value being input for the filter component.
                     * @param {FormKitNode} [node] FormKit input node.
                     */
                    checked: (value = false, node = undefined) =>
                        onInputChecked($id, value, node),
                };
            },
        });

        // EXPOSE
        return {
            ...handlers,
        };
    };

    /** Scales API. */
    const scales = /** @type {const} */ ({
        T,
        RH,
        DP,
        onInput,
        /** Persist current form data to the Vuex store contents. */
        saveFormData: async () => {
            // Get all the form data.
            const temp = ScaleFilter.temp(T.dirtyData.value);
            const rh = ScaleFilter.temp(RH.dirtyData.value);
            const dp = ScaleFilter.temp(DP.dirtyData.value);
            const record = ScaleFilterRecord.create([temp, rh, dp]);
            // Commit the form data record.
            return await $store.dispatch(`analysis/assignScaleRecord`, record);
        },
    });

    // EXPOSE
    return {
        store: $store,
        scales,
    };
};

export default useScaleFilterRecord;
