// <!-- UTILITIES -->
import { ref } from 'vue';
import { resolveRef, isDefined } from '@vueuse/core';

// <!-- TYPES -->
/**
 * @typedef UseAgGridPaginationOptions
 * @prop {boolean | V.Ref<boolean>} [enabled] Possibly reactive value controlling pagination enabled status.
 * @prop {number | V.Ref<number>} [size] Possibly reactive value controlling page size.
 */

/**
 * @typedef UseAgGridPaginationReturn
 * @prop {Readonly<V.Ref<boolean>>} pagination Reactive pagination enabled status.
 * @prop {Readonly<V.Ref<number>>} paginationPageSize Reactive page size.
 */

/**
 * Define reactive pagination settings.
 * @param {UseAgGridPaginationOptions} [props]
 * @returns {UseAgGridPaginationReturn}
 */
export const useAgGridPagination = (props = {}) => {
    /** @type {V.Ref<boolean> | V.ComputedRef<boolean>} */
    const pagination = isDefined(props.enabled)
        ? resolveRef(props.enabled)
        : ref(false);

    /** @type {V.Ref<number> | V.ComputedRef<number>} */
    const paginationPageSize = isDefined(props.size)
        ? resolveRef(props.size)
        : ref(10);

    return {
        pagination,
        paginationPageSize,
    };
};

// <!-- DEFAULT -->
export default useAgGridPagination;
