<template>
    <div class="flex flex-row min-w-screen min-h-screen h-screen">
        <div class="flex-none h-full">
            <SideBar
                :currentFilterType="currentFilterType"
                @click:zoom="$emit('click:zoom', $event)"
            >
                <slot name="sideBar"></slot>
            </SideBar>
        </div>

        <div
            class="flex-1 min-h-screen overflow-y-auto print:overflow-y-visible h-full"
        >
            <SubNav
                :subTabs="tabs"
                :includeExternalLinks="true"
            />
            <RightContainer class="sm:hidden block">
                <PageTitle></PageTitle>
            </RightContainer>
            <slot name="mainContent">T2</slot>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';
    import { useRouter } from 'vue-router';

    // <!-- COMPONENTS -->
    import SideBar from '@/components/SideBar.vue';
    import SubNav from '@/components/SubNav.vue';
    import PageTitle from '@components/PageTitle.vue';
    import RightContainer from '@components/RightContainer.vue';

    // <!-- TYPES -->
    /** @typedef {import('@/features/analysis/hooks/useAnalysisChart').AnalysisChartConstants['GraphTypes'][number]} GraphType */
    /** @typedef {Extract<GraphType, 'T' | 'RH' | 'TRH' | 'DP'> | 'all' | ''} FilterType */

    // <!-- CONSTANTS -->
    /** Tabs for the main container. */
    const tabs = [
        { name: 'Graph', to: '/analysis', current: false },
        { name: 'Statistics', to: '/analysis/statistics', current: false },
        { name: 'Compare Metrics', to: '/analysis/compare', current: false },
        {
            name: 'NARA Standards',
            to: '/analysis/narastandards',
            current: false,
        },
        { name: 'Reports', to: '/analysis/reports', current: false },
    ];

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'AnalysisContainer',
        components: {
            SideBar,
            SubNav,
            PageTitle,
            RightContainer,
        },
        emits: [
            'click:zoom',
            'nodeCheckAll',
            'nodeCheckNone',
            'nodeChecked',
            'nodeUnchecked',
            'update:start',
            'update:end',
            'update:range',
        ],
        props: {
            /** Filter type. */
            currentFilterType: {
                /** @type {V.PropType<FilterType>} */
                type: /** @type {any} */ (String),
                default: 'all',
            },
        },
        setup(props, context) {
            // SERVICES
            const router = useRouter();

            // METHODS
            /**
             * Invoked when the component is created, at the end of the setup()
             * function call.
             */
            const onCreated = () => {
                // Update the current tab.
                for (let tabObj of tabs) {
                    if (tabObj.to === router.currentRoute.value.path) {
                        tabObj.current = true;
                    } else {
                        tabObj.current = false;
                    }
                }
            };

            // LIFECYCLE
            // Update the current tab.
            onCreated();

            // ==== EXPOSE ====
            return {
                tabs,
            };
        },
    });
</script>

<style scoped>
    .main-section {
        position: relative;
    }

    @media print {
        .main-section {
            height: auto;
        }
    }
</style>
