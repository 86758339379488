// <!-- API -->
import { ref } from 'vue';
import {
    fetchComponents,
    fetchAccountTemplates,
    createAccountTemplate,
    deleteAccountTemplateById,
} from '@/api/reports';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import {
    useArraySome,
    useConfirmDialog,
    createEventHook,
    useArrayFind,
} from '@vueuse/core';
import { computed } from 'vue';

// <!-- MODELS -->
import { CustomReportComponent, CustomReportTemplate } from '@/models/reports';
import compare from 'just-compare';

// <!-- TYPES -->

/**
 * @typedef UseReportTemplateModalsReturn
 * @prop {import('@vueuse/core').UseConfirmDialogReturn<{ components: CustomReportComponent[] }, any, { error?: unknown }>} SaveTemplateAs
 * @prop {import('@vueuse/core').UseConfirmDialogReturn<{ target: CustomReportTemplate }, any, { error?: unknown }>} ConfirmDeleteTemplate
 */

/**
 * Define modal behaviour.
 * @returns {UseReportTemplateModalsReturn}
 */
const useReportTemplateModals = () => {
    const SaveTemplateAs = useConfirmDialog();
    const ConfirmDeleteTemplate = useConfirmDialog();
    return {
        SaveTemplateAs,
        ConfirmDeleteTemplate,
    };
};

/**
 * @typedef UseDataReturn
 * @prop {UseDataReturnComponents} components Reactive component data properties.
 * @prop {UseDataReturnTemplates} templates Reactive template data properties.
 * @prop {V.Ref<string>} saveTemplateAs Reactive template name for the modal form.
 * @prop {V.ComputedRef<CustomReportTemplate>} currentTemplate The current selected template, if one is present. Else, `undefined`.
 * @prop {V.ComputedRef<boolean>} isFetching When `true`, a resource is fetching.
 * @prop {V.ComputedRef<boolean>} isSaving When `true`, a template resource is being created or destroyed.
 * @prop {V.ComputedRef<boolean>} isBusy When `true`, some operation is running.
 */

/**
 * @typedef UseDataReturnComponents
 * @prop {V.Ref<CustomReportComponent[]>} data Collection of available, selectable components.
 * @prop {V.Ref<unknown>} error Contains error details, if present.
 * @prop {V.Ref<boolean>} fetching Mutable reference that indicates components are being fetched.
 * @prop {V.ComputedRef<boolean>} isMissing When `true`, no components are available.
 * @prop {V.ComputedRef<boolean>} isError When `true`, an error is present.
 * @prop {import('@vueuse/core').EventHook<{ components: CustomReportComponent[], error?: unknown }>} fetch Triggered after available components are fetched.
 * @prop {import('@vueuse/core').EventHook<{ components: CustomReportComponent[], selected: number[], error?: unknown }>} change Triggered after a component select or deselect event.
 * @prop {import('@vueuse/core').EventHook<{ error?: unknown }>} reset Triggers the reset operation (for components).
 */

/**
 * @typedef UseDataReturnTemplates
 * @prop {V.Ref<CustomReportTemplate[]>} data Collection of available, copyable templates.
 * @prop {V.Ref<number>} selected Mutable reference that specifies the last selected template.
 * @prop {V.Ref<unknown>} error Contains error details, if present.
 * @prop {V.Ref<boolean>} fetching Mutable reference that indicates templates are being fetched.
 * @prop {V.Ref<boolean>} creating Mutable reference that indicates a template is being created.
 * @prop {V.Ref<boolean>} destroying Mutable reference that indicates a template is being destroyed.
 * @prop {V.ComputedRef<boolean>} isMissing When `true`, no templates are available.
 * @prop {V.ComputedRef<boolean>} isSelected When `true`, a template is selected.
 * @prop {V.ComputedRef<boolean>} isError When `true`, an error is present.
 * @prop {import('@vueuse/core').EventHook<{ templates: CustomReportTemplate[], error?: unknown }>} fetch Triggered after account templates are fetched.
 * @prop {import('@vueuse/core').EventHook<{ template: CustomReportTemplate, error?: unknown }>} select Triggered after a template is selected.
 * @prop {import('@vueuse/core').EventHook<{ template: CustomReportTemplate, error?: unknown }>} create Triggered after a template is created.
 * @prop {import('@vueuse/core').EventHook<{ template: CustomReportTemplate, error?: unknown }>} destroy Triggered after a template is destroyed.
 * @prop {import('@vueuse/core').EventHook<{ error?: unknown }>} deselect Triggers the deselect operation (for templates).
 */

/** Define the reactive data references. */
const useReportTemplateData = () => {
    /** @type {UseDataReturn['components']} */
    const components = {
        data: ref([]),
        error: ref(null),
        fetching: ref(false),
        isMissing: computed(() => components.data.value.length === 0),
        isError: computed(() => !!components.error.value),
        fetch: createEventHook(),
        change: createEventHook(),
        reset: createEventHook(),
    };

    /** @type {UseDataReturn['templates']} */
    const templates = {
        data: ref([]),
        selected: ref(null),
        error: ref(null),
        fetching: ref(false),
        creating: ref(false),
        destroying: ref(false),
        isMissing: computed(() => templates.data.value.length === 0),
        isSelected: computed(
            () => !!templates.selected.value || templates.selected.value === 0
        ),
        isError: computed(() => !!templates.error.value),
        fetch: createEventHook(),
        select: createEventHook(),
        create: createEventHook(),
        destroy: createEventHook(),
        deselect: createEventHook(),
    };

    /** @type {V.Ref<string>} */
    const saveTemplateAs = ref('New Template');

    /** Computed property that returns the current selected template, if one is present. Otherwise, returns `undefined`. */
    const currentTemplate = useArrayFind(
        templates.data,
        (t) => t.id === templates.selected.value
    );

    /** Computed property that indicates if one or more resources are currently being fetched. */
    const isFetching = useArraySome(
        [components.fetching, templates.fetching],
        (condition) => condition === true
    );

    /** Computed property that indicates if one or more resources are currently being saved. */
    const isSaving = useArraySome(
        [templates.creating, templates.destroying],
        (condition) => condition === true
    );

    /** Computed property that indicates if one or more resources are currently being fetched or saved. */
    const isBusy = useArraySome(
        [isFetching, isSaving],
        (condition) => condition === true
    );

    return {
        components,
        templates,
        saveTemplateAs,
        currentTemplate,
        isFetching,
        isSaving,
        isBusy,
    };
};

/**
 * @typedef {{ components: Pick<UseDataReturnComponents, 'data' | 'change'>, templates: Pick<UseDataReturnTemplates, 'deselect'> }} UseReportTemplateOptions
 */

/**
 * @typedef UseReportTemplateGrid
 * @prop {AgGrid.ColumnDef<CustomReportComponent>} defaultColumnDef Default column definition.
 * @prop {AgGrid.GetRowNodeIdFunc} getRowNodeIdGetter Getter for the row node id.
 * @prop {(params: AgGrid.Events.GridReadyEvent<CustomReportComponent>, source: 'left' | 'right') => void} onDualListBoxReady Store the grid api for the loaded side.
 * @prop {(params: AgGrid.Events.RowDragEndEvent<CustomReportComponent>, source: 'left' | 'right') => void} onDragEndEvent Handle row dropped over own grid.
 * @prop {UseReportTemplateGridBox<'left'>} left Left grid box properties.
 * @prop {UseReportTemplateGridBox<'right'>} right Right grid box properties.
 */

/**
 * @template {'left' | 'right'} [DualListBoxSide='left'|'right']
 * @typedef UseReportTemplateGridBox
 * @prop {DualListBoxSide} side API side.
 * @prop {V.Ref<AgGrid.GridApi<CustomReportComponent>>} api AgGrid grid api reference.
 * @prop {V.Ref<CustomReportComponent[]>} rowData Row data to display.
 * @prop {AgGrid.ColumnDef<CustomReportComponent>[]} columnDefs Column definitions describing the grid.
 * @prop {V.ComputedRef<boolean>} isEmpty When `true`, there is no row data available.
 */

/**
 * Define the AgGrid properties for the dual-list box.
 * @param {UseReportTemplateOptions} params
 * @return {UseReportTemplateGrid}
 */
const useReportTemplateGrid = (params) => {
    const { data, change } = params.components;
    const { deselect } = params.templates;
    /** @type {AgGrid.ColumnDef} */
    const defaultColumnDef = {
        flex: 1,
        sortable: true,
        cellClass: 'flex items-center leading-5 text-left break-normal',
        filter: true,
        resizable: true,
    };
    /** @type {UseReportTemplateGridBox<'left'>} */
    const left = {
        side: 'left',
        api: ref(null),
        rowData: ref([]),
        columnDefs: [
            {
                headerName: 'Components',
                field: 'name',
                rowDrag: true,
                cellClass: 'flex items-center',
            },
        ],
        isEmpty: computed(() => left.rowData.value.length === 0),
    };
    /** @type {UseReportTemplateGridBox<'right'>} */
    const right = {
        side: 'right',
        api: ref(null),
        rowData: ref([]),
        columnDefs: [
            {
                headerName: 'Selected Components',
                field: 'name',
                rowDrag: true,
                cellClass: 'flex items-center',
            },
        ],
        isEmpty: computed(() => right.rowData.value.length === 0),
    };
    /**
     * Add dropzone to handle deselection of components
     * when dragging from right to left.
     */
    const useDeselectionDropZone = () => {
        // GET the source dropzone.
        const source = right;
        // GET the target dropzone.
        const target = left;
        // ATTACH dropzone to the source.
        source.api.value.addRowDropZone(
            target.api.value.getRowDropZoneParams({
                onDragStop: (params) => {
                    /** @type {AgGrid.RowNode<CustomReportComponent>[]} */
                    const nodes = params.nodes;
                    // GET the dropped component.
                    const id = nodes[0].data.id;
                    // DESELECT component by id.
                    onComponentDeselected(id);
                },
            })
        );
    };
    /**
     * Add dropzone to handle deselection of components
     * when dragging from right to left.
     */
    const useSelectionDropZone = () => {
        // GET the source dropzone.
        const source = left;
        // GET the target dropzone.
        const target = right;
        // ATTACH dropzone to the source.
        source.api.value.addRowDropZone(
            target.api.value.getRowDropZoneParams({
                onDragStop: (params) => {
                    /** @type {AgGrid.RowNode<CustomReportComponent>[]} */
                    const nodes = params.nodes;
                    // GET the dropped component.
                    const id = nodes[0].data.id;
                    // SELECT component by id.
                    onComponentSelected(id);
                },
            })
        );
    };
    /** @type {(id: number) => void} */
    const onComponentDeselected = (id) => {
        console.log(`[deselect::component]`, id);
        // REMOVE data from the `right` API.
        right.api.value.forEachNode(function (node) {
            if (node.data.id === id) {
                const row = node.data;
                right.api.value.applyTransaction({
                    remove: [row],
                });
            }
        });
        // UPDATE the grid.
        onDualListBoxUpdate();
    };
    /** @type {(id: number) => void} */
    const onComponentSelected = (id) => {
        console.log(`[select::component]`, id);
        // REMOVE data from the `left` API.
        left.api.value.forEachNode(function (node) {
            if (node.data.id === id) {
                const row = node.data;
                left.api.value.applyTransaction({
                    remove: [row],
                });
            }
        });
        // UPDATE the grid.
        onDualListBoxUpdate();
    };
    /** Update the left list box row data. */
    const onLeftListBoxUpdate = () => {
        /** @type {AgGrid.RowNode<CustomReportComponent>[]} */
        const nodes = [];
        left.api.value.forEachNode((node) => {
            // console.log('[left]', node.data);
            nodes.push(node);
        });
        left.rowData.value = nodes.map((n) =>
            CustomReportComponent.withPosition(n.data, null)
        );
    };
    /** Update the right list box row data. */
    const onRightListBoxUpdate = () => {
        /** @type {AgGrid.RowNode<CustomReportComponent>[]} */
        const nodes = [];
        right.api.value.forEachNode((node) => {
            // console.log('[right]', node.data);
            nodes.push(node);
        });
        right.rowData.value = nodes.map((n, index) =>
            CustomReportComponent.withPosition(n.data, index + 1)
        );
    };
    /** Update the grid row data. */
    const onDualListBoxUpdate = () => {
        if (left.api.value != null && right.api.value != null) {
            onLeftListBoxUpdate();
            onRightListBoxUpdate();
            change.trigger({
                components: data.value,
                selected: right.rowData.value.map((c) => c.id),
            });
        }
        // console.log('[right]', right.rowData.value);
        // console.log('[left]', left.rowData.value);
    };
    /** @type {UseReportTemplateGrid['onDualListBoxReady']} */
    const onDualListBoxReady = (params, source) => {
        // SELECT the api to be assigned.
        const { api } = params;

        // ASSIGN the dual list box side.
        switch (source) {
            case left.side:
                left.api.value = api;
                break;
            case right.side:
                right.api.value = api;
                break;
        }

        // ADD GRID DROP ZONE if both sides are present.
        if (left.api.value && right.api.value) {
            // addGridDropZone(left);
            // addGridDropZone(right);
            useSelectionDropZone();
            useDeselectionDropZone();
        }

        // SIZE columns to fit.
        api.sizeColumnsToFit();
    };
    /** @type {UseReportTemplateGrid['onDragEndEvent']} */
    const onDragEndEvent = (params, source) => {
        const previous = {
            left: left.rowData.value.map((c) => c.id),
            right: right.rowData.value.map((c) => c.id),
        };
        const data = [];
        // Handle the drag end event when dropped on self.
        switch (source) {
            case left.side:
                params.api.forEachNode((node) => {
                    const component = CustomReportComponent.withPosition(
                        node.data,
                        null
                    );
                    data.push(component);
                });
                const isLeftDirty = !compare(
                    previous.left,
                    data.map((c) => c.id)
                );
                left.rowData.value = data;
                if (isLeftDirty) {
                    console.log(`[drag::end::left]`, left.rowData.value);
                }
                break;
            case right.side:
                params.api.forEachNode((node, index) => {
                    const position = index + 1;
                    const component = CustomReportComponent.withPosition(
                        node.data,
                        position
                    );
                    data.push(component);
                });
                const isRightDirty = !compare(
                    previous.right,
                    data.map((c) => c.id)
                );
                right.rowData.value = data;
                if (isRightDirty) {
                    // DESELECT template, if one was present.
                    deselect.trigger({});
                    console.log(`[drag::end::right]`, right.rowData.value);
                }
                break;
        }
    };
    /** @type {UseReportTemplateGrid['getRowNodeIdGetter']} */
    const getRowNodeIdGetter = (data) => {
        return data?.id ?? data?.data?.id;
    };
    // EXPOSE
    return {
        left,
        right,
        defaultColumnDef,
        onDualListBoxReady,
        onDragEndEvent,
        getRowNodeIdGetter,
    };
};

/**
 * Define reusable behavior for saving, deleting, and editing report templates.
 */
export const useReportTemplate = () => {
    /** Record containing reactive references with domain data. */
    const data = useReportTemplateData();
    const { components, templates, saveTemplateAs } = data;

    // DEFINE component event handlers.
    const onComponentsFetched = components.fetch.on;
    const onSelectionChanged = components.change.on;
    const onSelectionReset = components.reset.on;

    // DEFINE template event handlers.
    const onTemplatesFetched = templates.fetch.on;
    const onTemplateSelected = templates.select.on;
    const onTemplateDeselected = templates.deselect.on;
    const onTemplateCreated = templates.create.on;
    const onTemplateDestroyed = templates.destroy.on;

    /** Record containing grid data. */
    const grids = useReportTemplateGrid(data);

    /** Modals used on the report template section. */
    const modals = useReportTemplateModals();

    // DEFINE modal event handlers.
    const onSaveTemplateRevealed = modals.SaveTemplateAs.onReveal;
    const onSaveTemplateCanceled = modals.SaveTemplateAs.onCancel;
    const onSaveTemplateConfirmed = modals.SaveTemplateAs.onConfirm;
    const onDeleteTemplateRevealed = modals.ConfirmDeleteTemplate.onReveal;
    const onDeleteTemplateCanceled = modals.ConfirmDeleteTemplate.onCancel;
    const onDeleteTemplateConfirmed = modals.ConfirmDeleteTemplate.onConfirm;

    // ALIASES

    /** Is the (left) selectable component box empty? */
    const isComponentListEmpty = grids.left.isEmpty;

    /** Is the (right) selected components box empty? */
    const isSelectedComponentListEmpty = grids.right.isEmpty;

    // METHODS

    /**
     * Create default template name.
     * @param {string} prefix
     * @param {CustomReportComponent[]} components
     */
    const generateTemplateName = (prefix, components) => {
        // MAX size.
        const max = 3;
        // OVERFLOW
        const truncated = components.length > max;
        // LIMIT components to first 3.
        const items = components.slice(0, max);
        // USE CODES
        const codes = items.map((c) => c.code);
        // ADD SUFFIX, if present.
        if (truncated) {
            codes.push('...');
        }
        // FORMAT name.
        return `${prefix} (${codes.join('-')} [${components.length}])`;
    };

    /** Reset the current selection. */
    const resetSelection = () => {
        components.reset.trigger({});
    };

    /** Initialize the dual list box data. */
    const initializeDualListBox = () => {
        grids.left.rowData.value = [...data.components.data.value];
        grids.right.rowData.value = [];
        templates.deselect.trigger({});
    };

    /** Fetch the available components that a user can select. */
    const fetchCustomReportComponents = async () => {
        try {
            // BEGIN fetching.
            components.fetching.value = true;
            // FETCH components from the backend.
            const response = await fetchComponents();
            if (!!response.error) {
                throw response.error;
            }
            // HANDLE response.
            components.fetch.trigger({ components: response.data });
        } catch (e) {
            // HANDLE error.
            components.fetch.trigger({ components: [], error: e });
        } finally {
            // STOP fetching.
            components.fetching.value = false;
        }
    };

    /**
     * Fetch the availble templates present in the database.
     * @param {{ account: number }} params
     */
    const fetchCustomReportTemplates = async (params) => {
        try {
            // BEGIN fetching.
            templates.fetching.value = true;
            // FETCH templates from the backend.
            const response = await fetchAccountTemplates(params);
            if (!!response.error) {
                throw response.error;
            }
            // HANDLE response.
            templates.fetch.trigger({ templates: response.data });
        } catch (e) {
            // HANDLE error.
            templates.fetch.trigger({ templates: [], error: e });
        } finally {
            // STOP fetching.
            templates.fetching.value = false;
        }
    };

    /**
     * Handle the save template as confirmation dialog.
     * @param {{ account: number }} params
     */
    const createCustomReportTemplate = async (params) => {
        try {
            templates.creating.value = true;
            const components = [...grids.right.rowData.value];
            saveTemplateAs.value = generateTemplateName(
                'New Template',
                components
            );
            const { isCanceled } = await modals.SaveTemplateAs.reveal({
                components,
            });
            if (isCanceled === true) {
                // HANDLE cancellation.
                templates.create.trigger({
                    template: null,
                    error:
                        data?.error ??
                        new Error(
                            `Operation cancelled. No template was saved.`
                        ),
                });
                return;
            }
            // GET template name.
            const name = saveTemplateAs.value;
            // GET template components.
            const ids = CustomReportComponent.sort(components).map((c) => c.id);
            // CREATE the new template.
            const response = await createAccountTemplate(params, {
                name,
                components: ids,
            });
            if (!!response.error) {
                throw response.error;
            }
            // HANDLE response.
            templates.create.trigger({ template: response.data });
        } catch (e) {
            // HANDLE error.
            templates.create.trigger({ template: null, error: e });
        } finally {
            templates.creating.value = false;
        }
    };

    /**
     * Handle the delete template confirmation dialog.
     * @param {{ account: number, target: CustomReportTemplate }} params
     */
    const destroyCustomReportTemplate = async (params) => {
        try {
            templates.destroying.value = true;
            const { isCanceled } = await modals.ConfirmDeleteTemplate.reveal(
                params
            );
            if (isCanceled === true) {
                // HANDLE cancellation.
                templates.destroy.trigger({
                    template: null,
                    error:
                        data?.error ??
                        new Error(
                            `Operation cancelled. No template was destroyed.`
                        ),
                });
                return;
            }
            // DELETE the template.
            const response = await deleteAccountTemplateById({
                ...params,
                id: params.target?.id,
            });
            if (!!response.error) {
                throw response.error;
            }
            // HANDLE response.
            templates.destroy.trigger({ template: params.target });
        } catch (e) {
            // HANDLE error.
            templates.destroy.trigger({ template: null, error: e });
        } finally {
            // STOP destroying.
            templates.destroying.value = false;
        }
    };

    /**
     * Select a custom report template using the button prompt.
     * @param {number} id Unique template id.
     */
    const selectCustomReportTemplate = async (id) => {
        // VALIDATE id.
        const isInvalid =
            id === null ||
            id === undefined ||
            !!templates.isMissing.value ||
            !templates.data.value.map((t) => t.id).includes(id);
        if (isInvalid) {
            data.templates.select.trigger({
                template: null,
                error: new Error(
                    `Cannot select non-existent template id: ${id}.`
                ),
            });
            return;
        }
        // ASSIGN valid identifier.
        templates.selected.value = id;
        // GET selected template.
        const template = data.currentTemplate.value;
        // TRIGGER update after selection.
        data.templates.select.trigger({ template });
    };

    // LIFECYCLE

    // Handle fetched components.
    onComponentsFetched(({ components, error = null }) => {
        data.components.fetching.value = false;
        if (!!error) {
            // TODO: Do something special on error?
            console.error(`[fetch::components]`, error);
            return;
        }
        console.log(`[fetch::components]`, components);
        data.components.data.value = [...components];
    });

    // Handle changed component selection.
    onSelectionChanged(({ components, selected, error = null }) => {
        if (!!error) {
            // TODO: Do something special on error?
            console.error(`[change::selection]`, error);
            return;
        }
        // DESELECT template, if one was present.
        templates.deselect.trigger({});
        console.log(
            `[change::selection]`,
            selected
                .map((id) => components.find((c) => c.id === id))
                .map(
                    (c, index) =>
                        new CustomReportComponent({ ...c, position: index + 1 })
                )
        );
    });

    // Handle reset component selection.
    onSelectionReset(({ error = null }) => {
        if (!!error) {
            // TODO: Do something special on error?
            console.error(`[reset::selection]`, error);
            return;
        }
        // DESELECT template, if one was present.
        templates.deselect.trigger({});
        initializeDualListBox();
        console.log(`[reset::selection]`, {
            left: grids.left.rowData.value,
            right: grids.right.rowData.value,
            template: data.currentTemplate.value,
        });
    });

    // Handle fetched templates.
    onTemplatesFetched(({ templates, error = null }) => {
        data.templates.fetching.value = false;
        if (!!error) {
            // TODO: Do something special on error?
            console.error(`[fetch::templates]`, error);
            return;
        }
        console.log(`[fetch::templates]`, templates);
        data.templates.data.value = [...templates];
    });

    // Handle selected template.
    onTemplateSelected(({ template, error = null }) => {
        if (!!error) {
            // TODO: Do something special on error?
            console.error(`[select::template]`, error);
            return;
        }
        console.log(`[select::template]`, template);
        // GET all available components.
        const source = data.components.data.value;
        // GET the sorted keys of the template components.
        const ids = template.keys;
        // GET the left list box rows (unselected components).
        const unselected = source.filter(
            (component) => !ids.includes(component.id)
        );
        // GET the right list box rows (selected components).
        const selected = CustomReportComponent.sort(template.components);
        // ASSIGN components.
        grids.left.rowData.value = unselected;
        grids.right.rowData.value = selected;
    });

    // Handle deselected template.
    onTemplateDeselected(({ error = null }) => {
        if (!!error) {
            // TODO: Do something special on error?
            console.error(`[deselect::template]`, error);
            return;
        }
        console.log(`[deselect::template]`, data.currentTemplate.value);
        if (!!templates.isSelected.value) {
            templates.selected.value = null;
        }
    });

    // Handle created template.
    onTemplateCreated(({ template, error = null }) => {
        if (!!error) {
            // TODO: Do something special on error?
            console.error(`[create::template]`, error);
            return;
        }
        console.log(`[create::template]`, template);
        // UPDATE the collection with the new template.
        const collection = [...templates.data.value, template];
        const sortedKeys = new Set(
            collection.map((t) => t.id).sort((a, b) => a - b)
        );
        // SORTED keys to template definitions.
        templates.data.value = Array.from(sortedKeys, (id) =>
            collection.find((t) => t.id === id)
        );
        // SELECT the created template.
        selectCustomReportTemplate(template.id);
    });

    // Handle destroyed template.
    onTemplateDestroyed(({ template, error = null }) => {
        if (!!error) {
            // TODO: Do something special on error?
            console.error(`[destroy::template]`, error);
            return;
        }
        console.log(`[destroy::template]`, template);
        // DESELECT destroyed template if it matches the current template.
        if (
            templates.isSelected.value &&
            templates.selected.value === template.id
        ) {
            templates.deselect.trigger({});
        }
        // UPDATE the collection with the template removed.
        const collection = templates.data.value;
        templates.data.value = collection.filter((t) => t.id !== template.id);
    });

    // EXPOSE
    return {
        data,
        grids,
        modals,
        isComponentListEmpty,
        isSelectedComponentListEmpty,
        resetSelection,
        initializeDualListBox,
        fetchCustomReportComponents,
        fetchCustomReportTemplates,
        createCustomReportTemplate,
        destroyCustomReportTemplate,
        selectCustomReportTemplate,
    };
};

// <!-- DEFAULT -->
export default useReportTemplate;
