// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';

// <!-- ROUTES -->
const ROUTES = {
    LOCATION_STANDARDS_INDEX: (account) => `accounts/${account}/standards`,
    LOCATION_STANDARDS: (account, location) =>
        `accounts/${account}/locations/${location}/standards`,
    LOCATION_STANDARDS_METRICS: (account, location) =>
        `accounts/${account}/locations/${location}/standards/metrics`,
};

// <!-- PROPERTIES -->

/** @typedef {{ account: Pick<import('@/models/accounts/Account').AccountResource, 'id'> }} AccountKeyProperty */
/** @typedef {{ location: Pick<import('@/models/locations/Location').LocationResource, 'id'> }} LocationKeyProperty */

/** @typedef {{ start_time: integer }} StartTimeProperty */
/** @typedef {{ end_time: integer }} EndTimeProperty */
/** @typedef {Combine<StartTimeProperty & EndTimeProperty>} TimePeriodProperty */

/** @typedef {{ account: { id: integer, temperature_scale: 'F' | 'C' } }} AccountMetadataProperty */
/** @typedef {{ location: Partial<{ id: integer, name: string, min_date: string, max_date: string, minDate?: Date|integer, maxDate?: Date|integer, path?: string, label?: string }> }} LocationMetadataProperty */

/** @typedef {{ id: integer, name: string, description: string }} StandardsMetadataProperty */
/** @typedef {{ min_temp: integer, max_temp: integer }} TemperatureLimitsProperty */
/** @typedef {{ min_rh: integer, max_rh: integer }} RelativeHumidityLimitsProperty */
/** @typedef {Combine<StandardsMetadataProperty & TemperatureLimitsProperty & RelativeHumidityLimitsProperty>} StandardsProperty */

/** @typedef {{ records: integer }} RecordCountProperty */
/** @typedef {{ temp_below_count: integer, temp_above_count: integer, temp_in_count: integer }} TemperatureCountProperty */
/** @typedef {{ rh_below_count: integer, rh_above_count: integer, rh_in_count: integer }} RelativeHumidityCountProperty */
/** @typedef {{ temp_below_percent: number, temp_above_percent: number, temp_in_percent: number }} TemperaturePercentProperty */
/** @typedef {{ rh_below_percent: number, rh_above_percent: number, rh_in_percent: number }} RelativeHumidityPercentProperty */
/** @typedef {{ filter_start: string, filter_end: string }} FilterPeriodProperty */
/** @typedef {Combine<RecordCountProperty & TemperatureCountProperty & TemperaturePercentProperty & RelativeHumidityCountProperty & RelativeHumidityPercentProperty & FilterPeriodProperty>} StandardsMetricsProperty */

// <!-- REQUESTS -->

/** @typedef {Combine<AccountKeyProperty & LocationKeyProperty>} LocationStandardsRequest */
/** @typedef {Combine<AccountKeyProperty & LocationKeyProperty & TimePeriodProperty>} LocationDistributionsRequest */

// <!-- RESPONSES -->

/** @typedef {Combine<AccountMetadataProperty & LocationMetadataProperty & { standard?: StandardsProperty }>} LocationStandardsResponse */
/** @typedef {Combine<AccountMetadataProperty & LocationMetadataProperty & { standard?: StandardsProperty } & { metrics: StandardsMetricsProperty } & { hasStandard?: boolean }>} LocationDistributionsResponse */

// <!-- HELPERS -->

/**
 * Resolve the location data period.
 *
 * @param {LocationStandardsResponse} payload
 */
const asLocationStandardsResource = (payload) => {
    // CLONE the incoming payload.
    const resource = {
        ...payload,
    };

    // UPDATE the dates.
    resource.location.minDate = new Date(payload.location.minDate);
    resource.location.maxDate = new Date(payload.location.maxDate);

    // RETURN hydrated resource.
    return resource;
};

/**
 * Resolve the location data period.
 *
 * @param {LocationDistributionsResponse} payload
 */
const asLocationDistributionsResource = (payload) => {
    // CLONE the incoming payload.
    const resource = {
        ...payload,
        get hasStandard() {
            return !!this.standard && this.standard.id >= 0;
        },
    };

    // UPDATE the dates.
    resource.location.minDate = new Date(payload.location.minDate);
    resource.location.maxDate = new Date(payload.location.maxDate);

    // RETURN hydrated resource.
    return resource;
};

// <!-- ACTIONS -->

/**
 * Get the index of all Location Standards.
 *
 * @param {LocationStandardsRequest['account']} account Account key parameter.
 * @param {import('axios').AxiosRequestConfig} config Enables additional configuration of the axios request, including abort signal handling.
 */
export const fetchLocationStandards = async (account, config) => {
    // RESOLVE the endpoint route using appropriate route parameters.
    const route = ROUTES.LOCATION_STANDARDS_INDEX(account.id);

    // RESOLVE the request body.
    const body = {}; // Empty body for index request.

    // RESOLVE the request configuraiton.
    /** @type {import('axios').AxiosRequestConfig} */
    const request = {
        ...(config ?? {}),
    };

    // AWAIT the response.
    /** @type {import('axios').AxiosResponse<LocationStandardsResponse[], {}>} */
    const response = await axios().get(route, request);

    // GET the response payload.
    const payload = response.data;

    // FOR EACH response, adjust timezone.
    const collection = payload.map(asLocationStandardsResource);

    // RETURN the collection.
    return collection;
};

/**
 * Get the specified Location Standards, without the computed distributions.
 *
 * @param {LocationStandardsRequest['account']} account Account key parameter.
 * @param {LocationStandardsRequest['location']} location Location key parameter.
 * @param {import('axios').AxiosRequestConfig} config Enables additional configuration of the axios request, including abort signal handling.
 */
export const fetchLocationStandardsById = async (account, location, config) => {
    // RESOLVE the endpoint route using appropriate route parameters.
    const route = ROUTES.LOCATION_STANDARDS(account.id, location.id);

    // RESOLVE the request body.
    const body = {}; // Empty body for index request.

    // RESOLVE the request configuraiton.
    /** @type {import('axios').AxiosRequestConfig} */
    const request = {
        ...(config ?? {}),
    };

    // AWAIT the response.
    /** @type {import('axios').AxiosResponse<LocationStandardsResponse, {}>} */
    const response = await axios().get(route, request);

    // GET the response payload.
    const payload = response.data;

    // ADJUST the timezone.
    const resource = asLocationStandardsResource(payload);

    // RETURN the resource.
    return resource;
};

/**
 * Get the specified Location Standards, with the computed distributions.
 *
 * @param {LocationDistributionsRequest['account']} account Account key parameter.
 * @param {LocationDistributionsRequest['location']} location Location key parameter.
 * @param {Omit<LocationDistributionsRequest, 'account' | 'location'>} parameters Additional request parameters.
 * @param {import('axios').AxiosRequestConfig} config Enables additional configuration of the axios request, including abort signal handling.
 */
export const fetchLocationDistributionsById = async (
    account,
    location,
    parameters,
    config
) => {
    // RESOLVE the endpoint route using appropriate route parameters.
    const route = ROUTES.LOCATION_STANDARDS_METRICS(account.id, location.id);

    // RESOLVE the request body.
    /** @type {Omit<LocationDistributionsRequest, 'account' | 'location'>} */
    const body = {
        start_time: parameters.start_time ?? null,
        end_time: parameters.end_time ?? null,
    };

    // RESOLVE the request configuraiton.
    /** @type {import('axios').AxiosRequestConfig<Omit<LocationDistributionsRequest, 'account' | 'location'>>} */
    const request = {
        ...(config ?? {}),
    };

    // AWAIT the response.
    /** @type {import('axios').AxiosResponse<LocationDistributionsResponse, Omit<LocationDistributionsRequest, 'account' | 'location'>>} */
    const response = await axios().post(route, body, request);

    // GET the response payload.
    const payload = response.data;

    // ADJUST the timezone.
    const resource = asLocationDistributionsResource(payload);

    // RETURN the resource.
    return resource;
};

// <!-- EXPORTS -->
export default {
    fetchLocationStandards,
    fetchLocationStandardsById,
    fetchLocationDistributionsById,
};
