// <!-- UTILITIES -->

import is from '@sindresorhus/is';
import capitalize from 'lodash-es/capitalize';

// <!-- TYPES -->

/**
 * Parameters used for creating a `ReportFileName` instance.
 * @typedef {{ reportType: keyof typeof import('@/utils/enums/ReportType').ReportType }} ReportTypeParam
 * @typedef {{ name: import('@/models/accounts/Account').AccountResource['name'] }} AccountNameParam
 * @typedef {{ date: IDate }} DownloadDateParam
 * @typedef {{ format: 'pdf' }} FileFormatParam
 * @typedef {Combine<ReportTypeParam & Partial<AccountNameParam> & Partial<DownloadDateParam> & Partial<FileFormatParam>>} ReportFileNameParams
 */

/**
 * Created `ReportFileName` instance metadata properties.
 * @typedef {{ tag: string }} FileTagProperty
 * @typedef {{ group: string }} FileGroupProperty
 * @typedef {{ date: IDate }} FileDateProperty
 * @typedef {{ extension: string }} FileExtensionProperty
 * @typedef {Combine<FileTagProperty & Partial<FileGroupProperty> & Partial<FileDateProperty> & FileExtensionProperty>} FileNameMetadataOptions
 */

/**
 * Formatting options controlling output name from `ReportFileName` instance.
 * @typedef {{ includeExtension: boolean }} IncludeExtensionProperty
 * @typedef {{ useISOTimestamp: boolean }} ISOTimestampProperty
 * @typedef {Combine<Partial<IncludeExtensionProperty> & Partial<ISOTimestampProperty>>} FileNameFormatOptions
 */

/**
 * Represents a report filename.
 * eg. `[Tag]-[Institution]-[DateTime].[extension]`
 */
export class ReportFileName {
    // CONSTRUCTOR

    /**
     * Generate a report filename instance.
     * @param {Combine<FileNameMetadataOptions & FileNameFormatOptions>} options
     */
    constructor(options) {
        // Save metadata.
        this.tag = options.tag;
        this.group = options.group ?? 'eClimateNotebook';
        this.date = options.date ?? Date.now();
        this.extension = options.extension ?? '.pdf';

        // Save configuration options.
        this.includeExtension = options?.includeExtension ?? true;
        this.useISOTimestamp = options?.useISOTimestamp ?? false;
    }

    // STATIC UTILITY METHODS

    /**
     * Get the tag to use with the report type.
     * @param {ReportTypeParam['reportType']} reportType
     */
    static getTagWithReportType(reportType) {
        let content = '';
        switch (reportType) {
            case 'compare':
                content = `compare metrics`;
                break;
            case 'nara':
                content = `nara standards`;
                break;
            case 'overview':
            case 'performance':
            case 'custom':
                content = `${reportType}`;
                break;
            default:
                return `eClimateNotebook`;
        }
        return `${content} report`.split(' ').map(capitalize).join(' ');
    }

    // STATIC FACTORY METHODS

    /**
     * Get the default report filename for the specified report type.
     * @param {ReportFileNameParams} params
     */
    static create(params) {
        const tag = `${ReportFileName.getTagWithReportType(params.reportType)}`;
        const group = params?.name ?? 'eClimateNotebook';
        const date = params?.date ?? Date.now();
        const extension = params?.format ?? 'pdf';
        return new ReportFileName({
            tag,
            group,
            date,
            extension,
        });
    }

    // PROPERTIES

    /**
     * Get filename metadata.
     * @return {Readonly<FileNameMetadataOptions>}
     */
    get metadata() {
        return Object.freeze({
            tag: this.tag,
            group: this.group,
            date: this.date,
            extension: this.extension,
        });
    }

    /**
     * Get access to validators.
     */
    get isValid() {
        const filename = this;
        return {
            /**
             * Is the tag valid?
             * @returns {boolean}
             */
            tag: () => is.nonEmptyStringAndNotWhitespace(filename.tag),
            /**
             * Is the group valid?
             * @returns {boolean}
             */
            group: () => is.nonEmptyStringAndNotWhitespace(filename.group),
            /**
             * Is the date valid?
             * @returns {boolean}
             */
            date: () =>
                is.number(filename.date.valueOf()) &&
                !is.nan(filename.date.valueOf()),
            /**
             * Is the extension valid?
             * @returns {boolean}
             */
            extension: () =>
                is.nonEmptyStringAndNotWhitespace(filename.extension),
            /**
             * Is the filename valid?
             * @returns {boolean}
             */
            filename: () =>
                is.nonEmptyStringAndNotWhitespace(filename.toString()),
        };
    }

    /**
     * Get the formatted file title.
     * @example eg. `Overview Report (IPI)`
     * @return {string}
     */
    get title() {
        if (!this.isValid.tag()) {
            return '';
        }
        if (!this.isValid.group()) {
            return '';
        }
        const tag = this.tag
            .toLocaleLowerCase()
            .split(' ')
            .map(capitalize)
            .join(' ');
        const group = this.group;
        return `${tag} (${group})`;
    }

    /**
     * Get the ISO-8601 formatted timestamp, derived from the ISO-8601 timestamp.
     * @example eg. `2023-12-31-00:00:00.000Z`
     * @return {string}
     */
    get timestamp() {
        if (!this.isValid.date()) {
            return '';
        }
        const datetime = new Date(this.date.valueOf());
        const [date, time] = datetime.toISOString().split('T');
        return `${date}T${time.substring(0, 8)}`;
    }

    /**
     * Get the simplified date string, derived from the `en-ca` locale.
     * @example eg. `2023-12-31`
     * @return {string}
     */
    get datestring() {
        if (!this.isValid.date()) {
            return '';
        }
        const datetime = new Date(this.date.valueOf());
        return datetime.toLocaleDateString('en-ca');
    }

    /**
     * Get the extension without the dot prefix, if present.
     * @example eg. `.pdf` => `pdf` and `pdf` => `pdf`.
     * @return {string}
     */
    get format() {
        if (!this.isValid.extension()) {
            return '';
        }
        const { extension } = this;
        const isDotted = /^[.].+$/i.test(extension);
        const type = isDotted ? extension.substring(1) : extension;
        return type;
    }

    /**
     * Get the filename, using the instance's current settings.
     */
    get filename() {
        return this.toString();
    }

    // SERVICE METHODS

    /**
     * Return formatted filename string.
     * @param {FileNameFormatOptions} [options] Allow local overriding of default options at format time.
     */
    toString(options = {}) {
        // Assign default options via destructure.
        const {
            includeExtension = this.includeExtension,
            useISOTimestamp = this.useISOTimestamp,
        } = options ?? {};

        // If missing required options, return empty string.
        if (
            !this.isValid.tag() ||
            !this.isValid.group() ||
            !this.isValid.date() ||
            !this.isValid.extension()
        ) {
            return '';
        }

        // Generate filename format.
        const title = this.title;
        const timestamp = useISOTimestamp ? this.timestamp : this.datestring;
        const format = this.format;

        // Compose segments.
        const basename = `${title} ${timestamp}`;
        const extension = `${format}`;

        // Return formatted string.
        return includeExtension ? `${basename}.${extension}` : basename;
    }

    /**
     * Get the filename string representation or an empty string.
     */
    get() {
        return this.filename;
    }

    /**
     * Get the filename string representation, if it is valid.
     */
    orNull() {
        return this.isValid.filename() ? this.filename : null;
    }

    /**
     * Get the filename string representation, if it is valid. Otherwise, return the default string.
     * @param {string} [defaultValue]
     */
    orDefault(defaultValue = `Untitled.pdf`) {
        return this.orNull() ?? defaultValue;
    }
}

// <!-- DEFAULT -->
export default ReportFileName;
