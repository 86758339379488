// <!-- API -->

import { ref, onBeforeMount, onBeforeUnmount } from 'vue';
import {
    createEventHook,
    computedEager,
    resolveRef,
    refDefault,
    useTimeoutFn,
} from '@vueuse/core';

// <!-- UTILITIES -->

import capitalize from 'lodash-es/capitalize';

// <!-- COMPOSABLES -->

import { useAlerts } from '@/components/alerts/hooks/useAlerts';

// <!-- ENUMS -->

import { ReportType, AlertType } from '@/utils/enums';

// <!-- CONSTANTS -->

/**
 * Default alert timeout duration, in milliseconds.
 */
const DEFAULT_ALERT_TIMEOUT_DURATION = 7 * 1000;

// <!-- TYPES -->

/**
 * Alert definitions.
 * @typedef {import('@/components/alerts/hooks/useAlerts').AlertDef} AlertDef
 */

/**
 * Individual properties used in report alerts.
 * @typedef {{ id: string }} ReportAlertID Report type.
 * @typedef {{ reportType: keyof typeof ReportType }} ReportTypeProperty Report type.
 * @typedef {{ alertType: keyof typeof AlertType }} AlertTypeProperty Alert type.
 * @typedef {{ message: string }} AlertContentProperty Alert content.
 * @typedef {{ duration: number }} ReportAlertTimeoutProperty Duration to display before timeout.
 * @typedef {{ error: unknown }} ErrorProperty Error instance or message.
 */

/**
 * Report alert event options.
 * @typedef {Combine<AlertTypeProperty & ReportTypeProperty & AlertContentProperty>} RaiseAlertEventArgs
 * @typedef {Combine<ReportAlertID & { isTimeout: boolean }>} AlertDismissedEventArgs
 * @typedef {Combine<ReportAlertID>} AlertTimeoutEventArgs
 */

/**
 * Report alert event hooks.
 * @typedef {import('@vueuse/core').EventHook<void>} ResetAlertsEventHook Triggered to dismiss all pending alerts.
 * @typedef {import('@vueuse/core').EventHook<RaiseAlertEventArgs>} RaiseAlertEventHook Triggered to raise the alert of the specified type.
 * @typedef {import('@vueuse/core').EventHook<AlertDismissedEventArgs>} AlertDismissedEventHook Triggered when an alert is dismissed.
 * @typedef {import('@vueuse/core').EventHook<AlertTimeoutEventArgs>} AlertTimeoutEventHook Triggered when an alert timeout occurs.
 */

/**
 * Report alert model components.
 * @typedef {ReturnType<ReportAlerts.services>} ReportAlertsServices
 * @typedef {ReturnType<ReportAlerts.events>} ReportAlertsEventHooks
 * @typedef {ReturnType<ReportAlerts.state>} ReportAlertsState
 * @typedef {ReturnType<ReportAlerts.properties>} ReportAlertsProperties
 */

// <!-- CONTROLLER -->

/**
 * Controller utility for managing a queue of report alerts.
 */
export class ReportAlerts {
    // STATIC INTERNAL METHODS

    /**
     * Creates services that interact with this controller.
     * @param {{ alerts?: ReturnType<useAlerts> }} options
     */
    static services(options = {}) {
        return {
            alerts: options?.alerts ?? useAlerts(),
        };
    }

    /**
     * Creates event hooks used by this controller.
     */
    static events() {
        return {
            /**
             * Triggered to clear existing report alerts.
             * @type {ResetAlertsEventHook}
             */
            reset: createEventHook(),
            /**
             * Triggered to raise a new report alert.
             * @type {RaiseAlertEventHook}
             */
            raise: createEventHook(),
            /**
             * Triggered when report alert is dismissed.
             * @type {AlertDismissedEventHook}
             */
            dismiss: createEventHook(),
            /**
             * Triggered when report alert timeout occurs.
             * @type {AlertTimeoutEventHook}
             */
            timeout: createEventHook(),
        };
    }

    /**
     * Creates reactive state used by this controller.
     * @param {{ delay?: MaybeComputedRef<number> }} [options]
     */
    static state(options = {}) {
        return {
            /**
             * Contains collection of active alerts specific to this controller.
             * @type {V.Ref<string[]>}
             */
            active: ref([]),
            /**
             * Represents duration (in milliseconds) before an alert should dismiss itself on timeout.
             */
            delay: refDefault(
                resolveRef(options.delay),
                DEFAULT_ALERT_TIMEOUT_DURATION
            ),
        };
    }

    /**
     * Creates the computed properties used by this controller.
     * @param {{ services: ReportAlertsServices, state: ReportAlertsState }} context
     */
    static properties(context) {
        /** Reference to total active alerts. */
        const alerts = computedEager(() => {
            const _alerts = context.services.alerts.state.alerts.value;
            const _ids = context.state.active.value;
            return _alerts.filter((def) => _ids.includes(String(def.id)));
        });
        /** Reference to the alerts this has controller over. */
        const isEmpty = computedEager(
            () => context.state.active.value.length === 0
        );
        return {
            alerts,
            isEmpty,
        };
    }

    // CONSTRUCTOR

    /**
     * Construct the report download controller.
     * @param {Combine<{ alerts?: ReturnType<useAlerts>, delay?: MaybeComputedRef<number> }>} [options]
     */
    constructor(options = {}) {
        this.services = ReportAlerts.services(options);
        this.events = ReportAlerts.events();
        this.state = ReportAlerts.state(options);
        this.properties = ReportAlerts.properties(this);

        this.boot();
        this.register();
    }

    // STATIC UTILITY METHODS

    /**
     * Get the tag to use with the report type.
     * @param {keyof typeof ReportType} reportType
     */
    static getLabelWithReportType(reportType) {
        let content = '';
        switch (reportType) {
            case 'compare':
                content = `compare metrics`;
                break;
            case 'nara':
                content = `nara standards`;
                break;
            case 'overview':
            case 'performance':
            case 'custom':
                content = `${reportType}`;
                break;
            default:
                return `eClimateNotebook`;
        }
        return `${content}`.split(' ').map(capitalize).join(' ');
    }

    // INTERNAL METHODS

    /**
     * Initialize and setup the report download controller.
     */
    boot() {}

    /**
     * Register the event handlers that are minimally required.
     */
    register() {
        // Setup the controller reference.
        // THEN, get reference to its event listeners.
        const controller = this;
        const { onReset, onRaise, onDismiss, onTimeout } = controller.lifecycle;

        // REMOVE all active alerts.
        onReset(() => {
            const _active = [...controller.state.active.value].sort((a, b) =>
                a.localeCompare(b)
            );
            _active.forEach((id) => controller.pop(id));
        });

        // RAISE alert of the specified type.
        onRaise((e) => {
            let tag = `alert::raise`;
            switch (e.alertType) {
                case 'success':
                    tag = `${tag}::${e.alertType}`;
                    console.info(tag, e);
                    break;
                case 'failure':
                case 'error':
                    tag = `${tag}::${e.alertType}`;
                    console.error(tag, e);
                    break;
                case 'warning':
                    tag = `${tag}::${e.alertType}`;
                    console.warn(tag, e);
                    break;
                case 'info':
                default:
                    tag = `${tag}::${e.alertType}`;
                    console.info(tag, e);
                    break;
            }

            // Define new alert params.
            const timestamp = Date.now();
            const id = `${timestamp}-${e.reportType}-${e.alertType}`;
            const content = e.message;
            const type = e.alertType;

            // Create the alert definition.
            const definition = controller.create({
                id,
                content,
                type,
                dismissable: true,
            });

            // Set active alert.
            controller.push(definition);

            // Start countdown for timeout.
            controller.countdown(definition.id);
        });

        // DISMISS alert of the specified id.
        onDismiss((e) => {
            const tag = `alert::dismiss`;
            console.info(tag, e);
            controller.pop(e.id);
        });

        // DISMISS alert of the specified id, on timeout.
        onTimeout((e) => {
            const tag = `alert::timeout`;
            console.info(tag, e);
            controller.dismiss(e.id, true);
        });

        // RESET all alerts before mounting.
        onBeforeMount(() => {
            controller.clear();
        });

        // RESET all alerts before unmounting.
        onBeforeUnmount(() => {
            controller.clear();
        });
    }

    // PROPERTIES

    /**
     * Get lifecycle event listeners.
     */
    get lifecycle() {
        return {
            onReset: this.events.reset.on,
            onRaise: this.events.raise.on,
            onDismiss: this.events.dismiss.on,
            onTimeout: this.events.timeout.on,
        };
    }

    /**
     * Get lifecycle event triggers.
     */
    get trigger() {
        return {
            reset: this.events.reset.trigger,
            raise: this.events.raise.trigger,
            dismiss: this.events.dismiss.trigger,
            timeout: this.events.timeout.trigger,
        };
    }

    // SERVICE METHODS

    /**
     * Create an alert definition.
     * @param {Object} params
     * @param {string} params.id
     * @param {keyof typeof AlertType} [params.type]
     * @param {String} [params.title]
     * @param {String} [params.content]
     * @param {String[]} [params.messages]
     * @param {Boolean} [params.dismissable]
     */
    create(params) {
        return this.services.alerts.methods.createAlert(params);
    }

    /**
     * Push an alert definition.
     * @param {AlertDef} alert
     */
    push(alert) {
        this.state.active.value.push(String(alert.id));
        this.services.alerts.methods.pushAlert(alert);
    }

    /**
     * Clear an alert by its id.
     * @param {string} id
     */
    pop(id) {
        if (this.state.active.value.includes(id)) {
            this.services.alerts.methods.clearAlert(id);
            const _active = this.state.active.value.filter(
                (active) => active !== id
            );
            this.state.active.value = _active;
        }
    }

    /**
     * Register timeout to take effect once the delay is over.
     * @param {string} id
     */
    countdown(id) {
        const delay = this.state.delay.value ?? 1000;
        const callback = () => this.trigger.timeout({ id });
        useTimeoutFn(callback, delay, { immediate: false }).start();
    }

    /**
     * Clear all active alerts.
     */
    clear() {
        this.trigger.reset();
    }

    /**
     * Clear specified alert.
     * @param {string} id
     * @param {boolean} [isTimeout]
     */
    dismiss(id, isTimeout = false) {
        this.trigger.dismiss({
            id,
            isTimeout,
        });
    }

    /**
     * Raise alert on download operation start.
     * @param {keyof typeof ReportType} reportType
     * @param {string} [message]
     */
    clicked(reportType, message = null) {
        const alertType = AlertType.info;
        this.trigger.raise({
            alertType,
            reportType,
            message:
                message ??
                `Generating ${ReportAlerts.getLabelWithReportType(
                    reportType
                )} report...`,
        });
    }

    /**
     * Raise alert on download operation success.
     * @param {keyof typeof ReportType} reportType
     * @param {string} [message]
     */
    success(reportType, message = null) {
        const alertType = AlertType.success;

        /** Find different timestamped alerts, to dismiss. */
        const previous = this.state.active.value.find((id) =>
            id.includes(reportType)
        );
        this.dismiss(previous, false);

        // Raise the new alert.
        this.trigger.raise({
            alertType,
            reportType,
            message:
                message ??
                `Downloaded ${ReportAlerts.getLabelWithReportType(
                    reportType
                )} report successfully.`,
        });
    }

    /**
     * Raise alert on download operation failure.
     * @param {keyof typeof ReportType} reportType
     * @param {string} [message]
     */
    failure(reportType, message = null) {
        const alertType = AlertType.failure;

        /** Find different timestamped alerts, to dismiss. */
        const previous = this.state.active.value.find((id) =>
            id.includes(reportType)
        );
        this.dismiss(previous, false);

        // Raise the new alert.
        this.trigger.raise({
            alertType,
            reportType,
            message:
                message ??
                `Failed to download the ${ReportAlerts.getLabelWithReportType(
                    reportType
                )} report.`,
        });
    }
}

// <!-- DEFAULT -->
export default ReportAlerts;
