<template>
    <div class="side-container relative bg-gray-100 print:hidden h-full">
        <div
            class="block relative h-full transition-all"
            :class="{
                'w-72 overflow-y-auto duration-500 ease-in': isExpanded,
                'w-4 overflow-y-hidden duration-700 ease-in-out': isCollapsed,
            }"
            ref="sidebar"
            @click="onSidebarClicked"
        >
            <div class="px-4 pb-2">
                <div class="max-w-3xl mx-auto divide-y-2 divide-gray-200">
                    <dl>
                        <!-- <ZoomFilter :border="false" @zoomButtonClicked="$emit('zoomButtonClicked')" /> -->
                        <DateRangeFilter
                            :border="false"
                            v-show="isDateRangeVisible"
                            id="filter-date-range-group"
                            v-bind="filters.dateRange"
                        />
                        <LimitsFilter
                            id="filter-limits-group"
                            v-show="isLimitsVisible"
                            v-bind="filters.limits"
                            :currentFilterType="currentFilterType"
                        />
                        <ScalesFilter
                            id="filter-scales-group"
                            v-show="isScalesVisible"
                            v-bind="filters.scales"
                            :currentFilterType="currentFilterType"
                            :showButtons="isExpanded"
                            @click:zoom="$emit('click:zoom', $event)"
                        />
                        <LocationFilter
                            id="filter-locations-group"
                            v-show="isLocationsVisible"
                            v-bind="filters.locations"
                            :showButtons="isExpanded"
                            :checkedLimit="checkedLocationLimit"
                            :debug="false"
                        />
                        <WeatherStationFilter
                            id="filter-locations-group"
                            v-show="isWeatherStationsVisible"
                            v-bind="filters.stations"
                            :showButtons="isExpanded"
                            :checkedLimit="checkedWeatherStationLimit"
                            :debug="true"
                        />
                    </dl>
                </div>
            </div>
            <slot></slot>
        </div>
        <div
            class="vertical-line absolute right-0 top-0 border-r border-gray-300 hover:border-gray-400 h-full"
        >
            <BaseTooltip
                target="#tooltip-target"
                :text="sidebarTooltip"
                :textSize="'text-md'"
            >
                <template #icon>
                    <button
                        class="arrowButton absolute transition-all transform duration-700 ease-in-out"
                        :class="{
                            'rotate-0': isExpanded,
                            'rotate-180': isCollapsed,
                        }"
                        ref="sidebarToggle"
                    >
                        <ArrowLeftIcon
                            class="h-6 w-6 text-primary-800 bg-white hover:bg-primary-800 hover:text-white border border-gray-400 rounded-full"
                            aria-hidden="true"
                            @click="onSidebarToggleClicked"
                            id="collapseBtnArrow"
                        />
                    </button>
                </template>
            </BaseTooltip>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        ref,
        watch,
        onBeforeMount,
        onBeforeUnmount,
    } from 'vue';

    // <!-- COMPONENTS -->
    import { ArrowLeftIcon } from '@heroicons/vue/outline';
    import DateRangeFilter from '@/components/sidebar/DateRangeFilter.vue';
    import LimitsFilter from '@/components/sidebar/LimitsFilter.vue';
    import ScalesFilter from '@/components/sidebar/ScalesFilter.vue';
    import LocationFilter from '@/components/sidebar/LocationFilter.vue';
    import WeatherStationFilter from '@/components/sidebar/WeatherStationFilter.vue';
    import BaseTooltip from '@/components/tooltips/BaseTooltip.vue';

    // <!-- COMPOSABLES -->
    import { useSidebarFilters } from '@/components/sidebar/hooks/useSidebarFilters';
    import { useSidebarCollapser } from '@/components/sidebar/hooks/useSidebarCollapser';

    // <!-- TYPES -->
    /** @typedef {import('@/features/analysis/hooks/useAnalysisChart').AnalysisChartConstants['GraphTypes'][number]} GraphType */
    /** @typedef {Extract<GraphType, 'T' | 'RH' | 'TRH' | 'DP'> | 'all' | ''} FilterType */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SideBar',
        components: {
            ArrowLeftIcon,
            DateRangeFilter,
            LimitsFilter,
            ScalesFilter,
            LocationFilter,
            WeatherStationFilter,
            BaseTooltip,
        },
        emits: ['click:zoom'],
        props: {
            /** Filter type. */
            currentFilterType: {
                /** @type {V.PropType<FilterType>} */
                type: /** @type {any} */ (String),
                required: true,
            },
        },
        setup(props, context) {
            // <!-- STATE -->
            /**
             * @type {V.Ref<Array<V.WatchStopHandle>>}
             * Watch stop handles.
             */
            const handles = ref([]);

            // <!-- FILTERS -->
            const filters = useSidebarFilters();

            // <!-- COLLAPSE -->
            const collapser = useSidebarCollapser();

            // <!-- COLLAPSE > STATE -->
            const { sidebar, sidebarToggle } = collapser.state;

            // <!-- COLLAPSE > CONDITIONALS -->
            const { isCollapsed, isExpanded, isScreenTiny } =
                collapser.properties;

            // <!-- COLLAPSE > PROPERTIES -->
            const { sidebarTooltip } = collapser.properties;

            // <!-- COLLAPSE > WATCHERS -->
            const { onSidebarReady, onSidebarToggleReady } = collapser.methods;

            // <!-- COLLAPSE > EVENT HANDLERS -->
            const { onWindowResize, onSidebarClicked, onSidebarToggleClicked } =
                collapser.methods;

            // <!-- LIFECYCLE -->
            onBeforeMount(() => {
                // Stop watchers, if running.
                handles.value.forEach((h) => h?.());
                // Start watchers.
                handles.value = [
                    // Watch for the sidebar to be mounted.
                    watch(sidebar, onSidebarReady, { flush: 'post' }),
                    // Watch for the sidebar toggle to be mounted.
                    watch(sidebarToggle, onSidebarToggleReady, {
                        flush: 'post',
                    }),
                ];

                // Add event listener.
                window.addEventListener('resize', onWindowResize);
            });

            /** Unregister watchers. */
            onBeforeUnmount(() => {
                // Stop watchers, if running.
                handles.value.forEach((h) => h?.());
                // Clear array.
                handles.value = [];
                // Remove window event listener.
                window.removeEventListener('resize', onWindowResize);
            });

            // ==== EXPOSE ====
            return {
                // CONSTANTS
                FILTERS: filters.constants.FILTERS,
                // TEMPLATE REFS,
                sidebar,
                sidebarToggle,
                // STATE
                filters: filters.state.filters,
                // PROPERTIES
                routeName: filters.properties.currentRouteName,
                checkedLocationLimit: filters.properties.checkedLocationLimit,
                checkedWeatherStationLimit:
                    filters.properties.checkedWeatherStationLimit,
                sidebarTooltip,
                // CONDITIONALS
                isDateRangeVisible: filters.properties.isDateRangeVisible,
                isLimitsVisible: filters.properties.isLimitsVisible,
                isScalesVisible: filters.properties.isScalesVisible,
                isLocationsVisible: filters.properties.isLocationsVisible,
                isWeatherStationsVisible:
                    filters.properties.isWeatherStationsVisible,
                isCollapsed,
                isExpanded,
                isScreenTiny,
                // ACTIONS
                onUpdateStartDate: filters.handlers.onUpdateStartDate,
                onUpdateEndDate: filters.handlers.onUpdateEndDate,
                onSidebarClicked,
                onSidebarToggleClicked,
            };
        },
    });
</script>

<style lang="scss">
    .arrowButton {
        right: -12px;
        top: 20px;
    }

    #collapse {
        transition: width 1s;
    }

    .arrowButton svg {
        padding: 2px;
    }

    .checkbox-wrapper {
        flex-shrink: 0;
        min-width: 10px;
    }

    /** See: https://vuejs.org/guide/built-ins/transition-group.html#enter-leave-transitions */
    .filters-move, /* apply transition to moving elements */
    .filters-enter-active,
    .filters-leave-active {
        transition: all 0.5s ease;
    }

    .filters-enter-from,
    .filters-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }

    /* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
    .filters-leave-active {
        position: absolute;
    }
</style>
