// <!-- MODELS -->

import { CustomReportComponent } from './CustomReportComponent';

// <!-- TYPES -->

/**
 * @typedef ReportTemplate
 * @prop {number} id Unique template id.
 * @prop {string} name Display text associated with this template.
 * @prop {number} accountId Parent account id.
 * @prop {number} creatorId Creator user id.
 * @prop {(CustomReportComponent & { position: number })[]} components Custom report components with positions.
 */

/**
 * @typedef ReportTemplatePayload
 * @prop {number} id Unique template id.
 * @prop {string} name Display text associated with this template.
 * @prop {number} account_id Parent account id.
 * @prop {number} creator_id Creator user id.
 * @prop {(CustomReportComponent & { position: number })[]} components Custom report components with positions.
 * @prop {string} created_at Creation date in ISO-8601 format.
 * @prop {string} updated_at Last updated date in ISO-8601 format.
 */

/**
 * Custom report template model.
 * @implements {ReportTemplate}
 */
export class CustomReportTemplate {
    /**
     * Define "empty" report instance.
     */
    static empty() {
        return new CustomReportTemplate({});
    }

    /**
     * Define custom report template instance.
     * @param {number} id Unique template id.
     * @param {string} name Display text associated with this template.
     * @param {number} accountId Parent account id.
     * @param {number} creatorId Creator user id.
     * @param {(CustomReportComponent & { position: number })[]} components Custom report components with positions.
     */
    static fromAttributes(id, name, accountId, creatorId, components) {
        return new CustomReportTemplate({
            id,
            name,
            creatorId,
            accountId,
            components,
        });
    }

    /**
     * Define custom report template instance.
     * @param {ReportTemplatePayload} payload
     */
    static fromPayload(payload) {
        return new CustomReportTemplate({
            ...payload,
            creatorId: payload.creator_id,
            accountId: payload.account_id,
        });
    }

    /**
     * Define a custom report template instance.
     * @param {Partial<ReportTemplate>} attrs Attributes.
     */
    constructor(attrs = {}) {
        this.id = attrs.id ?? null;
        this.name = attrs.name ?? null;
        this.accountId = attrs.accountId ?? null;
        this.creatorId = attrs.creatorId ?? null;
        this.components = attrs.components ?? [];
    }

    // ALIASES

    /** @alias name - The template label is the formatted template name. */
    get label() {
        return this.name;
    }

    /** Number of components in this template. */
    get size() {
        return this.components.length;
    }

    /** Get the component ids an array, sorted by position value. */
    get keys() {
        return CustomReportComponent.sort(this.components).map((c) => c.id);
    }

    /**
     * Test if the template contains the specified component.
     * @param {Pick<CustomReportComponent, 'id'>} component
     */
    includes(component) {
        return this.keys.includes(component.id);
    }

    /**
     * Test if the template instance was created by the specified user.
     * @param {Pick<import('@/api/users').UserResource, 'id'>} user
     */
    isCreatedBy(user) {
        return this.creatorId === user.id;
    }

    /**
     * Test if the template instance belongs to the specified account.
     * @param {Pick<import('@/models/accounts/Account').AccountResource, 'id'>} account
     * @returns {boolean}
     */
    isAccountTemplate(account) {
        return this.accountId === account.id;
    }

    /**
     * Select the first component with a matching id.
     * @param {number} id
     */
    findComponentById(id) {
        return this.components.find((c) => c.id === id);
    }

    /**
     * Select the first component with a matching position value.
     * @param {number} position
     */
    findComponentByPosition(position) {
        return this.components.find((c) => c.position === position);
    }
}

// <!-- DEFAULT -->
export default CustomReportTemplate;
